import { useMemo } from 'react'

import Link from 'next/link'

import Box, { BoxProps } from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import { Divider } from '@mui/material'

import { LayoutProps } from 'src/@core/layouts/types'

import getHomeRoute from 'src/layouts/components/acl/getHomeRoute'

import { getUserRole } from 'src/utils/getUserRole'

import Can from '../../../../../layouts/components/acl/Can'
import { subjects } from '../../../../../navigation/vertical/subjects'
import { permissionActions } from '../../../../../configs/permissionActions'
import { DefaultCCLogo } from '../../shared-components/icons'
import { getImageUrl } from '../../../../../utils/getImageUrl'
import { usePortalSettings } from '../../../../../hooks/usePortalSettings'

import Logo from './Logo'

interface Props {
  navHover: boolean
  collapsedNavWidth: number
  hidden: LayoutProps['hidden']
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  settings: LayoutProps['settings']
  saveSettings: LayoutProps['saveSettings']
  navMenuBranding?: LayoutProps['verticalLayoutProps']['navMenu']['branding']
  menuLockedIcon?: LayoutProps['verticalLayoutProps']['navMenu']['lockedIcon']
  menuUnlockedIcon?: LayoutProps['verticalLayoutProps']['navMenu']['unlockedIcon']
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const LogoImage = styled(Box, { shouldForwardProp: prop => prop !== 'logo' })<{ logo: string }>(({ logo }) => ({
  flex: 1,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: logo,
  width: 204,
  height: 52
}))

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const { hidden, navHover, settings, navMenuBranding: userNavMenuBranding } = props
  const theme = useTheme()

  const userRole = getUserRole()

  // ** Hooks & Vars
  const { navCollapsed } = settings

  const { portalSettings } = usePortalSettings()
  const navigationLogoUrl = useMemo(
    () =>
      portalSettings?.portal_design_images?.[0]?.small_logo &&
      `url(${getImageUrl(portalSettings?.portal_design_images?.[0]?.small_logo)})`,
    [portalSettings?.portal_design_images]
  )

  return (
    <MenuHeaderWrapper
      className='nav-header'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: !hidden ? 5 : 0,
        mb: 5,
        gap: 5,
        overflow: 'hidden'
      }}
    >
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <LinkStyled href={getHomeRoute(userRole)}>
          <Can a={subjects.stundentPage} I={permissionActions.read}>
            {navigationLogoUrl ? <LogoImage logo={navigationLogoUrl} /> : <DefaultCCLogo />}
          </Can>
          <Can not a={subjects.stundentPage} I={permissionActions.read}>
            <Logo isLabelVisible={navCollapsed ? navHover : true} />
          </Can>
        </LinkStyled>
      )}

      <Divider
        variant={navCollapsed && !navHover ? 'fullWidth' : 'middle'}
        flexItem
        sx={{
          transition: 'all .25s ease-in-out',
          borderColor: theme.palette.customColors.navbarSecondaryText
        }}
      />
    </MenuHeaderWrapper>
  )
}

export default VerticalNavHeader
