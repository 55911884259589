import { createContext, useContext } from 'react'

import { useAuth } from 'src/hooks/useAuth'
import { useDesign } from 'src/hooks/design/useDesign'

import { UserRolesType } from 'src/utils/getUserRole'

import { Theme } from 'src/types/settings/portal-settings'

const CustomDesignContext = createContext<Theme | undefined>(undefined)

export const CustomDesignProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: design } = useDesign()
  const { user } = useAuth()

  const customDesign =
    user?.role === UserRolesType.CONTENT_CREATOR || user?.role === UserRolesType.ADMIN ? undefined : design

  return <CustomDesignContext.Provider value={customDesign}>{children}</CustomDesignContext.Provider>
}

export const useCustomDesign = () => useContext(CustomDesignContext)
