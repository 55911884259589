import {
  AbstractEntityColumn,
  AbstractEntityOrder,
  AbstractEntityFilter,
  abstractEntityConditions,
  AbstractEntityFilterParams,
  AbstractEntitySubQuery,
  abstractEntityTypes
} from '../types/abstract-entity-service-types'

import { OrderConfig, FilterConfig, FilterParams, SubQuery } from 'src/types/common/types'

const isSubQuery = <EntityKey = string>(column: unknown): column is SubQuery<EntityKey> =>
  typeof column === 'object' && column !== null && 'over' in column && 'attrs' in column
const isSubQueryColumn = <EntityKey = string>(column: unknown): column is EntityKey[] =>
  Array.isArray(column) && column.every(col => typeof col === 'string')

export const mapColumnsToAttrs = <EntityKey = string>(
  columns: (EntityKey | SubQuery<EntityKey>)[]
): (AbstractEntityColumn<EntityKey> | AbstractEntitySubQuery<EntityKey>)[] => {
  return columns.map(column => {
    if (isSubQuery<EntityKey>(column)) {
      const subQuery: AbstractEntitySubQuery<EntityKey> = {
        _t: abstractEntityTypes.subQuery,
        over: {
          _t: abstractEntityTypes.reference,
          key: column.over
        },
        attrs: mapColumnsToAttrs(
          isSubQueryColumn(column.attrs) ? column.attrs : []
        ) as AbstractEntityColumn<EntityKey>[]
      }

      return subQuery
    }

    return {
      _t: abstractEntityTypes.column,
      key: column
    }
  })
}

export const mapOrderByToOrders = <EntityKey>(orderBy: OrderConfig<EntityKey>[]): AbstractEntityOrder<EntityKey>[] => {
  return orderBy.map(order => ({
    _t: 'ES::Order',
    body: {
      _t: 'ES::Column',
      key: order.column!
    },
    asc: order.ascending || true
  }))
}

export const mapFilterByToFilters = <EntityKey, Value>(
  filterBy: FilterConfig<EntityKey, Value>[]
): AbstractEntityFilter<EntityKey, Value>[] => {
  return filterBy.map(filter => ({
    _t: 'ES::Condition',
    key: filter?.operator ? abstractEntityConditions[filter.operator] : 'eq',
    args: [
      {
        _t: 'ES::Column',
        key: filter.attribute as EntityKey
      },
      {
        _t: 'ES::Literal',
        body: filter.value!
      }
    ]
  }))
}

export const mapRequestDataToAbstractEntityPayload = (data: FilterParams): AbstractEntityFilterParams | undefined => {
  if (!data) return

  const payload: AbstractEntityFilterParams = {
    _t: 'ES::Query'
  }

  if (data?.columns) payload.attrs = mapColumnsToAttrs(data?.columns)
  if (data?.order_by && typeof data?.order_by !== 'string') payload.orders = mapOrderByToOrders(data?.order_by)
  if (data?.filter_by) payload.filters = mapFilterByToFilters(data?.filter_by)
  if (data?.offset) payload.offset = data?.offset
  if (data?.limit) payload.limit = data?.limit

  return payload
}
