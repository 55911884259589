import { useCallback, useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import { alpha, styled, useTheme } from '@mui/material/styles'
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import { Card, Dialog, FormControlLabel, Radio, Stack } from '@mui/material'

import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'

import CustomTextField from 'src/@core/components/mui/text-field'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import { useMediaStorage } from 'src/hooks/useMediaStorage'
import useModal from 'src/hooks/useModal'
import useFileMutation from 'src/hooks/useFileMutation'

import GridIconView from 'src/components/media-storage/grid-icon-view'
import RenderMenu, { mapChildren } from 'src/components/media-storage/renderMenu'
import MediaListView from 'src/components/common/media-list/MediaListView'
import VideoUploader from 'src/components/common/uploaders/video-uploader/VideoUploader'
import FileUploader from 'src/components/common/uploaders/file-uploader/FileUploader'

import { generateId } from 'src/utils/random'

import { MediaVariant } from 'src/types/files/media'

const CustomSelect = styled(Select)<SelectProps>(({ theme }) => ({
  height: 'max-content',
  color: theme.palette.primary.main,
  backgroundColor: theme.colorWithOpacity(theme.palette.primary.main, 16),
  '&.MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5)
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: theme.spacing(2.5, 2.5, 2.5, 5)
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0
  }
}))
type MediaStorageProps = {
  mediaType: MediaVariant
  isModal?: boolean
  onChoose?: () => void
}

const MediaStorage = ({ mediaType, isModal, onChoose }: MediaStorageProps) => {
  const router = useRouter()

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const queryClient = useQueryClient()

  const { isOpen: isFileUploadOpen, open, close } = useModal()
  const { control, watch } = useForm({
    defaultValues: {
      type: 'file'
    }
  })

  const type = watch('type')

  const { uploadFile, isUploadPending } = useFileMutation()

  const onUploadVideo = async () => await queryClient.invalidateQueries({ queryKey: ['files', 'media'] })

  const onUpload = async (filesToUpload: File[]) => {
    await Promise.all(
      filesToUpload.map(file => {
        const id = generateId()

        return uploadFile({ file, id })
      })
    )

    await queryClient.invalidateQueries({ queryKey: ['files', 'media'] })
  }

  const theme = useTheme()
  const {
    mediaList,
    folderList,
    thumbnailRef,
    anchorEl,
    modals,
    mediaSortOrder,
    folderSortOrder,
    searchValue,
    isActive,
    setActive,
    setAnchorEl,
    handleCopyLink,
    handleDelete,
    handleDuplicate,
    handleDownload,
    handleEdit,
    handleNewFolder,
    handleRename,
    handleUpload,
    handleUploadThumbnail,
    setSearchValue,
    setMediaSortOrder,
    setFolderSortOrder,
    fetchNextPage
  } = useMediaStorage({ mediaType: mediaType })

  useEffect(() => {
    if (router.query.search) {
      setSearchValue(router.query.search as string)
    }
  }, [router.query.search])

  const { setMultiSelect } = useSelectedMedia()

  const { isSelected, setSelected } = useSelectedMedia()

  const { t: tGlobal } = useTranslation()

  const gridRef = useRef<HTMLElement | null>(null)

  const [view, setView] = useState<'grid' | 'list'>('grid')

  const [mouseOver, setMouseOver] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<number[]>([])

  const isOpen = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [setSearchValue]
  )

  const handleMediaSortChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string
      setMediaSortOrder(value)
    },
    [setMediaSortOrder]
  )

  const handleFolderSortChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string

      setFolderSortOrder(value)
    },
    [setFolderSortOrder]
  )

  return (
    <Stack
      gap={theme.spacing(6)}
      height={'100%'}
      width={'100%'}
      borderBottom={isModal ? `2px solid ${alpha(theme.palette.secondary.main, 0.2)}` : 'none'}
    >
      {!isModal && (
        <Stack direction={'row'} gap={theme.spacing(2)}>
          <Icon icon='tabler:home' fontSize='20px' />
          <Breadcrumbs separator={<Icon icon='tabler:chevron-right' />} aria-label={tGlobal('extracted.breadcrumb')}>
            <Link underline='hover' key='1' color='inherit' href='/' onClick={() => {}} variant='body1'>
              {tGlobal('extracted.courses_overview')}
            </Link>
            <Typography variant='body1' fontWeight={600} key='2' color='text.primary'>
              {tGlobal('extracted.media_storage')}
            </Typography>
          </Breadcrumbs>
        </Stack>
      )}
      <Dialog
        maxWidth='md'
        fullWidth
        PaperProps={{
          sx: { overflow: 'visible', position: 'relative' }
        }}
        open={isFileUploadOpen}
        onClose={close}
      >
        <Card>
          <Stack padding={theme.spacing(4)}>
            <Typography variant='h5'>Select file type</Typography>
            <Stack direction={'row'}>
              <Controller
                control={control}
                name={'type'}
                render={({ field: { value, onChange } }) => (
                  <>
                    <FormControlLabel
                      onChange={onChange}
                      value={'file'}
                      checked={value === 'file'}
                      control={<Radio />}
                      label={'Any file(image, pdf, e.t.c)'}
                    />
                    <FormControlLabel
                      onChange={onChange}
                      value={'video'}
                      checked={value === 'video'}
                      control={<Radio />}
                      label={'Video'}
                    />
                  </>
                )}
              />
            </Stack>
            {type === 'video' ? (
              <VideoUploader onUpload={onUploadVideo} mentorCloudEnabled={false} hasVideoUrlField={false} />
            ) : (
              <FileUploader
                isLoading={isUploadPending}
                mentorCloudEnabled={false}
                fileInputRef={fileInputRef}
                onUpload={onUpload}
              />
            )}
          </Stack>
        </Card>
      </Dialog>
      <Stack
        bgcolor={theme.palette.background.paper}
        borderRadius={theme.spacing(1.5)}
        overflow={'hidden'}
        width={'100%'}
        height={'100%'}
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          overflowY: 'auto'
        }}
      >
        <Stack
          borderRadius={theme.spacing(1.5, 0, 0, 0)}
          borderRight={`2px solid ${alpha(theme.palette.secondary.main, 0.2)}`}
          sx={{
            borderRadius: '6px, 0px, 0px, 0px'
          }}
        >
          {...modals}
          <Box
            sx={{
              alignItems: 'center',
              background: 'linear-gradient(45deg, #415BF5 0%, #6E82F7 100%)',
              color: theme.palette.common.white,
              display: 'flex',
              gap: theme.spacing(2.5),
              justifyContent: 'center',
              padding: theme.spacing(6, 0, 6, 0)
            }}
          >
            <Icon icon='tabler:cloud' fontSize='32px' />
            <Typography variant='h4' color='white'>
              {tGlobal('extracted.title')}
            </Typography>
          </Box>
          <Stack gap={theme.spacing(4)} padding={theme.spacing(6, 5, 6, 5)} sx={{ overflow: 'auto' }}>
            <CustomSelect
              variant='outlined'
              defaultValue='name-a-z'
              IconComponent={() => (
                <Box sx={{ display: 'flex', paddingRight: '10px' }}>
                  <Icon icon='tabler:chevron-down' />
                </Box>
              )}
              value={folderSortOrder}
              onChange={handleFolderSortChange}
            >
              <MenuItem value='name-a-z'>{tGlobal('extracted.name_(a-z)')}</MenuItem>
              <MenuItem value='name-z-a'>{tGlobal('extracted.name_(z-a)')}</MenuItem>
              <MenuItem value='creation_date-newest-first'>
                {tGlobal('extracted.creation_date_(newest_first)')}
              </MenuItem>
              <MenuItem value='creation_date-oldest-first'>
                {tGlobal('extracted.creation_date_(oldest_first)')}
              </MenuItem>
            </CustomSelect>
            <Box
              onClick={() => setActive(undefined)}
              sx={{
                alignItems: 'center',
                backgroundColor: isActive === undefined ? alpha(theme.palette.primary.main, 0.2) : 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                gap: theme.spacing(2),
                padding: theme.spacing(1.5, 2, 1.5, 2),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1)
                }
              }}
            >
              <Icon color={theme.palette.primary.main} fontSize='24px' icon='tabler:home' />
              <Typography variant='body2'>{tGlobal('extracted.home')}</Typography>
            </Box>
            <Stack gap={theme.spacing(1)} maxHeight={`calc(100vh - ${isModal ? 475 : 330}px)`}>
              <RenderMenu
                data={mapChildren(folderList || [])}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                isActiveFolder={isActive}
                setActiveFolder={setActive}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          width={'100%'}
          sx={{
            padding: { xs: theme.spacing(0, 5, 2, 5), md: theme.spacing(8, 12, 8, 12) }
          }}
        >
          <Stack gap={theme.spacing(8)} width={'100%'}>
            <Stack gap={theme.spacing(4)}>
              <Stack
                gap={theme.spacing(2)}
                alignItems={'center'}
                sx={{
                  flexDirection: { xs: 'column', md: 'row' }
                }}
              >
                <Typography variant='h4' sx={{ flexGrow: 1 }}>
                  {isActive?.name || 'Home'}
                </Typography>
                <input
                  multiple
                  type='file'
                  id='file'
                  ref={fileInputRef}
                  onChange={handleUpload}
                  style={{ display: 'none' }}
                />
                <Button
                  variant='outlined'
                  endIcon={<Icon icon='tabler:file-plus' />}
                  onClick={open}

                  // onClick={() => fileInputRef.current?.click()}
                >
                  {tGlobal('extracted.upload_file')}
                </Button>
                <Stack direction={'row'} alignItems={'center'} gap={theme.spacing(0.5)}>
                  <Button onClick={handleNewFolder} variant='outlined' endIcon={<Icon icon='tabler:folder' />}>
                    {tGlobal('extracted.add_folder')}
                  </Button>

                  <Button
                    disabled={isSelected === undefined}
                    onClick={handleClick}
                    variant='tonal'
                    sx={{ minHeight: '38px', minWidth: '38px', p: 0 }}
                  >
                    <Icon icon='tabler:dots-vertical' fontSize='22px' />
                  </Button>
                </Stack>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  open={isOpen}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {isSelected?.type === 'folder' && (
                    <Stack>
                      <MenuItem onClick={handleRename} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.rename')}
                      </MenuItem>
                      <MenuItem onClick={handleDelete} sx={{ minWidth: '184px', color: theme.palette.error.main }}>
                        {tGlobal('ACTION.delete')}
                      </MenuItem>
                    </Stack>
                  )}
                  {['video'].includes(isSelected?.type || '') && (
                    <Stack>
                      <input
                        type='file'
                        id='thumbnail'
                        ref={thumbnailRef}
                        onChange={handleUploadThumbnail}
                        style={{ display: 'none' }}
                        accept='image/*'
                      />
                      <MenuItem onClick={handleEdit} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.edit')}
                      </MenuItem>
                      <MenuItem onClick={handleDuplicate} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.duplicate')}
                      </MenuItem>
                      <MenuItem onClick={handleDownload} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.download')}
                      </MenuItem>
                      <MenuItem onClick={handleCopyLink} sx={{ minWidth: '184px' }}>
                        {tGlobal('extracted.copy_link_to_file')}
                      </MenuItem>
                      <MenuItem onClick={() => thumbnailRef.current?.click()} sx={{ minWidth: '184px' }}>
                        {tGlobal('extracted.upload_thumbnail_image')}
                      </MenuItem>
                      <MenuItem onClick={handleDelete} sx={{ minWidth: '184px', color: theme.palette.error.main }}>
                        {tGlobal('ACTION.delete')}
                      </MenuItem>
                    </Stack>
                  )}
                  {['image', 'file', 'audio', 'pdf'].includes(isSelected?.type || '') && (
                    <Stack>
                      <MenuItem onClick={handleEdit} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.edit')}
                      </MenuItem>
                      <MenuItem onClick={handleDuplicate} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.duplicate')}
                      </MenuItem>
                      <MenuItem onClick={handleDownload} sx={{ minWidth: '184px' }}>
                        {tGlobal('ACTION.download')}
                      </MenuItem>
                      <MenuItem onClick={handleCopyLink} sx={{ minWidth: '184px' }}>
                        {tGlobal('extracted.copy_link_to_file')}
                      </MenuItem>
                      <MenuItem onClick={handleDelete} sx={{ minWidth: '184px', color: theme.palette.error.main }}>
                        {tGlobal('ACTION.delete')}
                      </MenuItem>
                    </Stack>
                  )}
                </Menu>
              </Stack>
              <Breadcrumbs
                separator={<Icon icon='tabler:chevron-right' />}
                aria-label={tGlobal('extracted.breadcrumb')}
              >
                <Link
                  underline='hover'
                  key='1'
                  color='inherit'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setActive(undefined)}
                  variant='body1'
                >
                  {tGlobal('extracted.home')}
                </Link>
              </Breadcrumbs>
              <Stack gap={theme.spacing(3)} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                <CustomTextField
                  placeholder={tGlobal('extracted.search_for_files_or_tags...')}
                  value={searchValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: <Icon icon='tabler:search' />
                  }}
                  fullWidth
                />
                <CustomSelect
                  variant='outlined'
                  defaultValue='name-a-z'
                  IconComponent={() => (
                    <Box sx={{ display: 'flex', paddingRight: '10px' }}>
                      <Icon icon='tabler:chevron-down' />
                    </Box>
                  )}
                  value={mediaSortOrder}
                  onChange={handleMediaSortChange}
                >
                  <MenuItem value='namet'>{tGlobal('extracted.name_(a-z)')}</MenuItem>
                  <MenuItem value='namef'>{tGlobal('extracted.name_(z-a)')}</MenuItem>
                  <MenuItem value='created_at_epochf'>{tGlobal('extracted.creation_date_(newest_first)')}</MenuItem>
                  <MenuItem value='created_at_epocht'>{tGlobal('extracted.creation_date_(oldest_first)')}</MenuItem>
                  <MenuItem value='sizet'>{tGlobal('extracted.size_(smallest_first)')}</MenuItem>
                  <MenuItem value='sizef'>{tGlobal('extracted.size_(largest_first)')}</MenuItem>
                </CustomSelect>
                <Button
                  onClick={() => setView(view === 'grid' ? 'list' : 'grid')}
                  variant='tonal'
                  sx={{
                    minHeight: '38px',
                    minWidth: '38px',
                    padding: 0,
                    position: 'relative'
                  }}
                >
                  <Icon
                    icon='tabler:list'
                    fontSize='22px'
                    style={{
                      position: 'absolute',
                      transform: `scale(${view === 'list' ? 1 : 0})`,
                      transition: 'all .2s ease-in-out'
                    }}
                  />
                  <Icon
                    icon='tabler:layout-grid'
                    fontSize='22px'
                    style={{
                      position: 'absolute',
                      transform: `scale(${view === 'grid' ? 1 : 0})`,
                      transition: 'all .2s ease-in-out'
                    }}
                  />
                </Button>
              </Stack>
              <Box
                sx={{ position: 'relative', width: '100%' }}
                onDragOver={() => setMouseOver(true)}
                onDragEnd={() => setMouseOver(false)}
                onDragLeave={() => setMouseOver(false)}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.7), ${theme.palette.background.paper} 100%)`,
                    display: mouseOver ? 'flex' : 'none',
                    width: '100%',
                    height: '400px',
                    position: 'absolute',
                    zIndex: 666,
                    justifyContent: 'center'
                  }}
                >
                  <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={theme.spacing(4)}
                    padding={theme.spacing(5, 6, 5, 6)}
                  >
                    <Icon fontSize='128px' icon='tabler:cloud-upload' color={theme.palette.primary.main} />
                    <Typography variant='h3'>{tGlobal('extracted.drag_&_drop_your_files_here!')}</Typography>
                  </Stack>
                </Box>
                <Box
                  ref={gridRef}
                  sx={{
                    display: 'flex',
                    gap: {
                      xs: theme.spacing(1),
                      md: theme.spacing(5)
                    },
                    overflow: 'auto',
                    maxHeight: `calc(100vh - ${isModal ? 475 : 318}px)`,
                    flexWrap: 'wrap',
                    position: 'absolute',
                    opacity: view === 'grid' ? 1 : 0,
                    transition: 'all .3s ease-in-out',
                    transform: `translateX(${view === 'list' ? 5000 : 0}px)`
                  }}
                >
                  <GridIconView
                    data={mediaList || []}
                    gridRef={gridRef}
                    view={view}
                    isSelected={isSelected}
                    isCollapsed={isCollapsed}
                    mediaType={mediaType}
                    setIsCollapsed={setIsCollapsed}
                    setActive={setActive}
                    setSelected={setSelected}
                    fetchNextPage={fetchNextPage}
                    handleClick={handleClick}
                    handleEdit={handleEdit}
                    onDoubleClick={() => {
                      if (isModal && mediaType !== 'file' && onChoose) {
                        onChoose()
                        setSelected()
                        setMultiSelect()
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: theme.spacing(5),
                    flexWrap: 'wrap',
                    position: 'absolute',
                    opacity: view === 'list' ? 1 : 0,
                    transition: 'all .3s ease-in-out',
                    transform: `translateX(${view === 'grid' ? 5000 : 0}px)`,
                    width: '100%'
                  }}
                >
                  <MediaListView
                    mediaList={mediaList || []}
                    view={view}
                    isModal={isModal}
                    fetchNextPage={fetchNextPage}
                    setSelected={setSelected}
                    handleClick={handleClick}
                    handleDoubleClick={() => {
                      if (isModal && mediaType !== 'file' && onChoose) {
                        onChoose()
                      }
                    }}
                    handleEdit={handleEdit}
                    isSelected={isSelected}
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    setActive={setActive}
                    mediaType={mediaType}
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MediaStorage
