import { ElementType } from 'react'

import { useRouter } from 'next/router'
import Link from 'next/link'

import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'

import clsx from 'clsx'

import { NavLink, NavGroup } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'
import { handleURLQueries } from 'src/@core/layouts/utils'

import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

import themeConfig from 'src/configs/themeConfig'

interface Props {
  parent?: boolean
  item: NavLink
  navHover?: boolean
  settings: Settings
  navVisible?: boolean
  collapsedNavWidth: number
  navigationBorderWidth: number
  toggleNavVisibility: () => void
  isSubToSub?: NavGroup | undefined
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & { component?: ElementType; href: string; target?: '_blank' | undefined }
>(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  maxHeight: 'max-content',
  maxWidth: 'max-content',
  padding: 0,
  transition: 'padding-left .25s ease-in-out, padding-right .25s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&.active': {
    '&, &:hover': {
      boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
      background: `linear-gradient(72.47deg, ${
        theme.direction === 'ltr' ? theme.palette.primary.main : hexToRGBA(theme.palette.primary.main, 0.7)
      } 22.16%, ${
        theme.direction === 'ltr' ? hexToRGBA(theme.palette.primary.main, 0.7) : theme.palette.primary.main
      } 76.47%)`,
      '&.Mui-focusVisible': {
        background: `linear-gradient(72.47deg, ${theme.palette.primary.dark} 22.16%, ${hexToRGBA(
          theme.palette.primary.dark,
          0.7
        )} 76.47%)`
      }
    },
    '& .MuiTypography-root, & svg': {
      color: `${theme.palette.primary.contrastText} !important`
    }
  }
}))

const MenuItemWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: theme.spacing(58),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(4)
}))

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  transition: 'all .25s ease-in-out',

  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
}))

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  navigationBorderWidth,
  toggleNavVisibility
}: Props) => {
  // ** Hooks
  const router = useRouter()
  const theme = useTheme()

  // ** Vars
  const { navCollapsed } = settings

  const icon = item.icon

  const menuGroupCollapsedStyles = navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }

  return (
    <CanViewNavLink navLink={item}>
      <ListItem
        disablePadding
        className='nav-link'
        disabled={item.disabled || false}
        sx={{
          mt: 1,
          px: '0 !important',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap'
        }}
      >
        <MenuNavLink
          component={Link}
          {...(item.disabled && { tabIndex: -1 })}
          className={clsx({ active: router.pathname === item.path || handleURLQueries(router, item.path) })}
          href={item.path === undefined ? '/' : `${item.path}`}
          {...(item.openInNewTab ? { target: '_blank' } : null)}
          onClick={e => {
            if (item.path === undefined) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (navVisible) {
              toggleNavVisibility()
            }
          }}
          sx={{
            ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
            '& .MuiTypography-root, & svg': {
              color: 'customColors.navbarSecondaryText'
            }
          }}
        >
          {item.render ? (
            <item.render
              title={item.title}
              menuItemWrapperBoxProps={{
                sx: {
                  height: theme.spacing(parent ? 9.5 : 14),
                  px: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 22 - 28) / 8 : 4,
                  transition: 'all .25s ease-in-out',
                  width: theme.spacing(58),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: theme.spacing(2),
                  padding: theme.spacing(2, 4)
                }
              }}
              listItemIconProps={{
                sx: {
                  transition: 'all .25s ease-in-out',
                  transform: navCollapsed && !navHover ? 'translate(14px)' : 'translateX(0)',
                  ...(parent ? { ml: 1.5, mr: 3.5 } : navCollapsed && !navHover ? { m: 0 } : {}),
                  '& svg': {
                    ...(parent ? { fontSize: '0.625rem' } : { fontSize: '1.375rem' })
                  }
                }
              }}
              menuItemTextMetaWrapperProps={{
                sx: {
                  ...(isSubToSub ? { ml: 2 } : {}),
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2),
                  justifyContent: 'space-between',
                  transition: 'opacity .25s ease-in-out',
                  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' }),
                  ...menuGroupCollapsedStyles
                }
              }}
              menuItemTextMetaTypographyProps={{
                noWrap: themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover),
                sx: { fontWeight: item.fontWeight },
                variant: 'body1'
              }}
            />
          ) : (
            <MenuItemWrapperBox
              sx={{
                height: theme.spacing(parent ? 9.5 : 14),
                width: theme.spacing(58),
                transition: 'all .25s ease-in-out',
                py: theme.spacing(parent ? 2 : 4),
                paddingRight: theme.spacing(parent ? (item.badgeContent ? 2 : 11.5) : 4)
              }}
            >
              {icon ? (
                <ListItemIcon
                  sx={{
                    transition: 'all .25s ease-in-out',
                    transform: navCollapsed && !navHover ? 'translate(14px)' : 'translateX(0)',
                    ...(parent ? { ml: 1.5, mr: 3.5 } : navCollapsed && !navHover ? { m: 0 } : {}),
                    '& svg': {
                      ...(parent ? { fontSize: '0.625rem' } : { fontSize: '1.375rem' })
                    }
                  }}
                >
                  <UserIcon icon={icon as string} />
                </ListItemIcon>
              ) : (
                <Box
                  sx={{
                    // no plan to have 2nd level nav links with icons
                    mr: 3.5
                  }}
                ></Box>
              )}
              <MenuItemTextMetaWrapper
                sx={{
                  ...(isSubToSub ? { ml: 2 } : {}),
                  ...menuGroupCollapsedStyles
                }}
              >
                <Typography
                  variant='body1'
                  sx={{ fontWeight: item.fontWeight }}
                  {...((themeConfig.menuTextTruncate ||
                    (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                    noWrap: true
                  })}
                >
                  <Translations text={item.title} />
                </Typography>
                {item.badgeContent ? (
                  <Chip
                    size='small'
                    label={item.badgeContent}
                    color={item.badgeColor || 'primary'}
                    sx={{
                      height: 22,
                      minWidth: 22,
                      borderRadius: 1,
                      '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
                    }}
                  />
                ) : null}
              </MenuItemTextMetaWrapper>
            </MenuItemWrapperBox>
          )}
        </MenuNavLink>
        {item.withDivider && (
          <Divider
            {...((!navCollapsed || navHover) && { variant: 'middle' })}
            flexItem
            sx={{
              borderColor: theme.palette.customColors.navbarSecondaryText
            }}
          />
        )}
      </ListItem>
    </CanViewNavLink>
  )
}

export default VerticalNavLink
