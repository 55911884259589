import { NextRouter } from 'next/router'

import { NavGroup, NavLink } from 'src/@core/layouts/types'

/**
 * Appends trailing slash if missing
 * Used for comparing urls agnostic of trailingSlash config
 *
 * @param {string} path
 */
export const appendTrailingSlash = (path: string) => {
  return path.endsWith('/') ? path : path + '/'
}

/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param {NextRouter} router
 * @param {string} [path]
 */

export const handleURLQueries = (router: NextRouter, path?: string): boolean => {
  if (!path) return false

  let currentPath = router.asPath.split('?')[0]

  if (currentPath.startsWith('/courses')) {
    currentPath = currentPath.replace('/courses', '')
  }

  if (path === '/') return false

  return appendTrailingSlash(currentPath).startsWith(appendTrailingSlash(path))
}

/**
 * Check if the given item has the given url
 * in one of its children
 *
 * @param item
 * @param currentURL
 */
export const hasActiveChild = (item: NavGroup, currentURL: string): boolean => {
  const { children } = item

  if (!children) {
    return false
  }

  for (const child of children) {
    if ((child as NavGroup).children) {
      if (hasActiveChild(child as NavLink, currentURL)) {
        return true
      }
    }
    const childPath = (child as NavLink).path

    // Check if the child has a link and is active
    if (
      child &&
      childPath &&
      currentURL &&
      (childPath === currentURL || (currentURL.includes(childPath) && childPath !== '/'))
    ) {
      return true
    }
  }

  return false
}

/**
 * Check if this is a children
 * of the given item
 *
 * @param children
 * @param openGroup
 * @param currentActiveGroup
 */
export const removeChildren = (children: NavLink[], openGroup: string[], currentActiveGroup: string[]) => {
  children.forEach((child: NavLink) => {
    if (!currentActiveGroup.includes(child.title)) {
      const index = openGroup.indexOf(child.title)
      if (index > -1) openGroup.splice(index, 1)

      // @ts-ignore
      if (child.children) removeChildren(child.children, openGroup, currentActiveGroup)
    }
  })
}
