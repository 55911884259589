import { createQueryKeys } from '@lukemorales/query-key-factory'

const customerCourses = createQueryKeys('customerCourses', {
  myCourses: {
    queryKey: null
  },
  paidCourses: {
    queryKey: null
  },
  freeCourses: {
    queryKey: null
  },

  courseModules: (courseId?: number) => ({
    queryKey: ['courseModules', courseId]
  }),

  moduleLessons: (moduleIds: number[]) => ({
    queryKey: ['moduleLessons', ...moduleIds]
  })
})

export default customerCourses
