import { useState, useCallback, useMemo } from 'react'

type ModalState = {
  isOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

const useModal = (defaultValue = false): ModalState => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggle = useCallback(() => setIsOpen((prev) => !prev), [])

  return useMemo(
    () => ({
      isOpen,
      open,
      close,
      toggle
    }),
    [isOpen, open, close, toggle]
  )
}

export default useModal
