import axios from 'axios'

import env from 'src/envs'

import { GetImagesReponse, GetImageReponse, GetImagesRequest, GetImageRequest } from 'src/types/unsplash'

const requestUrls = {
  image: (requestBody: GetImageRequest) => `${env.unsplashApiUrl}/photos/${requestBody.id}`,
  images: (requestBody: GetImagesRequest) =>
    `${env.unsplashApiUrl}/search/photos?query=${
      requestBody.searchTerm ? requestBody.searchTerm : 'best'
    }&per_page=15&page=${requestBody.page ? requestBody.page : 1}&orientation=landscape`
}

const unsplashService = {
  getImage: async (requestBody: GetImageRequest): Promise<GetImageReponse> => {
    return await axios.get(requestUrls.image(requestBody), {
      headers: { Authorization: `Client-ID ${env.unsplashClientId}` }
    })
  },
  getImages: async (requestBody: GetImagesRequest): Promise<GetImagesReponse> => {
    return await axios.get(requestUrls.images(requestBody), {
      headers: { Authorization: `Client-ID ${env.unsplashClientId}` }
    })
  }
}

export default unsplashService
