import { useCallback } from 'react'

import { Link, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import useTrial from 'src/hooks/auth/useTrial'
import { useLanguages } from 'src/hooks/languages'
import { useUserPortalInfo } from 'src/hooks/portal/useUserPortalInfo'

import { digistoreUrl } from 'src/utils/settings/portal-settings/utils'

import authService from 'src/service/auth-service'

const BG_SECOND_COLOR = '#565861'
const BG_PRIMARY_COLOR = '#415BF5'

const StyledText = styled(Typography)({
  color: 'white'
})

const LinkText = styled(Typography)({
  color: 'white',
  textDecoration: 'underline',
  cursor: 'pointer'
})

const StyledStack = styled(Stack)(() => ({
  width: '100%',
  padding: '14px',
  alignItems: 'start',
  justifyContent: 'center',
  position: 'relative',
  zIndex: 1000,
  borderRadius: '6px',
  maxWidth: '1392px',
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '10px',
  paddingLeft: '15px'
}))

export const TrialBar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'TRIAL_INFO' })
  const { resendVerificationEmail } = useTrial()
  const user = authService.getUser()
  const { data: languages = [] } = useLanguages()
  const { isVerified } = useUserPortalInfo()
  const theme = useTheme()

  const selectedLang = languages.find(lang => lang.id === user?.portal?.language_id)?.code
  const firstName = user?.portal_member.first_name
  const lastName = user?.portal_member.last_name
  const email = user?.portal_member.email
  const trialEndTime = user?.portal?.trial_end_time || 0
  const trialDays = trialEndTime > 0 ? Math.ceil(trialEndTime / 1000 / 3600 / 24) : 0
  const url = digistoreUrl(email!, firstName!, lastName!, selectedLang!)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleResendEmail = useCallback(async () => {
    await resendVerificationEmail({
      portal_id: user!.portal.id,
      token: user!.token
    })
  }, [resendVerificationEmail, user])

  return (
    <StyledStack
      sx={{
        backgroundColor: isVerified ? BG_PRIMARY_COLOR : BG_SECOND_COLOR
      }}
    >
      {!isVerified && (
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          <StyledText>{t('confirm_email', { email: user?.portal_member.email })}</StyledText>
          <LinkText onClick={handleResendEmail}>{t('resend_email')}</LinkText>
        </Stack>
      )}
      {isVerified && (
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
          <StyledText>{t('your_free_trial_expires', { days: trialDays })}</StyledText>
          <Link href={url} target='_blank' sx={{ textDecoration: 'underline' }} color='#fff'>
            {t('extend_your_trial')}
          </Link>
          <StyledText>{t('extend_remaining_text')}</StyledText>
        </Stack>
      )}
    </StyledStack>
  )
}
