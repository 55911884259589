import { z } from 'zod'
import * as zod from 'zod'

import { notificationSettingsType } from '../../../types/settings'

export const notificationsSchema = z.object({
  notification_settings_type: z.enum(notificationSettingsType, {
    errorMap: () => ({ message: 'settings_type' })
  }),
  receive_email_notifications: z.boolean(),
  receive_in_app_notifications: z.boolean()
})

export type NotificationsFormData = zod.infer<typeof notificationsSchema>
