import { useMemo } from 'react'

import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'

import { RenderProps } from 'src/@core/layouts/types'

import { usePopover } from '../../../../hooks/usePopover'
import { NotificationsPopover } from '../../../notifications/'
import Icon from '../../../../@core/components/icon'
import { useAuth } from '../../../../hooks/useAuth'
import { UserRolesType } from '../../../../utils/getUserRole'
import Badge from '../../../../@core/components/mui/badge'
import { useNotifications } from '../../../../hooks/notifications'
import { useSettings } from '../../../../@core/hooks/useSettings'

const BadgeStyled = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    height: theme.spacing(4.5),
    width: theme.spacing(4.5)
  }
}))

const ListItemIconStyled = styled(ListItemIcon, { shouldForwardProp: prop => prop !== 'isHorizontalLayout' })<{
  isHorizontalLayout?: boolean
}>(({ theme, isHorizontalLayout }) => ({
  width: isHorizontalLayout ? theme.spacing(14) : undefined,
  height: isHorizontalLayout ? theme.spacing(14) : undefined,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0
}))

const NotificationsNav = (props: RenderProps) => {
  const { anchor, close, open, id, isOpen } = usePopover('notifications')
  const { t } = useTranslation()
  const { isLoading, isError, notifications } = useNotifications(10, isOpen)

  const unreadNotifications = useMemo(() => notifications.filter(el => !el.seen).length, [notifications])

  const { user } = useAuth()
  const theme = useTheme()
  const { settings } = useSettings()
  const isCustomer = user?.role === UserRolesType.CUSTOMER || user?.role === UserRolesType.HIDDEN_CUSTOMER

  return (
    <>
      <Box aria-describedby={id} component={Button} {...props.menuItemWrapperBoxProps} onClick={open}>
        <ListItemIconStyled {...props.listItemIconProps} isHorizontalLayout={settings.layout === 'horizontal'}>
          <BadgeStyled badgeContent={unreadNotifications} color='error'>
            {props.listItemIconProps?.children || (
              <Icon icon='tabler:bell' fontSize={settings.layout === 'horizontal' ? theme.spacing(6) : undefined} />
            )}
          </BadgeStyled>
        </ListItemIconStyled>
        {isCustomer && settings.layout !== 'horizontal' ? (
          <Box {...props.menuItemTextMetaWrapperProps}>
            <Typography variant='body1' {...props.menuItemTextMetaTypographyProps}>
              {t(props.title)}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <NotificationsPopover
        anchor={anchor}
        onClose={close}
        isOpen={isOpen}
        id={id}
        isLoading={isLoading}
        isError={isError}
        notifications={notifications}
      />
    </>
  )
}

export default NotificationsNav
