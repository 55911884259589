import { AxiosResponse } from 'axios'
import { DeepPartial } from 'react-hook-form'

import { requestUrls } from 'src/configs/url'

import service from './service'
import { abstractEntityService } from './abstract-entity-service'

import { FilterParams, ResponseResult } from 'src/types/common/types'
import { NavigationMenuItem, NavigationMenuItemKeys } from 'src/types/navigation-items'

export type UpdateNavigationMenuValues = Omit<NavigationMenuItem, 'id'> & DeepPartial<Pick<NavigationMenuItem, 'id'>>

export type GetAllNavigationItemRequest = FilterParams<NavigationMenuItemKeys>
export type GetAllNavigationItemResponse = ResponseResult<NavigationMenuItem[]>

export type UpdateNavigationItemResponse = ResponseResult<number | boolean>
export type UpdateNavigationItemRequest = { values: UpdateNavigationMenuValues[] }

export type DeleteNavigationItemResponse = ResponseResult<boolean>
export type DeleteNavigationItemRequest = Pick<FilterParams, 'filter_by'>

const navigationService = {
  getAll: async (requestBody: GetAllNavigationItemRequest): Promise<AxiosResponse<GetAllNavigationItemResponse>> => {
    return abstractEntityService.post(requestUrls.getCustomMenuSettings, requestBody)
  },
  getAllDeprecated: async (requestBody: FilterParams = {}): Promise<AxiosResponse<GetAllNavigationItemResponse>> => {
    return service.post(requestUrls.getCustomMenuSettingsDeprecated, requestBody)
  },

  update: async (requestBody: UpdateNavigationItemRequest): Promise<UpdateNavigationItemResponse> => {
    return service.post(requestUrls.updateCustomMenuSettings, requestBody)
  },

  delete: async (requestBody: DeleteNavigationItemRequest): Promise<DeleteNavigationItemResponse> => {
    return service.post(requestUrls.deleteCustomMenuSettings, requestBody)
  }
}

export default navigationService
