import { useEffect, useState } from 'react'

import { Box, CircularProgress, IconButton, Typography, alpha, useTheme } from '@mui/material'

import { Icon } from '@iconify/react'
import { useTranslation } from "react-i18next";

import { getTagList } from 'src/utils/mediaStorage/tagActions'

import { MediaGridProps } from 'src/types/files/media'
import { FileType } from 'src/types/files/fileData'

const GridIconAudio = ({
  data,
  isSelected = false,
  setSelected,
  handleClick,
  onDoubleClick,
  handleEdit,
  uploadProgress
}: MediaGridProps) => {
  const { t } = useTranslation();
  const theme = useTheme()

  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Box
      sx={{
        bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.08) : theme.palette.background.paper,
        border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.background.paper}`,
        borderRadius: isSelected ? '8px' : '0',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: {
          xs: '169px',
          md: '189px'
        },
        minWidth: {
          xs: '169px',
          md: '189px'
        },
        height: '177.75px',
        opacity: isMounted ? 1 : 0,
        padding: theme.spacing(2),
        transition: 'all .1s ease-in-out',
        transform: `scale(${isMounted ? 1.0 : 0.8})`,
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px'
        }
      }}
      onClick={() => {
        setSelected(data)
      }}
      onDoubleClick={onDoubleClick}
    >
      <Box
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          bgcolor: alpha(theme.palette.primary.main, 0.2),
          borderRadius: '4px',
          marginBottom: '8px',
          display: 'flex',
          minHeight: '98.28px',
          maxHeight: '98.29px',
          justifyContent: 'center'
        }}
        onClick={() => setSelected(data)}
      >
        {(data as FileType).isConverting && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
            <CircularProgress size={64} sx={{ position: 'absolute' }} />

            <Typography variant='caption' color={theme.palette.primary.main}>
              {t('extracted.converting')}</Typography>
          </Box>
        )}
        {(data as FileType).isUploading && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <CircularProgress size={64} sx={{ position: 'absolute' }} variant='determinate' value={uploadProgress} />
              <Typography variant='body2' sx={{ position: 'absolute' }} color={theme.palette.primary.main}>
                {uploadProgress}%
              </Typography>
            </Box>
            <Typography variant='caption' color={theme.palette.primary.main}>
              {t('extracted.uploading')}</Typography>
          </Box>
        )}
        {!(data as FileType).isUploading && !(data as FileType).isConverting && (
          <Icon icon='tabler:music' fontSize='64px' color={theme.palette.primary.main} />
        )}
      </Box>
      <Box
        sx={{
          alignItems: 'flex-start',
          height: 'fit-content',
          display: 'flex',
          gap: theme.spacing(2)
        }}
      >
        <Box>
          <Icon icon='tabler:music' fontSize='16px' color={alpha(theme.palette.secondary.main, 0.8)} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography
            variant='caption'
            color={theme.palette.primary.main}
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '109px',
              whiteSpace: 'nowrap'
            }}
            onClick={() => setSelected(data)}
          >
            {data.name}
          </Typography>
          <Typography variant='caption'>{(data.size / (1024 * 1024)).toFixed(2)} {t('extracted.mb')}</Typography>
        </Box>
        <IconButton onClick={handleClick} sx={{ alignSelf: 'flex-start' }}>
          <Icon icon='tabler:dots-vertical' fontSize='18px' color={theme.palette.primary.main} />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', gap: theme.spacing(1.5) }}>
        {data.tags && getTagList(data.tags).length > 0 ? (
          <>
            {getTagList(data.tags)
              .slice(0, 2)
              .map((tag, tagIndex) => (
                <Box
                  key={tagIndex}
                  sx={{
                    bgcolor: alpha(theme.palette.secondary.main, 0.2),
                    borderRadius: '4px',
                    padding: theme.spacing(0.5, 1),
                    fontSize: theme.typography.caption.fontSize
                  }}
                >
                  {tag}
                </Box>
              ))}

            {getTagList(data.tags).length > 2 && (
              <Typography variant='caption'>+ {getTagList(data.tags).length - 2}</Typography>
            )}
          </>
        ) : (
          <Box
            sx={{
              bgcolor: alpha(theme.palette.secondary.main, 0.2),
              borderRadius: '4px',
              cursor: 'pointer',
              padding: theme.spacing(0.5, 1),
              fontSize: theme.typography.caption.fontSize,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                bgcolor: theme.palette.primary.main,
                color: theme.palette.common.white
              }
            }}
            onClick={() => {
              setSelected(data)
              handleEdit()
            }}
          >
            {t('extracted.add_tags')}</Box>
        )}
      </Box>
    </Box>
  )
}

export default GridIconAudio
