import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { usePortalSettings } from '../usePortalSettings'

import {
  RegistrationFormType,
  ForgotPasswordFormType,
  zodRegistrationFormSchema,
  zodForgotPasswordFormSchema
} from 'src/schemas/auth'

export const useAuthForm = () => {
  const { portalSettings } = usePortalSettings()

  const configuration = portalSettings?.configuration?.[0]

  const registrationForm = useForm<RegistrationFormType>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      termsChecked: false,
      confirmPassword: ''
    },
    resolver: zodResolver(zodRegistrationFormSchema(configuration?.require_tos_approval))
  })

  const forgotPasswordForm = useForm<ForgotPasswordFormType>({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(zodForgotPasswordFormSchema)
  })

  return { registrationForm, forgotPasswordForm }
}
