import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'

import { RenderProps } from 'src/@core/layouts/types'

import useModal from 'src/hooks/useModal'

import LoginDialog from 'src/components/auth/login-dialog/LoginDialog'

import Icon from '../../../../@core/components/icon'



const LoginNav = (props: RenderProps) => {
  const theme = useTheme()
  const { isOpen, open, close } = useModal()
  const { t } = useTranslation()

  return (
    <>
      <Box component='div' {...props.menuItemWrapperBoxProps} onClick={open}>
        <Box {...props.listItemIconProps}>
          <Icon icon={props.iconName || 'tabler:link'} fontSize={theme.spacing(6)} />
        </Box>
        <Box {...props.menuItemTextMetaWrapperProps}>
          <Typography variant='body1' {...props.menuItemTextMetaTypographyProps}>{t(props.title)}</Typography>
        </Box>
      </Box>
      <LoginDialog isOpen={isOpen} close={close} />
    </>
  )
}

export default LoginNav
