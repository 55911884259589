// Define the AppAbility type using MongoAbility
import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability'

import { Subjects, subjects } from 'src/navigation/vertical/subjects'
import { rolePaths } from 'src/navigation/paths'

import { UserRolesType } from 'src/utils/getUserRole'

import authConfig from 'src/configs/auth'

import { NextPage } from 'next'

import { permissionActions, PermissionActions } from './permissionActions'

export type AppAbility = MongoAbility<[PermissionActions, Subjects]>

// Define ACL object type
export type ACLObj = {
  action: PermissionActions
  subject: Subjects
}

export const CCPermissions = [
  subjects.ccPage,
  subjects.userMenu,
  subjects.community,
  subjects.accountMenu,
  subjects.courses,
  subjects.bottomMenu,
  subjects.topMenu,
  subjects.demo,
  subjects.mentorCloud
]

/**
 * Define ability rules for the application
 * role - Role of the user
 * subject - Specific subject or resource
 */
const defineRulesFor = (role: string) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility)

  switch (role) {
    case '1':
      can(permissionActions.manage, CCPermissions)
      break
    case '2':
      can(permissionActions.manage, [subjects.stundentPage, subjects.accountMenu, subjects.userMenu]),
        can(permissionActions.read, [subjects.community])
      break
    case '4':
      can(permissionActions.manage, [subjects.stundentPage, subjects.accountMenu, subjects.userMenu]),
        can(permissionActions.read, [subjects.community, subjects.preview])
      break
    case '5':
      can(permissionActions.manage, [
        subjects.rootPage,
        subjects.accountMenu,
        subjects.courses,
        subjects.bottomMenu,
        subjects.topMenu,
        subjects.demo,
        subjects.mentorCloud,
        subjects.courseLanguageSettings
      ])
      break
    case 'guest':
      can(permissionActions.manage, [subjects.guest]),
        can(permissionActions.read, [subjects.community, subjects.stundentPage])
      break
    default:
      break
  }

  return rules
}

// Function to build an AppAbility instance
export const buildAbilityFor = (role: string): AppAbility => {
  return createMongoAbility(defineRulesFor(role), {
    detectSubjectType: (object: { type?: Subjects }) => object?.type || subjects.all
  })
}

// Default ACL configuration
export const defaultACLObj: ACLObj = {
  action: permissionActions.manage,
  subject: subjects.guest
}

// Modify the defaultACLObj for role-based access
export const getAclAbilities = (Component: NextPage, isPreviewMode: boolean) => {
  // If the component has specific ACL requirements, use those
  if (Component.acl) {
    return Component.acl
  }

  // For CC role (role '1'), set default access to ccPage
  if (typeof window !== 'undefined') {
    const storage = isPreviewMode ? sessionStorage : localStorage

    const user = JSON.parse(storage.getItem(authConfig.storageTokenKeyName) || '{}')

    if (user.role === UserRolesType.CONTENT_CREATOR) {
      return {
        action: permissionActions.manage,
        subject: subjects.ccPage
      }
    }

    if (user.role === UserRolesType.CUSTOMER) {
      return {
        action: permissionActions.manage,
        subject: subjects.stundentPage
      }
    }

    if (user.role === UserRolesType.HIDDEN_CUSTOMER) {
      return {
        action: permissionActions.manage,
        subject: subjects.stundentPage
      }
    }

    // For root role (role '5'), set default access to the root page
    if (user.role === UserRolesType.ADMIN) {
      return {
        action: permissionActions.manage,
        subject: subjects.rootPage
      }
    }
  }

  // Fallback to default ACL object
  return defaultACLObj
}

// TODO: add keyof typeof rolePaths and remove as
export const canAccessRouteByRole = (role: string, pathName: string): boolean | undefined => {
  return rolePaths[role as keyof typeof rolePaths]?.includes(pathName)
}
export default defineRulesFor
