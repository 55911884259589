import { FilterOperator } from 'src/types/common/types'
import { ValueOf } from 'src/types/common/utils'

export const abstractEntityTypes = {
  query: 'ES::Query',
  subQuery: 'ES::SubQuery',
  column: 'ES::Column',
  literal: 'ES::Literal',
  function: 'ES::Function',
  condition: 'ES::Condition',
  order: 'ES::Order',
  relation: 'ES::Relation',
  reference: 'ES::Reference'
} as const
export type AbstractEntityType = ValueOf<typeof abstractEntityTypes>

export type AbstractEntityFilterOperator = 'lt' | 'le' | 'eq' | 'gt' | 'ge' | 'ne' | 'in' | 'like' | 'ilike' | 'is_null'

export const abstractEntityConditions: Record<FilterOperator, AbstractEntityFilterOperator> = {
  [FilterOperator.LESS_THAN]: 'lt',
  [FilterOperator.LESS_THAN_OR_EQUAL]: 'le',
  [FilterOperator.EQUAL]: 'eq',
  [FilterOperator.STRICT_EQUAL]: 'eq',
  [FilterOperator.GREATER_THAN]: 'gt',
  [FilterOperator.GREATER_THAN_OR_EQUAL]: 'ge',
  [FilterOperator.NOT_EQUAL]: 'ne',
  [FilterOperator.IN]: 'in',
  [FilterOperator.LIKE]: 'like',
  [FilterOperator.ILIKE]: 'ilike',
  [FilterOperator.IS_NULL]: 'is_null'
} as const
export type AbstractEntityCondition = ValueOf<typeof abstractEntityConditions>

export interface AbstractEntityColumn<EntityKey> {
  _t: typeof abstractEntityTypes.column
  key: EntityKey
}

export interface AbstractEntityOrder<EntityKey = string> {
  _t: typeof abstractEntityTypes.order
  body: AbstractEntityColumn<EntityKey>
  asc: boolean
}

export interface AbstractEntityFilterLiteralArg<Body = unknown> {
  _t: typeof abstractEntityTypes.literal
  body: Body
}

export type AbstractEntityFilterArg<EntityKey, Body = unknown> =
  | AbstractEntityColumn<EntityKey>
  | AbstractEntityFilterLiteralArg<Body>

export interface AbstractEntityFilter<EntityKey, Body = unknown> {
  _t: typeof abstractEntityTypes.condition
  key: AbstractEntityCondition
  args: AbstractEntityFilterArg<EntityKey, Body>[]
}

export interface AbstractEntityReference<EntityKey = string> {
  _t: typeof abstractEntityTypes.reference
  key: EntityKey
}

export interface AbstractEntityRelation<EntityKey = string> {
  _t: typeof abstractEntityTypes.relation
  key: EntityKey
}

export interface AbstractEntitySubQuery<EntityKey = string> {
  _t: typeof abstractEntityTypes.subQuery
  over: AbstractEntityReference<EntityKey> | AbstractEntityRelation<EntityKey>
  attrs?: AbstractEntityColumn<EntityKey>[]
}

export interface AbstractEntityFilterParams<EntityKey = string, Body = unknown> {
  _t: typeof abstractEntityTypes.query
  attrs?: (AbstractEntityColumn<EntityKey> | AbstractEntitySubQuery<EntityKey>)[]
  orders?: AbstractEntityOrder<EntityKey>[]
  searches?: AbstractEntityFilter<EntityKey, Body>[]
  filters?: AbstractEntityFilter<EntityKey, Body>[]
  limit?: number
  offset?: number
}
