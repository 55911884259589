import authConfig from 'src/configs/auth'

export const UserRolesType = {
  CONTENT_CREATOR: '1',
  CUSTOMER: '2',
  LEAD: '3',
  HIDDEN_CUSTOMER: '4',
  ADMIN: '5',
  ACTIVATED_LEAD: '6',
  INACTIVE_LEAD: '7',
  PORTAL_VISITOR: '8',
  DEMO_HIDDEN_CUSTOMER: '9'
} as const

export const getUserRole = () => {
  const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'
  const storage = isPreviewMode ? window?.sessionStorage : window?.localStorage

  const user = JSON.parse(storage.getItem(authConfig.storageTokenKeyName) || '{}')

  return user.role ?? '2'
}
