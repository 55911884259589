import { z } from 'zod'
import * as zod from 'zod'

export const SenderSchema = z.object({
  domain: z
    .string({ errorMap: () => ({ message: 'domain' }) })
    .trim()
    .min(1),
  email: z
    .string({ errorMap: () => ({ message: 'email' }) })
    .email()
    .trim()
    .min(1),
  first_name: z
    .string({ errorMap: () => ({ message: 'first_name' }) })
    .trim()
    .min(1),
  last_name: z
    .string({ errorMap: () => ({ message: 'last_name' }) })
    .trim()
    .min(1)
})

export type SenderFormData = zod.infer<typeof SenderSchema>
