import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'

import service from './service'

import { ResponseResult } from 'src/types/common/types'
import { Language } from 'src/types/languages'

export type GetLanguagesOptionsResponse = ResponseResult<Language[]>

const languageService = {
  getLanguages: async (): Promise<AxiosResponse<GetLanguagesOptionsResponse>> => {
    return service.post(requestUrls.getLanguages)
  }
}

export default languageService
