import * as Sentry from '@sentry/nextjs'

import env from 'src/envs'

Sentry.init({
  dsn: env.sentryDnsURL, // Use the DSN from your Sentry projec
  tracesSampleRate: 1.0, // Adjust this rate for performance monitoring
  replaysSessionSampleRate: 0.1, // Capture 10% of sessions for replay
  enabled: process.env.NODE_ENV === 'production'
})
