const apiUrl = process.env.NEXT_PUBLIC_API_URL
const unsplashApiUrl = process.env.NEXT_PUBLIC_UNSPLASH_API_URL
const unsplashClientId = process.env.NEXT_PUBLIC_UNSPLASH_CLIENT_ID
const landingPageApiUrl = process.env.NEXT_PUBLIC_API_URL_LANDING_PAGE
const exportApiUrl = process.env.NEXT_PUBLIC_EXPORT_API_URL ?? '/export_api'
const communityApiUrl = process.env.NEXT_PUBLIC_COMMUNITY_API_URL ?? '/community_api'
const sentryDnsURL = process.env.NEXT_PUBLIC_SENTRY_DSN

const env = {
  apiUrl,
  unsplashApiUrl,
  unsplashClientId,
  landingPageApiUrl,
  exportApiUrl,
  communityApiUrl,
  sentryDnsURL
}

export default env
