import Router from 'next/router'

import { UserDataType } from 'src/context/types'

import { routePaths } from 'src/navigation/paths'

import { createUrlPath } from 'src/utils/url/helper'

export const redirectByRole = (user: UserDataType) => {
  const { role } = user
  const domain = user.portal.domain
  const isLoginOrRegisterPage = Router.asPath.startsWith('/login') || Router.asPath.startsWith('/register')

  const returnUrl = !isLoginOrRegisterPage && !Router.query.returnUrl ? Router.asPath : Router.query.returnUrl

  if (returnUrl) {
    if (role === '1' && domain && isCentralAdminDomain(domain)) {
      const url = getDomainUrl(domain, createUrlPath(`${returnUrl}`))
      window.location.href = url
    }

    if (!Router.query.returnUrl) {
      window.location.href = createUrlPath(`${returnUrl}`)
    }

    Router.push(`${returnUrl}`)
  } else {
    switch (role) {
      case '5':
        Router.replace('/courses')
        break
      case '8': // Portal Visitor
      case '1': // Content Creator
        if (domain && isCentralAdminDomain(domain)) {
          // clearUserSession()
          const url = getDomainUrl(domain, createUrlPath(routePaths.courses))
          window.location.href = url
        }

        Router.replace('/') // Root path for content creator
        break

      case '2':
        Router.replace(`/customer/dashboard${window.location.search}`) // Path for students
        break

      case '4':
        Router.replace(`/customer/dashboard${window.location.search}`) // Path for student preview mode
        break

      default:
        Router.replace('/login') // Default fallback for unknown roles
        break
    }
  }
}

const isCentralAdminDomain = (domain: string) => {
  if (process.env.NODE_ENV !== 'production') {
    return window.location.origin !== `${domain}:3000`
  } else {
    return window.location.origin !== domain
  }
}

const getDomainUrl = (domain: string, path: string) => {
  if (process.env.NODE_ENV !== 'production') {
    domain = domain.slice(domain.lastIndexOf('/') + 1)

    return `http://${domain}:3000${path}`
  } else {
    return `${domain}${path}`
  }
}

// const clearUserSession = () => {
//   const domain = window.location.hostname

//   Cookies.remove('user', { domain: domain })
//   Cookies.remove('mentortools_user', { domain: domain })

//   sessionStorage.clear()
// }
