import { formatDistanceToNow } from 'date-fns'
import { enUS, ru, fr, de } from 'date-fns/locale'

import { getImageUrl } from 'src/utils/getImageUrl'

import { PORTAL_SETTINGS_STORAGE_KEY } from './constants'

import { PortalMemberSettings } from 'src/types/settings/portal-settings'

export const getGDPRLinks = (showTermsPage?: boolean, basePath = '', isStudent?: boolean) => {
  const origin = typeof window !== 'undefined' ? window.location.origin + basePath : ''

  const links = [
    {
      title: 'PAGE.ANALYTICS_GDPR_SETTINGS.imprint',
      url: isStudent ? `${origin}/customer/legal/imprint` : `${origin}/legal/imprint`
    },
    {
      title: 'PAGE.ANALYTICS_GDPR_SETTINGS.privacy_policy',
      url: isStudent ? `${origin}/customer/legal/privacy-policy` : `${origin}/legal/privacy-policy`
    }
  ]

  if (showTermsPage) {
    links.push({
      title: 'PAGE.ANALYTICS_GDPR_SETTINGS.terms_conditions',
      url: isStudent ? `${origin}/customer/legal/terms-conditions` : `${origin}/legal/terms-conditions`
    })
  }

  return links
}

const setBranding = (faviconUrl: string) => {
  if (typeof window !== 'undefined') {
    const link: HTMLLinkElement = document.querySelector("link[rel~='icon']") || document.createElement('link')
    link.rel = 'icon'
    link.href = faviconUrl
    link.id = 'dynamic-favicon'
    document.head.appendChild(link)
  }
}

const setTabTitle = (title?: string) => {
  if (typeof window !== 'undefined' && title && document.title !== title) {
    document.title = title
  }
}

const locales: Record<string, Locale> = { en: enUS, ru, fr, de }
export const getTimeFromNow = (timestamp: number, lang = 'en'): string => {
  return formatDistanceToNow(new Date(timestamp), { addSuffix: true, locale: locales[lang] || enUS })
}

export const loadBranding = () => {
  if (typeof window === 'undefined') return

  // Retrieve stored branding data from sessionStorage
  let storedBranding = JSON.parse(sessionStorage.getItem('branding') || '{}')

  // Get latest branding from portal settings
  const portalSettings = JSON.parse(localStorage.getItem(PORTAL_SETTINGS_STORAGE_KEY) || '{}') as PortalMemberSettings
  const faviconImage = portalSettings.general_design_settings?.[0]?.design_image?.[0]?.favicon
  const newFavicon = faviconImage ? getImageUrl(faviconImage) : '/app/images/favicon-96x96.png' // Default fallback

  const newTitle = portalSettings.general_design_settings?.[0]?.browser_tab_title

  // Apply stored branding immediately to prevent flickering
  if (storedBranding?.favicon) {
    setBranding(storedBranding.favicon)
  }
  if (storedBranding?.title) {
    setTabTitle(storedBranding.title)
  }

  // If there's no stored branding, initialize it
  if (!storedBranding.favicon || !storedBranding.title) {
    storedBranding = { favicon: newFavicon, title: newTitle }
    sessionStorage.setItem('branding', JSON.stringify(storedBranding))
  }

  // Only update branding if it has changed
  if (newFavicon !== storedBranding.favicon || newTitle !== storedBranding.title) {
    setBranding(newFavicon)
    setTabTitle(newTitle)
    sessionStorage.setItem('branding', JSON.stringify({ favicon: newFavicon, title: newTitle }))
  }
}

export const digistoreUrl = (email: string, firstName: string, lastName: string, selectedLang: string): string => {
  const encodedEmail = encodeURIComponent(email)

  let productID: string
  switch (selectedLang) {
    case 'de':
      productID = '416282'
      break
    case 'mk':
    case 'hu':
    case 'en':
      productID = '356923'
      break
    case 'cz':
    case 'sk':
      productID = '438159'
      break
    default:
      productID = '356923' // Default case
  }

  return `https://www.digistore24.com/product/${productID}?email=${encodedEmail}&first_name=${firstName}&last_name=${lastName}`
}
