import { useQuery } from '@tanstack/react-query'

import designService from 'src/service/design-service'

import queries from '../../queries'

export const useDesign = () => {
  return useQuery({
    ...queries.design.activeTemplate,
    queryFn: () => designService.getActiveDesignTemplate(),
    select: response => response.data.result
  })
}
