import { ValueOf } from '../common/utils'

export const LargeBannerType = {
  BOTTOM_BANNER: 1,
  CENTRAL_BANNER: 2
} as const

export type LargeBannerTypeId = ValueOf<typeof LargeBannerType>

export const TrackingCondition = {
  IF_USER_CONFIRM: 1,
  ALWAYS_USE_TRACKING: 2,
  DO_NOT_TRACK_ANYTHING: 3
} as const

export type TrackingConditionId = ValueOf<typeof TrackingCondition>

export type LargeBannerType = {
  id: LargeBannerTypeId
  name: string
}

export type TrackingCondition = {
  id: TrackingConditionId
  name: string
}

export type SettingsGDPRType = {
  gdpr_banner_about_text: string | null
  gdpr_banner_accept_label: string | null
  gdpr_banner_detail_text: string | null
  gdpr_banner_main_text: string | null
  gdpr_banner_reject_label: string | null
  gdpr_banner_text: string | null
  gdrp_signature_file: unknown
  imprint_text: string | null
  large_banner_type_id: LargeBannerTypeId
  portal_id: number
  privacy_policy_text: string | null
  show_large_banner: boolean
  show_small_banner: boolean
  show_terms_page: boolean
  terms_text: string | null
  tracking_condition_id: TrackingConditionId
}
