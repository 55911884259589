import { ElementType, ReactNode } from 'react'

import { styled, Theme } from '@mui/material/styles'
import { Button } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import Box from '@mui/material/Box'

type InputButtonVariant = 'start' | 'inner' | 'end'

type StyledButtonProps = ButtonProps & {
  readOnly?: boolean
  width?: string
  place?: InputButtonVariant
  component?: ElementType
}

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'readOnly' && prop !== 'place'
})<StyledButtonProps>(({ theme, readOnly, width, place }) => ({
  pointerEvents: readOnly ? 'none' : undefined,
  userSelect: readOnly ? 'none' : undefined,
  borderRadius: place ? getBorderRadius(theme, place) : undefined,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    maxWidth: theme.spacing(30),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block'
  },
  width
}))

const getBorderRadius = (theme: Theme, place: InputButtonVariant) => {
  switch (place) {
    case 'start':
      return `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
    case 'inner':
      return '0'
    case 'end':
      return `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
    default:
      return undefined
  }
}

export type BaseInputButton = ButtonProps & {
  children: ReactNode
  readOnly?: boolean
  width?: string
}

type InputButtonProps = BaseInputButton & {
  place: InputButtonVariant
}

export const InputButton = ({ readOnly, children, place, ...rest }: InputButtonProps) => {
  return (
    <StyledButton
      tabIndex={readOnly ? -1 : undefined}
      component={readOnly ? Box : Button}
      readOnly={readOnly}
      place={place}
      {...rest}
    >
      {children}
    </StyledButton>
  )
}
