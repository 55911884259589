import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import { NewPortalDomain, PortalAffiliate, PortalAffiliateKeys } from '../types/portal'

import service from './service'
import { abstractEntityService } from './abstract-entity-service'

import {
  SupportSettings,
  SettingsAnalyticsType,
  SupportType,
  LargeBannerType,
  SettingsGDPRType,
  TrackingCondition,
  CommunitySettings,
  CommunitySettingsKeys,
  CommunityDisplayNames,
  ConfigurationKeys,
  Configuration,
  AffiliateLinkKeys,
  AffiliateLink
} from 'src/types/settings'
import { FilterParams, ResponseResult, WithColumnsParam } from 'src/types/common/types'
import { ContentHomePageSettings } from 'src/types/settings/homepage'
import { DeepPartial } from 'src/types/common/utils'
import { PortalContactInfo, PortalMemberSettings } from 'src/types/settings/portal-settings'

export type UpdateSupportSettingsRequest = FilterParams<string, unknown, SupportSettings[]>
export type GetSettingsAnalyticsGDPRInfoRequest = FilterParams
export type UpdateSettingsAnalyticsInfoRequest = FilterParams<string, unknown, Partial<SettingsAnalyticsType>[]>
export type UpdateSettingsGDPRInfoRequest = FilterParams<string, unknown, Partial<SettingsGDPRType>[]>
export type UpdateCommunitySettingsRequest = FilterParams<string, unknown, Partial<CommunitySettings>[]>
export type GetCommunitySettingsRequest = FilterParams<CommunitySettingsKeys>
export type GetContentHomePageSettingsRequest = FilterParams
export type UpdateContentHomePageSettingsRequest = FilterParams<
  keyof ContentHomePageSettings,
  unknown,
  DeepPartial<ContentHomePageSettings>
>
export type GetAffiliateLinksRequest = FilterParams<AffiliateLinkKeys, unknown>
export type GetAffiliateLinksCountRequest = FilterParams
export type UpdateAffiliateLinksRequest = FilterParams<PortalAffiliateKeys, number, Partial<PortalAffiliate>>
export type GetConfigurationRequest = FilterParams<ConfigurationKeys, number, Partial<Configuration>>
export type UpdateConfigurationRequest = FilterParams<unknown, number, Partial<Configuration>>
export type UpdatePortalContactInfoRequest = FilterParams<unknown, number, DeepPartial<PortalContactInfo>>
export type UpdateDomainRequest = NewPortalDomain

export type UpdateSupportSettingsResponse = ResponseResult<boolean>
export type GetSupportSettingsResponse = ResponseResult<SupportSettings[]>
export type GetSupportTypesResponse = ResponseResult<SupportType[]>
export type GetSettingsAnalyticsInfoResponse = ResponseResult<SettingsAnalyticsType[]>
export type GetSettingsGDPRInfoResponse = ResponseResult<SettingsGDPRType[]>
export type UpdateSettingsAnalyticsGDPRInfoResponse = ResponseResult<boolean>
export type GetLargeBannerTypeResponse = ResponseResult<LargeBannerType[]>
export type GetTrackingConditionResponse = ResponseResult<TrackingCondition[]>
export type GetContentHomePageSettingsResponse = ResponseResult<ContentHomePageSettings[]>
export type UpdateContentHomePageSettingsResponse = ResponseResult<number>
export type UpdateCommunitySettingsResponse = ResponseResult<number>
export type GetCommunitySettingsResponse = ResponseResult<CommunitySettings[]>
export type GetCommunityDisplayNamesResponse = ResponseResult<CommunityDisplayNames>
export type GetAffiliateLinksResponse = ResponseResult<AffiliateLink[]>
export type GetAffiliateLinksCountResponse = ResponseResult<number>
export type UpdateAffiliateLinksResponse = ResponseResult<number>
export type GetConfigurationResponse = ResponseResult<Configuration[]>
export type UpdateConfigurationResponse = ResponseResult<number>
export type UpdateDomainResponse = ResponseResult<boolean>
export type GetPortalSettingsResponse = ResponseResult<PortalMemberSettings>
export type UpdatePortalContactInfoResponse = ResponseResult<boolean>
export type GetDesignResponse = { done: boolean; error: string | null; result: boolean }
export type UpdateDesignResponse = { done: boolean; error: string | null; result: boolean }

const settingsService = {
  getSupportSettings: (): Promise<AxiosResponse<GetSupportSettingsResponse>> => {
    return service.post(requestUrls.supportSettings)
  },
  getSupportTypes: (): Promise<AxiosResponse<GetSupportTypesResponse>> => {
    return service.post(requestUrls.supportTypes)
  },
  updateSupportSettings: (
    requestBody: UpdateSupportSettingsRequest
  ): Promise<AxiosResponse<UpdateSupportSettingsResponse>> => {
    return service.post(requestUrls.supportSettingsUpdate, requestBody)
  },
  getSettingsGDPRInfo: async (
    requestBody: GetSettingsAnalyticsGDPRInfoRequest
  ): Promise<AxiosResponse<GetSettingsGDPRInfoResponse>> => {
    return service.post(requestUrls.getSettingsGdprInfo, requestBody)
  },
  getSettingsAnalyticsInfo: async (
    requestBody: GetSettingsAnalyticsGDPRInfoRequest
  ): Promise<AxiosResponse<GetSettingsAnalyticsInfoResponse>> => {
    return service.post(requestUrls.getSettingsAnalyticsInfo, requestBody)
  },
  getSettingsLargeBannerType: async (
    requestBody: WithColumnsParam
  ): Promise<AxiosResponse<GetLargeBannerTypeResponse>> => {
    return service.post(requestUrls.getSettingsLargeBannerType, requestBody)
  },
  getSettingsTrackingCondition: async (
    requestBody: GetSettingsAnalyticsGDPRInfoRequest
  ): Promise<AxiosResponse<GetTrackingConditionResponse>> => {
    return service.post(requestUrls.getSettingsTrackingCondition, requestBody)
  },
  updateSettingsAnalyticsInfo: async (
    requestBody: UpdateSettingsAnalyticsInfoRequest
  ): Promise<AxiosResponse<UpdateSettingsAnalyticsGDPRInfoResponse>> => {
    return service.post(requestUrls.updateSettingsAnalytics, requestBody)
  },
  updateSettingsGDPRInfo: async (
    requestBody: UpdateSettingsGDPRInfoRequest
  ): Promise<AxiosResponse<UpdateSettingsAnalyticsGDPRInfoResponse>> => {
    return service.post(requestUrls.updateSettingsGdpr, requestBody)
  },
  getCommunitySettings: async (
    requestBody: GetCommunitySettingsRequest
  ): Promise<AxiosResponse<GetCommunitySettingsResponse>> => {
    return abstractEntityService.post(requestUrls.getCommunitySettingsInfo, requestBody)
  },
  getCommunityDisplayNames: async (): Promise<AxiosResponse<GetCommunityDisplayNamesResponse>> => {
    return abstractEntityService.get(requestUrls.getCommunityDisplayNames)
  },
  updateCommunitySettings: async (
    requestBody: UpdateCommunitySettingsRequest
  ): Promise<AxiosResponse<UpdateCommunitySettingsResponse>> => {
    return service.post(requestUrls.updateCommunitySettings, requestBody)
  },
  getContentHomePageSettings: async (
    requestBody?: GetContentHomePageSettingsRequest
  ): Promise<AxiosResponse<GetContentHomePageSettingsResponse>> => {
    return service.post(requestUrls.getContentHomePageSettings, requestBody)
  },
  updateContentHomePageSettings: async (
    requestBody?: UpdateContentHomePageSettingsRequest
  ): Promise<AxiosResponse<UpdateContentHomePageSettingsResponse>> => {
    return service.post(requestUrls.updateContentHomePageSettings, requestBody)
  },

  getAffiliateLinks: async (
    requestBody: GetAffiliateLinksRequest
  ): Promise<AxiosResponse<GetAffiliateLinksResponse>> => {
    return service.post(requestUrls.getAffiliateLinks, requestBody)
  },
  getAffiliateLinksCount: async (
    requestBody?: GetAffiliateLinksCountRequest
  ): Promise<AxiosResponse<GetAffiliateLinksCountResponse>> => {
    return service.post(requestUrls.getAffiliateLinksCount, requestBody)
  },
  updateAffiliateLinks: async (
    requestBody: UpdateAffiliateLinksRequest
  ): Promise<AxiosResponse<UpdateAffiliateLinksResponse>> => {
    return service.post(requestUrls.updateAffiliateLinks, requestBody)
  },

  getConfiguration: (requestBody?: GetConfigurationRequest): Promise<AxiosResponse<GetConfigurationResponse>> => {
    return service.post(requestUrls.getConfiguration, requestBody)
  },
  updateConfiguration: (
    requestBody?: UpdateConfigurationRequest
  ): Promise<AxiosResponse<UpdateConfigurationResponse>> => {
    return service.post(requestUrls.updateConfiguration, requestBody)
  },
  updateDomain: (requestBody: UpdateDomainRequest): Promise<AxiosResponse<UpdateDomainResponse>> => {
    return service.post(requestUrls.updatePortalDomain, requestBody)
  },
  getPortalSettings: (): Promise<AxiosResponse<GetPortalSettingsResponse>> => {
    return abstractEntityService.get(requestUrls.getPortalSettings)
  },

  getDesignState: (): Promise<AxiosResponse<GetDesignResponse>> => {
    return service.get(requestUrls.getDesignState)
  },

  updateDesignState: (requestBody: { use_new_design: boolean }): Promise<AxiosResponse<UpdateDesignResponse>> => {
    return service.post(requestUrls.updateDesignState, requestBody)
  },

  updatePortalContactInfo: (
    requestBody: UpdatePortalContactInfoRequest
  ): Promise<AxiosResponse<UpdatePortalContactInfoResponse>> => {
    return service.post(requestUrls.updatePortalContactInfo, requestBody)
  }
}

export default settingsService
