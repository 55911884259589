export const requestUrls = {
  //Moderators
  getModerators: '/community_api/v1/moderator/get',
  deleteModerator: '/community_api/v1/moderator/delete',
  addModerator: '/community_api/v1/moderator/add',
  checkModerator: '/community_api/v1/moderator/check',
  getCommunityMembers: '/community_api/v1/community/portal_member/get',

  // Threads
  getThreadMessageReplies: '/community_api/v1/thread_message/replies/list',
  getThreadMessages: '/community_api/v2/thread_message/list',
  getThreadMessagesCount: '/community_api/v1/thread_message/count',
  addThreadMessage: '/community_api/v1/thread_message/add',
  updateThreadMessage: '/community_api/v1/thread_message/update',
  deleteThreadMessage: '/community_api/v1/thread_message/delete',
  addLikeThreadMessage: '/community_api/v1/thread_message/like/add',
  deleteLikeThreadMessage: '/community_api/v1/thread_message/like/delete',
  getThreadsCount: '/community_api/v1/thread/count',
  getThreads: '/community_api/v1/thread/list',
  addThread: '/community_api/v1/thread/add',
  updateThread: '/community_api/v1/thread/update',
  deleteThread: '/community_api/v1/thread/delete',
  getLikesThread: '/community_api/v1/thread/like/get',
  addLikeThread: '/community_api/v1/thread/like/add',
  deleteLikeThread: '/community_api/v1/thread/like/delete',
  getThread: '/community_api/v1/thread/info',
  followThread: '/community_api/v1/thread/subscribe',
  unfollowThread: '/community_api/v1/thread/unsubscribe',
  moveThread: '/community_api/v1/thread/move',
  getMyThreads: '/community_api/v1/thread/my/get',
  getScheduledThreadsCount: '/community_api/v1/thread/my/count',

  // Topics
  getTopics: '/community_api/v2/topic/list',
  addTopic: '/community_api/v1/topic/add',
  deleteTopic: '/community_api/v1/topic/delete',
  updateTopic: '/community_api/v1/topic/update',
  getTopic: '/community_api/v1/topic/info',
  subscribe: '/community_api/v1/topic/subscribe',
  unsubscribe: '/community_api/v1/topic/unsubscribe',

  // Leaderboard
  getLeaderboardLevels: '/community_api/v1/leader_board_level/list',
  addLeaderboardLevels: '/community_api/v1/leader_board_level/add',
  updateLeaderboardLevels: '/community_api/v1/leader_board_level/update',
  deleteLeaderboardLevels: '/community_api/v1/leader_board_level/delete',
  getLeaderboardStatistic: '/community_api/v1/leader_board/get',
  getLeaderboardCustomerStatistic: '/community_api/v1/customer/leader_board/get',

  // Communities
  getCommunities: '/community_api/v1/community/list',
  getCommunity: '/community_api/v1/community/info',
  updateCommunity: '/community_api/v1/community/update',
  addCommunity: '/community_api/v1/community/add',
  deleteCommunity: '/community_api/v1/community/delete',
  joinCommunity: '/community_api/v1/community/rejoin',
  leaveCommunity: '/community_api/v1/community/leave',

  // App requests
  appRequests: '/entity_api/entity/app_request/get',
  appRequestsCount: '/entity_api/entity/app_request/count',
  appRequestsUpdate: '/entity_api/entity/app_request/update',
  appRequestsAdd: '/entity_api/entity/app_request/add',
  appRequestPublic: '/entity_api/app_request/public/get',
  appRequestDetails: '/entity_api/app_request/details',
  appRequestSubmitFacebook: '/entity_api/app_request/submit_facebook',

  // App requests v2
  appRequestSummary: '/entity_api/v2/app_request/summary',
  appRequestDetailsV2: '/entity_api/v2/app_request/details',
  appRequestAccessesShare: '/entity_api/v2/app_request/accesses_share',
  appRequestTestUser: '/entity_api/v2/app_request/test_user',
  appRequestMarketing: '/entity_api/v2/app_request/marketing',

  // Courses
  coursesV2: '/new_entity_api/entity/course/get',
  getModulesV2: '/new_entity_api/entity/course_module/get',
  getLessonsV2: '/new_entity_api/entity/module_lesson/get',
  updateLessonThreadBinding: '/entity_api/module_lesson/thread_id/update',
  createCourse: '/entity_api/entity/course/add',
  courses: '/entity_api/entity/course/get',
  getModules: '/entity_api/entity/course_module/get',
  getLessons: '/entity_api/entity/module_lesson/get',
  courseCount: '/entity_api/entity/course/count',
  courseReorder: '/entity_api/entity/course/upsert',
  copyCourse: '/entity_api/portal/course/copy',
  courseUpdate: '/entity_api/entity/course/update',
  courseDelete: '/entity_api/entity/course/delete',
  moduleById: '/entity_api/portal/module/entities/get',
  newSubmodule: '/entity_api/entity/course_submodule/add',
  copyModule: '/entity_api/portal/module/copy',
  deleteModule: '/entity_api/entity/course_module/delete',
  moduleDetails: '/entity_api/entity/course_module/info',
  moduleAdd: '/entity_api/entity/course_module/add',
  moduleDelete: '/entity_api/entity/course_module/delete',
  moduleDuplicate: '/entity_api/portal/module/copy',
  moduleReorder: '/entity_api/entity/course_module/upsert',
  moduleLessonReorder: '/entity_api/entity/module_lesson/update',
  moduleSubmoduleReorder: '/entity_api/entity/course_submodule/update',
  getLessonDetails: '/entity_api/entity/module_lesson/info',
  newLesson: '/entity_api/entity/module_lesson/add',
  copyLesson: '/entity_api/portal/lesson/copy',
  getLessonPublicInfo: '/entity_api/portal/lesson/public_info/get',
  deleteLesson: '/entity_api/entity/module_lesson/delete',
  moduleUpdate: '/entity_api/entity/course_module/update',
  submoduleUpdate: '/entity_api/entity/course_submodule/update',
  submoduleDelete: '/entity_api/entity/course_submodule/delete',
  lessonUpdate: '/entity_api/entity/module_lesson/update',
  courseOwnershipReason: '/entity_api/portal/course_ownership_reason/get',
  courseOwnershipReasonCount: '/entity_api/portal/course_ownership_reason/count',
  lessonProgress: '/entity_api/entity/customer_lesson_progress/info',
  updateLessonProgress: '/entity_api/entity/customer_lesson_progress/update',
  courseInfo: '/entity_api/entity/course/info',
  getCourseInfo: '/entity_api/entity/course/info',
  coursePaymentCheck: '/entity_api/portal/course/payment/check',
  courseAccessCheck: '/entity_api/portal/course/access/check',
  courseToCommunity: '/entity_api/entity/course_to_community/get',
  courseToCommunityBind: '/entity_api/courses_to_community/bind',

  // Course categories
  getCourseCategories: '/entity_api/entity/course_category/get',
  createCourseCategory: '/entity_api/entity/course_category/add',
  updateCourseCategory: '/entity_api/entity/course_category/update',
  deleteCourseCategory: '/entity_api/entity/course_category/delete',

  // Course templates
  getCourseTemplates: '/entity_api/portal/partner_area/course/get',
  getCourseTemplatesCount: '/entity_api/portal/partner_area/course/count',
  importCourse: '/entity_api/portal/course/import',

  // Orders
  orders: '/entity_api/entity/order/get',
  deleteOrder: '/entity_api/entity/order/delete',
  billingType: '/entity_api/entity/billing_type/get',
  gatherRequest: '/entity_api/gather/entity/request',
  createOrder: '/entity_api/customer/course/gift',

  // Members
  getMembersInfo: '/entity_api/entity/portal_member/info',
  getMembers: '/entity_api/entity/portal_member/get',
  getMembersCount: '/entity_api/entity/portal_member/count',
  updateMember: '/entity_api/user_profile/update',
  deleteProfile: '/entity_api/user_profile/delete',
  deleteMember: '/entity_api/entity/portal_member/delete',

  // GDPR & Analytics
  getSettingsGdprInfo: '/entity_api/entity/settings_imprint_gdrp/info',
  getSettingsAnalyticsInfo: '/entity_api/entity/settings_analytics/info',
  getSettingsLargeBannerType: '/entity_api/entity/large_banner_type/get',
  getSettingsTrackingCondition: '/entity_api/entity/tracking_condition/get',
  updateSettingsAnalytics: '/entity_api/entity/settings_analytics/upsert',
  updateSettingsGdpr: '/entity_api/entity/settings_imprint_gdrp/upsert',

  // Optins
  getOptins: '/entity_api/entity/opt_in_process/get',
  getOptin: '/entity_api/entity/opt_in_process/info',
  getOptinsCount: '/entity_api/entity/opt_in_process/count',
  createOptin: '/entity_api/entity/opt_in_process/add',
  updateOptin: '/entity_api/entity/opt_in_process/update',
  deleteOptin: '/entity_api/entity/opt_in_process/delete',

  // Email
  getEmailVariables: '/entity_api/entity/content_email_template_type/get',
  validateEmailTemplate: '/entity_api/portal/settings/email_template/check',
  sendTestThankYouEmail: '/entity_api/lead/thank_you/email/test',
  sendTestActivationEmail: '/entity_api/lead/activation/email/test',
  setUpCodemagic: '/entity_api/app_request/integration/codemagic/general/setup',
  getEmailTemplates: '/notifications_api/v1/template/get',
  getEmailSettings: '/entity_api/entity/content_email_settings/get',
  updateEmailSettings: '/entity_api/entity/content_email_settings/update',
  updateWelcomeEmailSettings: '/entity_api/entity/settings_welcome_email/update',
  getWelcomeEmailSettings: '/entity_api/entity/settings_welcome_email/get',
  getWelcomeEmailTypes: '/entity_api/entity/mailing_settings_type/get',
  getSendersList: '/email_server_api/v1/sender/list',
  getSendersCount: '/email_server_api/v1/sender/count',
  activateSender: '/email_server_api/v1/sender/activate',
  deactivateSender: '/email_server_api/v1/sender/deactivate',
  checkSenderDNS: '/email_server_api/v1/sender/settings/check',
  updateSMTPSettings: '/entity_api/portal/settings/smtp/set',
  getSMTPSettings: '/entity_api/entity/settings_smtp/get',
  addSender: '/email_server_api/v1/sender/add',
  sendHelpEmail: '/entity_api/helpdesk/email/send',

  // Stats
  memberStats: '/entity_api/stats/members',
  cashflowStats: '/entity_api/stats/cash_flow',
  activityStats: '/entity_api/stats/user_activity',
  ordersPlacedStats: '/entity_api/stats/new_orders',
  totalMembersStats: '/entity_api/stats/total_members',

  // Landings
  getLandings: '/entity_api/entity/landing/get',
  newLandings: '/landing_api/v1/landings/',
  getLandingInfo: '/entity_api/entity/landing/info',
  getNewLandingsCount: '/landing_api/v1/landings/count',
  landingPageTemplates: '/landing_api/landing_api/v1/admin/templates/',
  getLandingPageTemplatesCount: '/landing_api/v1/admin/templates/count',
  landingPageTemplatesPublic: '/landing_api/landing_api/v1/public/templates/',
  getLandingPageTemplatesCountPublic: '/landing_api/v1/public/templates/count',
  getLandingsCount: '/entity_api/entity/landing/count',
  deleteLanding: '/entity_api/entity/landing/delete',

  //Trial user
  resendVerificationEmail: '/entity_api/auth/verification/email/send',

  // Password
  generatePassword: '/entity_api/auth/password/customer/generate',
  updatePassword: '/entity_api/auth/password/change',

  // Support Settings
  supportSettings: '/entity_api/entity/support_settings/get',
  supportTypes: '/entity_api/entity/support_type/get',
  supportSettingsUpdate: '/entity_api/entity/support_settings/upsert',

  // Marketplaces
  getMarketplaceInfo: '/entity_api/entity/marketplace/info',
  getMarketplaceBindings: '/entity_api/entity/portal/get',
  getPortalApiKey: '/entity_api/entity/portal_api_key/get',
  addMarketPlaceProvider: '/entity_api/portal/marketplace/api_key/add',
  removeMarketPlaceProvider: '/entity_api/entity/portal_to_marketplace/delete',
  generateCopecartCredentials: '/entity_api/copecart/credentials/generate',
  generateFapiAcceptUrl: '/entity_api/fapi/accept_url/generate',
  generateZapierApiKey: '/entity_api/auth/apikey/generate',
  getProduct: '/entity_api/portal/marketplace/product/get',
  updateProduct: '/entity_api/entity/marketplace_product/update',
  getAccessTypes: '/entity_api/entity/product_type/get',

  // Community settings
  getCommunitySettingsInfo: '/new_entity_api/entity/community_settings/get',
  getCommunityDisplayNames: '/new_entity_api/community_settings/display_name_setting_code/get',
  updateCommunitySettings: '/entity_api/entity/community_settings/update',

  // Content home page settings
  getContentHomePageSettings: '/entity_api/entity/content_homepage_settings/get',
  updateContentHomePageSettings: '/entity_api/entity/content_homepage_settings/update',

  // Design settings
  getDesignSettings: '/entity_api/entity/design_settings/get',
  getDesignSettingsCodes: '/entity_api/entity/design_settings_code/get',
  getPortalConstants: '/entity_api/portal/constants/get',
  activatePortalDesign: '/entity_api/portal/design/activate',
  updateFooterDesignSettings: '/entity_api/entity/footer_design_settings/update',
  updatePortalDesignImages: '/entity_api/entity/portal_design_images/update',
  updateDesignSettings: '/entity_api/entity/design_settings/update',
  updateGeneralDesignSettings: '/entity_api/entity/general_design_settings/update',
  updateCustomerLessonSettings: '/entity_api/entity/customer_lesson_page_design_settings/update',
  getGeneralDesignSettings: '/entity_api/entity/general_design_settings/get',
  getPortalToDesignSettings: '/entity_api/entity/portal_to_design_settings/get',
  getActiveDesignTemplate: '/design_api/v1/web/design/active',
  getDesignTemplates: '/design_api/v1/web/design/',
  getDesignAppTemplates: '/design_api/v1/app/design/',
  webDesignCustom: '/design_api/v1/web/design/custom',
  appDesignCustom: '/design_api/v1/app/design/custom',
  portalDesign: '/design_api/v1/portal/settings/',

  getExternalSystemIntegrationList: '/integration_api/v1/integration/external_system/list',
  getIntegrationList: '/integration_api/v1/integration/list',
  addIntegration: '/integration_api/v1/integration/add',
  removeIntegration: '/integration_api/v1/integration/delete',
  updateIntegration: '/integration_api/v1/integration/update',
  addIntegrationWithJwt: '/integration_api/v1/integration/handle_jwt',
  getOathUri: '/integration_api/v1/integration/get_oath_uri',
  getIntegrationRuleEventList: '/integration_api/v1/rule/event/list',
  getIntegrationActionList: '/integration_api/v1/rule/action/list',
  getIntegrationRuleList: '/integration_api/v1/rule/list',
  getIntegrationRuleCount: '/integration_api/v1/rule/count',
  getIntegrationErrorTypeList: '/integration_api/v1/error_type/list',
  getIntegrationErrorList: '/integration_api/v1/error_log/list',
  getIntegrationMailingList: '/integration_api/v1/integration/mailing_lists',
  addIntegrationRule: '/integration_api/v1/rule/add',
  updateIntegrationRule: '/integration_api/v1/rule/update',
  removeIntegrationRule: '/integration_api/v1/rule/delete',

  getPortal: '/entity_api/entity/portal/get',

  courseProgress: '/entity_api/portal/course/progress/get',
  getConfiguration: '/entity_api/entity/configuration/get',
  updateConfiguration: '/entity_api/entity/configuration/update',

  // Common
  getGenders: 'entity_api/entity/gender/get',
  getNiche: 'entity_api/entity/niche/get',
  getVimeoId: '/entity_api/entity/file_id_to_vimeo_id/info',
  getLanguages: '/entity_api/entity/language/get',

  // Notification settings
  getCommunityNotificationSettings: '/community_api/v1/user_notification_settings/get',
  updateCommunityNotificationSettings: '/community_api/v1/user_notification_settings/set',
  getNotificationSettings: '/notifications_api/v1/user_notification_settings/get',
  getNotifications: '/notifications_api/v1/user_notifications/get',
  markNotificationsAsRead: '/notifications_api/v1/mark_notifications_as_read',
  updateNotificationSettings: '/notifications_api/v1/user_notification_settings/set',

  // Logs
  getUserActionLog: '/entity_api/portal/user_action_log/get',
  getCommunityUserLogListCount: 'community_api/v1/user_log/count',
  getCommunityUserLogList: 'community_api/v1/user_log/list',
  getCommunityThreadLog: 'community_api/v1/thread/get',
  getEventType: '/entity_api/entity/event_type/get',

  // Custom menu settings
  getCustomMenuSettings: '/new_entity_api/entity/custom_menu_settings/get',
  getCustomMenuSettingsDeprecated: '/entity_api/entity/custom_menu_settings/get',
  updateCustomMenuSettings: '/entity_api/entity/custom_menu_settings/upsert',
  deleteCustomMenuSettings: '/entity_api/entity/custom_menu_settings/delete',

  // Login page settings
  getTextLoginPageSettings: '/entity_api/entity/content_login_page_settings/get',
  updateTextLoginPageSettings: '/entity_api/entity/content_login_page_settings/upsert',
  getImageLoginPageSettings: '/entity_api/entity/content_design_settings/get',
  updateImageLoginPageSettings: '/entity_api/entity/content_design_settings/update',

  // CC Signup
  registerCC: '/entity_api/auth/register/content_creator',

  // Quizzes
  getQuizzes: 'entity_api/entity/module_lesson/get',
  getQuizPasses: 'entity_api/customer/quiz/assessment/get',
  getQuizResults: 'entity_api/quiz/results/get',
  getPortalEntity: '/entity_api/root_action/portal/get',

  // Portal
  getPortalType: '/entity_api/entity/portal_type/get',
  loginAsCC: '/entity_api/auth/admin/content_creator/login',
  getPortalLanguage: '/entity_api/portal/language/get',
  getPortalSettings: '/new_entity_api/portal/settings/get',
  updateCourseAccess: '/entity_api/portal/course/access/update',
  updateModuleAccess: '/entity_api/portal/module/access/update',
  updateLessonAccess: '/entity_api/portal/lesson/access/update',
  unlockAllLessons: '/entity_api/portal/module/access/available',
  updatePortal: '/entity_api/entity/portal/update',
  updatePortalDomain: '/entity_api/portal/domain/save',
  getDesignState: '/static_api/design',
  updateDesignState: '/design_api/v1/web/design/newdesign',
  updatePortalContactInfo: '/entity_api/entity/portal_contact_info/update',

  // Affiliate
  upsertPortalAffiliate: 'entity_api/entity/portal_affiliate/upsert',
  updatePortalMember: 'entity_api/root_action/portal_member/update',
  getAffiliateLinks: '/entity_api/entity/partner_area/get',
  getAffiliateLinksCount: '/entity_api/entity/partner_area/count',
  updateAffiliateLinks: '/entity_api/entity/portal_affiliate/upsert',
  getWelcomeBlock: '/entity_api/entity/welcome_box_settings/info',
  updateWelcomeBlock: '/entity_api/entity/welcome_box_settings/update',
  activateContentBlock: '/entity_api/welcome_box_settings_content_block/activate',
  getCourseList: '/entity_api/entity/customer_course_list_design_settings/info',
  updateCourseList: '/entity_api/entity/customer_course_list_design_settings/update',

  // OpenAI
  generateStructure: '/openai_connector_api/v1/course/structure/generate',

  // Customer Courses API urls
  myCourses: '/entity_api/portal/course/my/get',
  paidCourses: '/entity_api/portal_v2/course/other/get',
  freeCourses: '/entity_api/portal_v2/course/free/get',
  getModule: '/entity_api/portal_v2/module/get',
  getLesson: '/entity_api/portal_v2/lesson/get',

  getCourseProgress: '/entity_api/portal/course/progress/get',
  courseFreeBind: '/entity_api/customer/course/free/bind',
  coursePreviewAdd: '/entity_api/preview/course/add',
  getAssesmentQuestionAnswer: '/entity_api/entity/assessment_question_answer/get',
  addFreeCourseToCustomer: '/entity_api/customer/course/free/add',
  resetPassword: '/entity_api/auth/password/reset',
  addFreeCourseToCustomerWithDouble: '/entity_api/lead/course/add',

  // Calendar
  getEvents: 'calendar_api/v1/calendar_event/get',
  getTimezones: 'calendar_api/v1/calendar_event/time_zone/get',
  getFrequencies: 'calendar_api/v1/calendar_event/frequency/get',
  getAccesses: 'calendar_api/v1/calendar_event/access/get',
  addEvent: 'calendar_api/v1/calendar_event_type/add',
  getCurrentTimestamp: 'entity_api/current_timestamp/get',
  updateOnlyCurrentEvent: '/calendar_api/v1/calendar_event/update',
  updateCurrentAndFutureEvent: '/calendar_api/v1/calendar_event/departure/update',
  updateAllEvents: '/calendar_api/v1/calendar_event_type/update',
  deleteOnlyCurrentEvent: 'calendar_api/v1/calendar_event/delete',
  deleteCurrentAndFutureEvent: 'calendar_api/v1/calendar_event/departure/delete',
  deleteAllEvents: 'calendar_api/v1/calendar_event_type/delete',

  // AI Video
  getAIVideoGenerationAccess: '/entity_api/v1/ai_video_generation_access/get',
  getAIVideoStatistics: 'ai_video_api/v1/presentation/video/generation/statistics/get',
  getAIVideoStatisticsCount: 'ai_video_api/v1/presentation/video/generation/statistics/count',
  getAIVideosGenerationAccess: '/entity_api/entity/ai_video_generation_access/get',
  getAIVideoGenerationAccessCount: '/entity_api/entity/ai_video_generation_access/count',
  aIVideoGenerationAccessAdd: '/entity_api/entity/ai_video_generation_access/add',
  aIVideoGenerationAccessDelete: '/entity_api/entity/ai_video_generation_access/delete',

  getAvatarList: '/ai_video_api/v1/avatar/list',
  addAvatar: '/ai_video_api/v1/avatar/add',
  deleteAvarar: '/ai_video_api/v1/avatar/delete',

  getVoiceList: '/ai_video_api/v1/voice/list',
  addVoice: '/ai_video_api/v1/voice/add',
  deleteVoice: '/ai_video_api/v1/voice/delete',

  getGenerationSettings: '/ai_video_api/v1/generation_settings/get',
  addGenerationSettings: '/ai_video_api/v1/generation_settings/add',
  deleteGenerationSettings: '/ai_video_api/v1/generation_settings/delete',

  getVideoPresentationList: '/ai_video_api/v1/presentation/video/generation/list',
  getVideoPresentationCount: '/ai_video_api/v1/presentation/video/generation/count',
  startGenerateVideoPresentation: '/ai_video_api/v1/presentation/video/generation/start',
  deleteVideoPresentation: '/ai_video_api/v1/presentation/video/generation/delete',
  checkVideoPresentationGenerationStatus: '/ai_video_api/v1/presentation/video/generation/status/check'
}
