import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { getTimeFromNow } from 'src/utils/settings/portal-settings/utils'

import memberService from '../../service/member-service'
import useToast from '../useToast'

const useTrial = () => {
  const { t } = useTranslation()
  const { notify } = useToast()

  const { mutateAsync: resendVerificationEmail } = useMutation({
    mutationFn: memberService.resendVerificationEmail,
    onSuccess: response => {
      if (response) {
        notify(t('TRIAL_INFO.ALERT.SUCCESS.resend_verification'), { type: 'success' })
      } else {
        notify(t('TRIAL_INFO.ALERT.ERROR.resend_verification'), { type: 'error' })
      }
    },
    onError: (err: {
      error: {
        error: string
      }
    }) => {
      const errText = err?.error?.error || ''
      if (errText.includes('resend email until')) {
        const timestamp = errText.slice(errText.lastIndexOf(':') + 1).trim()
        const timeTextObj = { text: getTimeFromNow(+timestamp, 'en') }
        notify(t('TRIAL_INFO.ALERT.ERROR.resend_verification_timeout', { text: timeTextObj }), { type: 'error' })
      } else {
        notify(t('TRIAL_INFO.ALERT.ERROR.resend_verification'), { type: 'error' })
      }
    }
  })

  return {
    resendVerificationEmail
  }
}

export default useTrial
