import { useMutation } from '@tanstack/react-query'

import fileService from 'src/service/file-service'

import useToast from './useToast'

import { FileUploadResult } from 'src/types/files/upload'

const useFileMutation = (
  onChangeProgess?: (progress: number, id?: string) => void,
  onUploadSuccess?: (result: FileUploadResult, id?: string) => void,
  onDeleteSuccess?: (fileId: string) => void
) => {
  const { notify } = useToast()

  const { mutateAsync: uploadFile, isPending } = useMutation({
    mutationFn: ({ file, id }: { file: File, id?: string }) => {
      const formData = new FormData()
      formData.append('payload', file)

      return fileService.uploadFile(formData, (progress) => onChangeProgess?.(progress, id))
    },
    onSuccess: (result, { id }) => {
      notify('upload', { type: 'success' })
      onUploadSuccess?.(result, id)
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' })
  })

  const { mutateAsync: deleteFile } = useMutation({
    mutationFn: (media_file_id: string) => fileService.deleteFile({ media_file_id: media_file_id }),
    onSuccess: (_, id: string) => {
      onDeleteSuccess?.(id)
      notify('delete', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' })
  })

  return {
    uploadFile,
    isUploadPending: isPending,
    deleteFile
  }
}

export default useFileMutation
