import { AxiosResponse } from 'axios'

import { requestUrls } from 'src/configs/url'

import service from './service'

import { FilterParams, ResponseResult } from 'src/types/common/types'
import { VimeoByFileId } from 'src/types/files/vimeo'

export type GetVimeoIdRequest = FilterParams<['file_id'], string>
export type GetVimeoIdResponse = ResponseResult<VimeoByFileId[]>

export const videoService = {
  getVimeoId: (requestBody: GetVimeoIdRequest): Promise<AxiosResponse<GetVimeoIdResponse>> => {
    return service.post(requestUrls.getVimeoId, requestBody)
  }
}
