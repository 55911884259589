import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { alpha } from '@mui/system'


export const UploaderPendingUploadContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'center',
  width: '100%'
}))

export const UploaderPendingUploadInnerColoredBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  justifyContent: 'center',
  padding: theme.spacing(8, 6),
  width: '100%'
}))

export const UploaderPendingUploadContentBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  justifyContent: 'center'
}))

export const UploaderPendingUploadContentTextsBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center'
}))
