import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Stack, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { UseFormRegister } from 'react-hook-form'

import Icon from '../../../../@core/components/icon'
import CustomTextField from '../../../../@core/components/mui/text-field'
import { DeleteProfileFormData } from '../../../../schemas/settings/profile'

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  width: '100%',
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  padding: theme.spacing(16.25)
}))

const ModalBody = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 68px)'
})

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4)
}))

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

type Props = {
  isOpen: boolean
  onClose: () => void
  register: UseFormRegister<DeleteProfileFormData>
  onSubmit: () => Promise<void>
  isValid: boolean
  isSubmitting: boolean
}

export const DeleteAccountModal = ({ isOpen, onClose, register, onSubmit, isValid, isSubmitting }: Props) => {
  const theme = useTheme()

  const { t: tAction } = useTranslation('translation', { keyPrefix: 'ACTION' })
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS.FORM' })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onClose} />
        </IconBox>
        <Stack spacing={theme.spacing(4)}>
          <ModalBody>
            <Stack spacing={theme.spacing(4)}>
              <Stack spacing={theme.spacing(1)}>
                <Typography variant='h5'>{t('delete_modal_title')}</Typography>
                <Typography variant='body1'>{t('delete_modal_subtitle')}</Typography>
              </Stack>
              <Typography variant='body1'>{t('delete_modal_instructions')}</Typography>
              <Box paddingX={theme.spacing(6.5)}>
                <CustomTextField fullWidth {...register('deleteLiteral')} />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant='tonal' color='primary' onClick={onClose}>
              {tAction('cancel')}
            </Button>
            <LoadingButton
              variant='contained'
              color='error'
              onClick={onSubmit}
              loading={isSubmitting}
              disabled={!isValid}
            >
              {t('delete_account')}
            </LoadingButton>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  )
}
