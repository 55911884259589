import { createQueryKeys } from '@lukemorales/query-key-factory'

const portal = createQueryKeys('portal', {
  all: {
    queryKey: null
  },
  language: {
    queryKey: null
  },
  constants: {
    queryKey: null
  }
})

export default portal
