import { createQueryKeys } from '@lukemorales/query-key-factory'

import { EmailType } from '../types/settings/email/email'

const emails = createQueryKeys('emails', {
  // todo: update after merge
  allEmailVariables: {
    queryKey: null
  },
  emailVariables: (emailTypes: EmailType[]) => [emailTypes],
  emailSettings: (emailTypes?: EmailType[]) => [emailTypes],
  allWelcomeEmailTypes: {
    queryKey: null
  },
  allWelcomeEmailSettings: {
    queryKey: null
  },
  allSenders: {
    queryKey: null
  },
  allSendersCount: {
    queryKey: null
  },
  customSMTP: {
    queryKey: null
  }
})

export default emails
