import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import queries from '../../queries'
import languageService, { GetLanguagesOptionsResponse } from '../../service/language-service'
import { Prettify } from '../../types/common/utils'
import { Language } from '../../types/languages'

type RefinedQueryOptions = Prettify<
  Omit<UseQueryOptions<AxiosResponse<GetLanguagesOptionsResponse>, Error, Language[]>, 'queryKey' | 'queryFn'>
>

export const useLanguages = (options?: RefinedQueryOptions) => {
  return useQuery({
    ...queries.languages.all,
    queryFn: languageService.getLanguages,
    select: response => response.data.result,
    ...options
  })
}
