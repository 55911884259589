import { createQueryKeys } from '@lukemorales/query-key-factory'

import { DEFAULT_LIST_QUERY_KEY } from './constants'

import { GetQuizResultsRequest } from 'src/types/quizzes'

const quizzes = createQueryKeys('quizzes', {
  list: (filters?: Partial<GetQuizResultsRequest>) => {
    if (!filters || JSON.stringify(filters) === '{}') return { queryKey: [DEFAULT_LIST_QUERY_KEY] }

    const obj: Record<string, unknown> = {}

    for (const [key, value] of Object.entries(filters)) {
      try {
        obj[key as keyof GetQuizResultsRequest] = JSON.parse(value.toString())
      } catch (_) {
        obj[key as keyof GetQuizResultsRequest] = value
      }
    }

    return { queryKey: [{ filters: obj }] }
  },
  count: (filters?: Partial<GetQuizResultsRequest>) => {
    if (!filters || JSON.stringify(filters) === '{}') return { queryKey: [DEFAULT_LIST_QUERY_KEY] }

    return { queryKey: [{ filters }] }
  },
  allAssesments: {
    queryKey: null
  },
  allAssesmentsCount: {
    queryKey: null
  },
})

export default quizzes
