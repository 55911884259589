import { useEffect, useState, useRef, useCallback } from 'react'

import { Crop, PixelCrop } from 'react-image-crop'

import { canvasPreview } from 'src/utils/canvasPreview'

const DEFAULT_SCALE = 0.9

interface UseImageEditor {
  onClose: () => void
  onSubmit?: (imageUrl: string) => Promise<void>
}

const initialCrop: Crop = {
  unit: '%',
  width: 90,
  height: 75,
  x: 5,
  y: 12.5
}

const useImageEditor = ({ onClose, onSubmit }: UseImageEditor) => {
  const [rotate, setRotate] = useState(0)
  const [scaleX, setScaleX] = useState(DEFAULT_SCALE)
  const [scaleY, setScaleY] = useState(DEFAULT_SCALE)
  const [crop, setCrop] = useState<Crop>(initialCrop)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  const reset = () => {
    setRotate(0)
    setScaleX(DEFAULT_SCALE)
    setScaleY(DEFAULT_SCALE)
    setCrop(initialCrop)
  }

  const handleSubmit = useCallback(async () => {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    )

    const blob = await offscreen.convertToBlob({
      type: 'image/png'
    })

    const reader = new FileReader()
    reader.onloadend = async () => {
      typeof reader.result === 'string' && (await onSubmit?.(reader.result))
      onClose()
      reset()
    }

    reader.readAsDataURL(blob)
  }, [onClose, onSubmit, completedCrop])

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scaleX, scaleY, rotate)
    }
  }, [completedCrop, scaleX, scaleY, rotate])

  return {
    crop,
    rotate,
    scaleX,
    scaleY,
    completedCrop,
    setRotate,
    setScaleX,
    setScaleY,
    setCrop,
    setCompletedCrop,
    handleSubmit,
    saveDisabled: completedCrop === undefined,
    imgRef,
    previewCanvasRef
  }
}

export default useImageEditor
