import { differenceInCalendarDays, format } from 'date-fns'
import { TFunction } from 'i18next'

export function getFormattedDate(date: Date, t: TFunction): string {
  const now = new Date()
  const diffDays = differenceInCalendarDays(now, date)

  if (diffDays === 0) return t('today')
  if (diffDays === 1) return t('yesterday')
  if (diffDays === 2) return t('2d')

  return format(date, 'MMM d')
}
