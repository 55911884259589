import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const marketing: NavGroup[] = [
  {
    title: 'NAVIGATION.tools.tools',
    subject: subjects.topMenu,
    icon: 'tabler:speakerphone',
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    children: [
      {
        title: 'NAVIGATION.tools.landing_pages',
        path: routePaths.landingPage,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.tools.optin',
        path: routePaths.optin,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.tools.calendar',
        path: routePaths.calendar,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.tools.leaderboard',
        path: routePaths.leaderboard,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.tools.shoutout_box',
        path: routePaths.shoutoutBox,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },

      {
        title: 'NAVIGATION.tools.ai_video_creation',
        path: routePaths.aiVideoCreation,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage,
        badgeContent: 'NEW'
      }
    ]
  }
]

export default marketing
