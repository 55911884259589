import { z } from 'zod'
import * as zod from 'zod'

import { notificationSettingsType } from '../../../types/settings'

export const communityNotificationsSchema = z.object({
  notification_settings_type: z.enum(notificationSettingsType, {
    errorMap: () => ({ message: 'settings_type' })
  }),
  is_notify_about_new_order: z.boolean(),
  is_notify_about_new_portal_member: z.boolean(),
  receive_email_notifications: z.boolean(),
  receive_in_app_notifications: z.boolean()
})

export type CommunityNotificationsFormData = zod.infer<typeof communityNotificationsSchema>
