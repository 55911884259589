import { ValueOf } from 'src/types/common/utils'

export const ProviderNamesType = {
  COPECART: 'copecart',
  FAPI: 'fapi',
  ZAPIER: 'zapier'
} as const

export type ProviderNamesTypeId = ValueOf<typeof ProviderNamesType>

export const MarketplaceType = {
  DIGISTORE: 1,
  ELOPAGE: 2,
  COPECART: 3,
  FAPI: 4,
  UPGATES: 5,
  STRIPE: 6,
  THRIVECART: 7,
  AFFILICON: 8,
  APP_STORE: 9,
  GOOGLE_PLAY_MARKET: 10
} as const

export type MarketplaceTypeId = ValueOf<typeof MarketplaceType>
export type PartialMarketplaceTypeId = Exclude<
  MarketplaceTypeId,
  typeof MarketplaceType.APP_STORE | typeof MarketplaceType.GOOGLE_PLAY_MARKET
>

export type Marketplace = {
  id: MarketplaceTypeId
  name: string
  created_at_epoch: number
  description: string
}

export type DigistoreCredentials = {
  api_key?: string
  merchant_name?: string
  salt?: string
}

export type ElopageCredentials = {
  api_key: string
  api_secret: string
  wait_for_payment: boolean
}

export type CopecartCredentials = {
  secret_key: string
  ipn_url: string
  wait_for_payment: boolean
}

export type FapiCredentials = {
  login: string
  api_token: string
  accept_url: string
  wait_for_payment: boolean
}

export type UpgatesCredentials = {
  api_key: string
  ipn_url: string
  login: string
  wait_for_payment: boolean
}

export type StripeCredentials = {
  api_key: string
  ipn_url: string
  wh_secret?: string
  wait_for_payment: boolean
}

export type ThrivecartCredentials = {
  api_key: string
  ipn_url: string
}

export type AffiliconCredentials = {
  api_key: string
}

export type AppStoreCredentials = {
  app_id: string
  bundle_id: string
  key_id: string
  issuer_id: string
  private_key: string
  shared_secret: string
}

export type GooglePlayCredentials = {
  app_id: string
  client_email: string
  private_key: string
}

export type MarketplaceCredentials<MarketplaceId, Credentials> = {
  marketplace_id: MarketplaceId
  credentials: Credentials
}

export type CommonMarketplaceValues = {
  name: string
  is_active: boolean
}

export type DefaultMarketplaceValues<MarketplaceId, Credentials> = MarketplaceCredentials<MarketplaceId, Credentials> &
  CommonMarketplaceValues

export type MarketplaceBindings = {
  id?: number
  name: string
  is_active: boolean
  marketplace_id: MarketplaceTypeId
  portal_id?: number
  connection_id?: number
} & (
  | MarketplaceCredentials<typeof MarketplaceType.DIGISTORE, DigistoreCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.ELOPAGE, ElopageCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.COPECART, CopecartCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.FAPI, FapiCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.UPGATES, UpgatesCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.STRIPE, StripeCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.THRIVECART, ThrivecartCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.AFFILICON, AffiliconCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.APP_STORE, AppStoreCredentials>
  | MarketplaceCredentials<typeof MarketplaceType.GOOGLE_PLAY_MARKET, GooglePlayCredentials>
)

export type PortalApiKey = {
  api_key: string
  created_at: number
  id: number
  portal_id: number
}

export type CopecartCredentialsType = {
  ipn_url: string
  secret_key: string
}

export type FapiAcceptUrl = {
  accept_url: string
}
