const getFileFromDataUrl = async (base64Data: string, erroCallback: (e: string) => void): Promise<Blob | undefined> => {
  try {
    const base64 = await fetch(base64Data)
    const blob = await base64.blob()

    return blob
  } catch (e) {
    erroCallback(e as string)

    return
  }
}

export default getFileFromDataUrl
