import { ValueOf } from './utils'

export const FilterOperator = {
  EQUAL: '=',
  STRICT_EQUAL: '==',
  NOT_EQUAL: '!=',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  LESS_THAN_OR_EQUAL: '<=',
  GREATER_THAN_OR_EQUAL: '>=',
  IN: 'in',
  LIKE: 'like',
  ILIKE: 'ilike',
  IS_NULL: 'is_null'
} as const

export type FilterOperator = ValueOf<typeof FilterOperator>

export type OrderConfig<Column = string> = {
  column?: Column
  ascending?: boolean
}

export type AdditionalFilterConfig = {
  attribute?: string | AdditionalFilterConfig
  operator?: '==' | 'in' | 'concat'
  key?: string
  keys?: string
  args?: string[]
  value?: any
}

export type FilterConfig<Column = string, Value = any> = {
  attribute?: Column | AdditionalFilterConfig
  operator?: FilterOperator
  value?: Value
}

export interface SubQuery<EntityKey = string> {
  over: EntityKey
  attrs: EntityKey[]
}

export type FilterParams<Columns = string, FilterValue = any, Values = undefined> = {
  columns?: (Columns | SubQuery<Columns>)[]
  filter_by?: FilterConfig<Columns extends string[] ? Columns[number] : string, FilterValue>[]
  search_by?: FilterConfig<Columns extends string[] ? Columns[number] : string, FilterValue>[]
  group_by?: string[]
  order_by?: OrderConfig<Columns extends string[] ? Columns[number] : string>[] | string
  offset?: number
  limit?: number
  values?: Values
  data?: any[]
  format?: 'squashed' | 'grouped' | 'pretty'
  token?: string
  from?: string
  api_key?: string
  distinct_by?: FilterConfig<FilterValue>[]
  distinct?: string[]
  file_types?: Array<string>
  free_access_hash?: string
  entity?: string
  timestamp?: number
  public_key?: string
  customer_id?: number
  method?: 'get' | 'count'
}

export type FilterParamsArray = {
  users: FilterParams
  orders?: FilterParams
  courses?: FilterParams
  count?: FilterParams
}

export type OrderFilterGatherParams = FilterParamsArray

export type WithColumnsParam = {
  columns: string[]
}

export type ResponseResult<Result> = {
  done: boolean
  result: Result
}

export type MakeFieldsRequiredAndOthersPartial<T, K extends keyof T> = {
  [P in K]-?: T[P]
} & {
  [P in Exclude<keyof T, K>]?: T[P]
}
