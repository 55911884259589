import { createQueryKeys } from '@lukemorales/query-key-factory'

const images = createQueryKeys('images', {
  all: {
    queryKey: null
  },
  detail: (imageId: string) => {
    return { queryKey: [imageId] }
  },
  infinite: {
    queryKey: null
  }
})

export default images
