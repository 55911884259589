import { useMutation, useQueryClient } from '@tanstack/react-query'

import fileService from 'src/service/file-service'
import queries from 'src/queries'

import useToast from '../useToast'

import { FileType } from 'src/types/files/fileData'
import { MediaVariant } from 'src/types/files/media'

type useMediaStorageMutationProps = {
  folderId: number
  mediaType?: MediaVariant
}

export const useMediaStorageMutation = ({ folderId, mediaType }: useMediaStorageMutationProps) => {
  const { notify } = useToast()
  const queryClient = useQueryClient()

  const { mutateAsync: readMediaFile } = useMutation({
    mutationFn: fileService.getFile,
    onError: err => notify(err.message || 'unexpected', { type: 'error' })
  })

  const { mutate: uploadFileMutation } = useMutation({
    mutationFn: (file: File) => {
      const formData = new FormData()
      formData.append('payload', file)
      if (folderId > -1) {
        formData.append('folder_id', folderId.toString())
      }

      return fileService.uploadFile(formData)
    },
    onSuccess: () => {
      notify('Successful upload', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: deleteFileMutation } = useMutation({
    mutationFn: (media_file_id: string) => {
      return fileService.deleteFile({ media_file_id: media_file_id })
    },
    onSuccess: () => {
      notify('delete', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: addFolderMutation } = useMutation({
    mutationFn: fileService.addFolder,
    onSuccess: () => {
      notify('add', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: deleteFolderMutation } = useMutation({
    mutationFn: (id: number) => {
      return fileService.deleteFolder(id)
    },
    onSuccess: () => {
      notify('delete', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.folders.all })
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: renameFolderMutation } = useMutation({
    mutationFn: (values: { id: number; name: string }) => {
      return fileService.renameFolder({ values })
    },
    onSuccess: () => {
      notify('update', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: updateFileMutation } = useMutation({
    mutationFn: (values: {
      id: number
      name: string
      created_at_epoch: number
      parent_folder_id: number | null
      portal_id: number
      access_type_id: number
      tags?: string | null
    }) => {
      return fileService.updateFile(values)
    },
    onSuccess: () => {
      notify('update', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: duplicateFileMutation } = useMutation({
    mutationFn: (values: FileType & { portal_id: number; access_type_id: number }) => {
      return fileService.duplicateFile(values)
    },
    onSuccess: () => {
      notify('add', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  const { mutate: addThumbnailMutation } = useMutation({
    mutationFn: (value: { video_id: string; preview_file_id: string }) => {
      return fileService.addThumbnail(value)
    },
    onSuccess: () => {
      notify('add', { type: 'success' })
    },
    onError: err => notify(err.message || 'unexpected', { type: 'error' }),
    onSettled: () => {
      queryClient.invalidateQueries({ ...queries.files.media(folderId, mediaType) })
    }
  })

  return {
    readMediaFile,
    handleMediaUpload: uploadFileMutation,
    handleMediaDelete: deleteFileMutation,
    handleAddFolder: addFolderMutation,
    handleDeleteFolder: deleteFolderMutation,
    handleRenameFolder: renameFolderMutation,
    handleFileEdit: updateFileMutation,
    handleDuplicateFile: duplicateFileMutation,
    addThumbnail: addThumbnailMutation
  }
}
