export type Configuration = {
  id: number
  portal_id: number
  allowed_register: boolean
  hide_unavailable: boolean
  registration_type: number
  require_tos_approval: boolean
  show_expired_date: boolean
  is_new_design?: boolean
}

export type ConfigurationKeys = keyof Configuration

export enum LanguageIDs {
  EN = 1,
  DE = 2,
  SK = 3,
  HU = 4,
  MK = 5,
  CZ = 6
}
