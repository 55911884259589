import { FilterParams } from '../common/types'

import { FileType } from './fileData'
import { FolderType } from './folder'

export type MediaVariant = 'folder' | keyof typeof extensions

export type MediaType = {
  id: number
  name: string
  created_at_epoch: number
  parent_folder_id: number | null
  size: number
  type: MediaVariant
  tags?: string
}

export type MediaGridProps = {
  uploadProgress?: number
  data: FileType | FolderType
  isSelected?: boolean
  mediaType?: MediaVariant
  setSelected: (isSelected?: FileType & FolderType) => void
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDoubleClick: () => void
  handleEdit: () => void
}

export const extensions = {
  image: ['.png', '.jpg', '.jpeg', '.webp', '.svg', 'png', 'jpg', 'jpeg', 'webp', 'svg'],
  video: [
    '.mp4',
    '.ogg',
    '.webm',
    '.mov',
    '.mkv',
    '.flv',
    '.vob',
    '.ogv',
    '.avi',
    '.m4v',
    '.mpg',
    '.mp2',
    '.mpeg',
    '.mpe',
    '.mpv',
    '.flv',
    '.f4v',
    'mp4',
    'ogg',
    'webm',
    'mov',
    'mkv',
    'flv',
    'vob',
    'ogv',
    'avi',
    'm4v',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'flv',
    'f4v'
  ],
  file: ['.txt', 'txt'],
  pdf: ['.pdf', 'pdf'],
  audio: ['.mp3', 'mp3', 'm4a', '.m4a']
}

export type MediaResponseType = { data: { result: FolderType[] | FileType[]; done: boolean } }

export type GetMediaRequest = {
  entityType: 'file' | 'folder'
  queryFilters?: FilterParams<string, boolean | number | string[] | string>
}

export type GetMediaResponse = { result: FolderType[] | FileType[]; done: boolean }
