import { SyntheticEvent, useState, ReactElement, useMemo } from 'react'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'

import Icon from '../../../@core/components/icon'

import { FormTabHorizontalProps } from './FormTabHorizontal'

const StyledTabList = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '&:not(.MuiTabs-vertical)': {
    borderBottom: 0
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  }
}))

const StyledTab = styled(Tab)({
  padding: 0
}) as typeof Tab

const StyledInactiveTab = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2.5, 5)
}))

const StyledTabPanel = styled(TabPanel)({
  padding: 0
})

type FormLayoutHorizontalProps = {
  children: Array<ReactElement<FormTabHorizontalProps> | null>
}

export const FormLayoutHorizontal = ({ children }: FormLayoutHorizontalProps) => {
  const [activeTab, setActiveTab] = useState(0)

  const filteredChildren = useMemo(
    () => children.filter((child): child is ReactElement<FormTabHorizontalProps> => child !== null),
    [children]
  )

  const handleTabChange = (e: SyntheticEvent<Element, Event>, newValue: string) => {
    setActiveTab(Number(newValue))
  }

  return (
    <TabContext value={String(activeTab)}>
      <StyledTabList onChange={handleTabChange}>
        {filteredChildren.map((child, index) => (
          <StyledTab
            key={index}
            value={String(index)}
            disableRipple
            label={
              index === activeTab ? (
                <Button
                  variant='contained'
                  startIcon={child.props?.menuIcon ? <Icon icon={child.props.menuIcon} /> : null}
                >
                  {child.props.title}
                </Button>
              ) : (
                <StyledInactiveTab
                  startIcon={child.props?.menuIcon ? <Icon icon={child.props.menuIcon} /> : null}
                  variant='text'
                >
                  {child.props.title}
                </StyledInactiveTab>
              )
            }
          />
        ))}
      </StyledTabList>
      <Box>
        {filteredChildren.map((child, index) => (
          <StyledTabPanel key={index} value={String(index)}>
            {child}
          </StyledTabPanel>
        ))}
      </Box>
    </TabContext>
  )
}
