import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

import { Community } from 'src/types/community'

interface CommunityMenuProps {
  communities?: Community[]
  isLoadingCommunities: boolean
  showCommunities: boolean
  communityCustomName?: string
  isGuest: boolean
  isCustomer: boolean
}

const getDefaultChildItems = () => [
  {
    title: 'NAVIGATION.community.addCommunity',
    aclSubject: subjects.community,
    subject: subjects.topMenu,
    action: permissionActions.manage,
    textColor: 'primary',
    fontWeight: 600,
    path: routePaths.addNewCommunity
  }
]

const createDefaultMenu = (): NavGroup[] => [
  {
    title: 'NAVIGATION.community.community',
    subject: subjects.topMenu,
    icon: 'tabler:users',
    children: getDefaultChildItems(),
    action: permissionActions.read,
    aclSubject: subjects.community
  }
]

const createCommunityMenu = (communities: Community[], customName?: string): NavGroup[] => [
  {
    title: customName || 'NAVIGATION.community.community',
    subject: subjects.topMenu,
    icon: 'tabler:users',
    children: [
      ...communities.map(community => ({
        title: community.title,
        path: `${routePaths.community}/${community.id}`,
        aclSubject: subjects.community,
        subject: subjects.topMenu,
        action: permissionActions.read
      })),
      ...getDefaultChildItems()
    ],
    action: permissionActions.read,
    aclSubject: subjects.community
  }
]

const CommunityMenu = ({
  communities,
  isLoadingCommunities,
  showCommunities,
  communityCustomName,
  isGuest,
  isCustomer
}: CommunityMenuProps): NavGroup[] => {
  if (isLoadingCommunities || showCommunities || !communities) return []

  if (communities.length === 0) {
    return !isGuest && !isCustomer ? createDefaultMenu() : []
  }

  return createCommunityMenu(communities, communityCustomName)
}

export default CommunityMenu
