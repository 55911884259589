export const GTMEvents = { profileData: 'complete_signup', email: 'initiate_signup', final: 'start_test' }
export const GTMID = 'GTM-PZ2DRTD'

export const ONBOARDING_DONE_STEP = 'finish'

const OnboardingStepPrefixes = ['/1/1', '/1/2', '/1/3']

export const getOnboardingStep = (onboardingStep?: string | null): number => {
  if (!onboardingStep) return 0

  const matckingIndex = OnboardingStepPrefixes.findIndex(key => onboardingStep.includes(key))

  if (matckingIndex !== -1) return matckingIndex

  const onboardinStepNumerical = Number(onboardingStep)
  if (onboardinStepNumerical && onboardinStepNumerical < 3) return onboardinStepNumerical

  return 0
}

export const getOnboardingStepPrefix = (step: number) =>
  step >= 3 || step < 0 ? OnboardingStepPrefixes[0] : OnboardingStepPrefixes[step]
