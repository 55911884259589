import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import Icon from '../../../@core/components/icon'
import emailService from '../../../service/email-service'
import { HelpEmailFormData, HelpEmailSchema } from '../../../schemas/settings/emails'
import { CustomTextField } from '../../../@core/components/mui/text-field'
import { DEFAULT_HELP_EMAIL_SUBJECT_KEY } from '../../../utils/settings/emails'
import useToast from '../../../hooks/useToast'

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '100%',
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    width: '93%'
  }
}))

const ModalBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(5, 6)
}))

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

const ModalHeader = styled(Stack)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(5, 6, 1),
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(2)
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const ContactFormModal = ({ isOpen, onClose }: Props) => {
  const { notify } = useToast()
  const { mutate: sendHelpEmail, isPending } = useMutation({
    mutationFn: emailService.sendHelpEmail,
    onSuccess: () => {
      notify('CONTACT_FORM.send', { type: 'success' })
      onClose()
    },
    onError: () => notify('CONTACT_FORM.send', { type: 'error' })
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<HelpEmailFormData>({
    resolver: zodResolver(HelpEmailSchema)
  })

  const onSubmit = handleSubmit((formData: HelpEmailFormData) =>
    sendHelpEmail({ subject: tGlobal(DEFAULT_HELP_EMAIL_SUBJECT_KEY), message: formData.message })
  )

  const { t: tGlobal } = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'FOOTER.help_modal' })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onClose} />
        </IconBox>
        <ModalHeader>
          <Icon icon='f7:paperplane' />
          <Typography variant='h4'>{t('title')}</Typography>
        </ModalHeader>
        <ModalBody>
          <CustomTextField
            label={t('message_label')}
            error={!!errors.message}
            helperText={errors.message && t(errors.message?.message ?? 'required')}
            {...register('message')}
            multiline
            fullWidth
            minRows={8}
          />
        </ModalBody>
        <ModalFooter>
          <LoadingButton variant='contained' color='primary' onClick={onSubmit} loading={isPending}>
            {tGlobal('ACTION.send')}
          </LoadingButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
