import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const design: NavGroup[] = [
  {
    title: 'NAVIGATION.design.design',
    icon: 'tabler:edit',
    subject: subjects.topMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    children: [
      {
        title: 'NAVIGATION.design.look_and_feel',
        path: routePaths.designLookAndFeel,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.design.login_page',
        path: routePaths.loginPageDesign,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.design.academy_customization',
        path: routePaths.academyCustomization,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.design.navigation_items',
        path: routePaths.navigationItems,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      }
    ]
  }
]

export default design
