import { memo, useMemo } from 'react'

import { Stack, styled } from '@mui/material'
import Typography from '@mui/material/Typography'

import { useTranslation } from 'react-i18next'

import { UserRolesType } from 'src/utils/getUserRole'

import { useProfileSettingsForm } from '../../../../hooks/settings/profile'
import { AccountSettingsEditor, PasswordEditor, PersonalSettingsEditor } from '../components/'
import { Member } from '../../../../types/members'
import { FormLayoutHorizontal } from '../../../common/form-layout-horizontal'
import { FormTabHorizontal } from '../../../common/form-layout-horizontal/FormTabHorizontal'
import { useAuth } from '../../../../hooks/useAuth'
import { useUpdatePasswordForm } from '../../../../hooks/settings/profile/useResetPasswordForm'
import { usePortalSettings } from '../../../../hooks/usePortalSettings'

import { NotificationsForm } from './NotificationsForm'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
    padding: theme.spacing(4)
  }
}))

type Props = {
  member: Member
}

export const ProfileSettingsForm = memo(({ member }: Props) => {
  const { portalSettings } = usePortalSettings()
  const communitySettings = useMemo(() => portalSettings?.community_settings?.[0], [portalSettings?.community_settings])

  const {
    onSubmit: onProfileSubmit,
    register: profileRegister,
    errors: profileErrors,
    setValue: setProfileValue,
    watch: watchProfile,
    isSubmitting: isProfileSubmitting,
    isDirty: isProfileDirty
  } = useProfileSettingsForm(member, communitySettings?.display_name_setting_code === 'custom_name')
  const {
    control: passwordControl,
    errors: passwordErrors,
    isSubmitting: isPasswordSubmitting,
    isDirty: isPasswordDirty,
    onSubmit: onPasswordSubmit
  } = useUpdatePasswordForm()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS' })

  const auth = useAuth()

  const avatar_id = watchProfile('avatar_id')

  const avatarId = useMemo(() => avatar_id || member?.avatar_id, [avatar_id, member?.avatar_id])

  const isCustomer = auth?.user?.role === UserRolesType.CUSTOMER || auth?.user?.role === UserRolesType.HIDDEN_CUSTOMER

  return (
    <Wrapper>
      <Typography variant='h2'>{t('FORM.title')}</Typography>
      <FormLayoutHorizontal>
        <FormTabHorizontal title={t('personal_tab_title')} menuIcon='tabler:users'>
          <PersonalSettingsEditor
            register={profileRegister}
            errors={profileErrors}
            avatarId={avatarId}
            setValue={setProfileValue}
            member={member}
            onSubmit={onProfileSubmit}
            isSubmitting={isProfileSubmitting}
            isDirty={isProfileDirty}
          />
        </FormTabHorizontal>

        <FormTabHorizontal menuIcon='tabler:lock' title={t('password_tab_title')}>
          <PasswordEditor
            control={passwordControl}
            errors={passwordErrors}
            isSubmitting={isPasswordSubmitting}
            isDirty={isPasswordDirty}
            onSubmit={onPasswordSubmit}
          />
        </FormTabHorizontal>

        {isCustomer ? (
          <FormTabHorizontal menuIcon='tabler:bell' title={t('notifications_title')}>
            <NotificationsForm />
          </FormTabHorizontal>
        ) : null}

        {isCustomer ? (
          <FormTabHorizontal menuIcon='gg:profile' title={t('account_settings')}>
            <AccountSettingsEditor />
          </FormTabHorizontal>
        ) : null}
      </FormLayoutHorizontal>
    </Wrapper>
  )
})
