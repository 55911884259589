import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { NotificationsFormData, notificationsSchema } from '../../../schemas/settings/notifications'
import { CommunityNotificationSettings, NotificationSettings } from '../../../types/settings'
import communityService from '../../../service/community-service'
import useToast from '../../useToast'
import queries from '../../../queries'
import notificationService from '../../../service/notification-service'

const setupDefaultValues = (
  communityNotificationSettings: CommunityNotificationSettings,
  notificationSettings: NotificationSettings
): NotificationsFormData => ({
  notification_settings_type: communityNotificationSettings.notification_settings_type,
  receive_email_notifications: notificationSettings.receive_email_notifications,
  receive_in_app_notifications: notificationSettings.receive_in_app_notifications
})

export const useNotificationSettingsForm = (
  communityNotificationSettings: CommunityNotificationSettings,
  notificationSettings: NotificationSettings
) => {
  const { notify } = useToast()
  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty }
  } = useForm<NotificationsFormData>({
    defaultValues: setupDefaultValues(communityNotificationSettings, notificationSettings),
    resolver: zodResolver(notificationsSchema)
  })

  const { mutateAsync: updateCommunityNotificationSettings } = useMutation({
    mutationFn: communityService.updateNotificationSettings,
    onSuccess: () => queryClient.invalidateQueries(queries.settings.communityNotifications)
  })

  const { mutateAsync: updateNotificationSettings } = useMutation({
    mutationFn: notificationService.updateNotificationSettings,
    onSuccess: () => queryClient.invalidateQueries(queries.settings.communityNotifications)
  })

  const onSubmit = async (formData: NotificationsFormData) => {
    try {
      await updateCommunityNotificationSettings({ notification_settings_type: formData.notification_settings_type })
      await updateNotificationSettings({
        receive_email_notifications: formData.receive_email_notifications,
        receive_in_app_notifications: formData.receive_in_app_notifications
      })
      reset(formData)
      notify('NOTIFICATION_SETTINGS.notifications_update', { type: 'success' })
    } catch (err) {
      notify('NOTIFICATION_SETTINGS.notifications_update', { type: 'error' })
    }
  }

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors,
    watch,
    isSubmitting,
    isDirty
  }
}
