import Link from 'next/link'

import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import Icon from 'src/@core/components/icon'

import { RenderProps } from '../../../../@core/layouts/types'
import { routePaths } from '../../../../navigation/paths'

const Wrapper = styled(Box)(({ theme }) => ({
  height: theme.spacing(14),
  width: theme.spacing(13.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const SettingsNav = (props: RenderProps) => {
  return (
    <Link href={routePaths.settings}>
      <Wrapper component={Button} {...props.menuItemWrapperBoxProps}>
        <ListItemIcon {...props.listItemIconProps}>
          {props.listItemIconProps?.children || <Icon icon='tabler:settings' />}
        </ListItemIcon>
      </Wrapper>
    </Link>
  )
}

export default SettingsNav
