import { useQuery } from '@tanstack/react-query'

import queries from 'src/queries'
import mediaStorageService from 'src/service/media-storage-service'

import { FilterParams, OrderConfig } from 'src/types/common/types'
import { FolderType, GetFoldersRequest } from 'src/types/files/folder'

const defaultOrderBy: OrderConfig[] = [{ column: 'created_at_epoch', ascending: true }]

const defaultColumns = ['id', 'name', 'created_at_epoch', 'parent_folder_id']
const defaultFilterBy: FilterParams = { columns: defaultColumns, order_by: defaultOrderBy }
const defaultFolderRequest: GetFoldersRequest = { entityType: 'folder', queryFilters: defaultFilterBy }

const useMediaFolders = () => {
  const query = useQuery({
    ...queries.folders.all,
    queryFn: () => mediaStorageService.getMediaList(defaultFolderRequest),
    select: response => [...response.data.result.map(item => ({ ...item, type: 'folder' } as FolderType))]
  })

  return query
}

export default useMediaFolders
