import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'

import { getLocalizedHelpCenterLink } from '../../../../utils/navigation'
import { RenderProps } from '../../../../@core/layouts/types'

const Wrapper = styled(Box)(({ theme }) => ({
  height: theme.spacing(14),
  width: theme.spacing(13.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const HelpCenterLink = (props: RenderProps) => {
  const { i18n } = useTranslation()
  const localizedPath = getLocalizedHelpCenterLink(i18n.language)

  return (
    <a href={localizedPath} target='_blank' rel='noreferrer noopener'>
      <Wrapper component={Button} {...props.menuItemWrapperBoxProps}>
        <ListItemIcon {...props.listItemIconProps}>
          {props.listItemIconProps?.children || <Icon icon='tabler:help' />}
        </ListItemIcon>
      </Wrapper>
    </a>
  )
}

export default HelpCenterLink
