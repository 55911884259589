import { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import { Autocomplete, useTheme } from '@mui/material'

import { useTranslation } from "react-i18next";

import CustomTextField from 'src/@core/components/mui/text-field'

import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    width: '93%'
  }
}))

const ModalHeader = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(5, 6, 1)
}))

const ModalBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(6)
}))

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

type EditFileModal = {
  data?: FileType
  isOpen: boolean
  mediaList: (FileType | FolderType)[] | undefined
  onSubmit: (newData: FileType) => void
  onCancel: () => void
}

const EditFileModal = ({ isOpen, onSubmit, onCancel, data, mediaList }: EditFileModal) => {
  const { t } = useTranslation();
  const theme = useTheme()

  const [name, setName] = useState<string | undefined>(data?.name)
  const [tags, setTags] = useState<string[]>(data?.tags?.split(',') || [])

  useEffect(() => {
    if (data) setName(data.name)
  }, [data])

  useEffect(() => {
    if (data) setTags(data?.tags?.split(',') || [])
  }, [data])

  const handleSave = () => {
    if (!data) return
    if (name === undefined) return

    const index = mediaList?.findIndex(media => media.id === data.id)
    if (!index || index === -1 || !mediaList) return

    const newData = { ...data, tags: tags.toString(), name: name }

    onSubmit(newData)
  }

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <ModalContent>
        <ModalHeader>
          <Typography variant='h5'>{t('ACTION.rename')}{data?.type}</Typography>
        </ModalHeader>
        <ModalBody>
          <CustomTextField
            label={t('extracted.file_name')}
            autoFocus
            type='text'
            placeholder={t('FORM.placeholder')}
            InputProps={{ sx: { width: '100%' } }}
            onChange={e => setName(e.target.value)}
            value={name}
          />
          <Autocomplete
            multiple
            freeSolo
            options={data?.tags?.split(',') || ['']}
            value={tags}
            onChange={(_, tags) => {
              setTags(tags)
            }}
            sx={{
              '& .MuiChip-root': {
                borderRadius: '4px',
                '& .MuiChip-label': { fontSize: theme.typography.body2.fontSize }
              }
            }}
            renderInput={params => <CustomTextField {...params} label={t('extracted.tags')} placeholder={t('extracted.best')} />}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant='tonal' onClick={onCancel}>
            {t('ACTION.cancel')}</Button>
          <Button variant='contained' disabled={!name} onClick={handleSave}>
            {t('ACTION.save')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditFileModal
