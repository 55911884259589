import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const members: NavGroup[] = [
  {
    title: 'NAVIGATION.members',
    icon: 'tabler:user',
    subject: subjects.topMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    children: [
      {
        title: 'NAVIGATION.MEMBERS_CHILDREN.MembersAndLeads',
        path: routePaths.membersAndLeads,
        action: permissionActions.manage,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu
      },
      {
        title: 'NAVIGATION.MEMBERS_CHILDREN.orders',
        path: routePaths.orders,
        action: permissionActions.manage,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu
      }
    ]
  }
]

export default members
