import { ValueOf } from 'src/types/common/utils'

export const ExternalSystemType = {
  ACTIVE_CAMPAIGN: 1,
  QUENTN: 2,
  FOUR_LEADS: 3,
  KLICK_TIPP: 4,
  GET_RESPONSE: 5,
  SENDINBLUE: 6,
  SMART_EMAILING: 7,
  CLEVER_REACH: 8,
  MAILCHIMP: 9,
  KLAVIYO: 10,
  HUBSPOT: 11,
  CONVERT_KIT: 12,
  CHIMPIFY: 13,
  MAILINGBOSS: 14,
  MAILER_LITE: 15,
  ZOHOMAIL: 16,
  RAPIDMAIL: 17,
  SAMDOCK: 18,
  ECOMAIL: 19
}

export type ExternalSystemTypeId = ValueOf<typeof ExternalSystemType>

export type ExternalSystemIntegration = {
  id: ExternalSystemTypeId
  name: string
  created_at: number
}

export type Integration = {
  id?: number
  api_key?: string
  api_url?: string
  created_at?: number
  external_system_id: ExternalSystemTypeId
  login?: string
  name?: string
  password?: string
  portal_id?: number
  refresh_key?: string
}

export type IntegrationWithJwt = Integration & {
  login_jwt?: string
  password_jwt?: string
  integration_name?: string
}

export const RuleEventType = {
  COURSE_PURCHASE: 1,
  COURSE_REFUND: 2,
  COURSE_GIFT: 3,
  COURSE_ACCESS: 4,
  COURSE_ANY: 5
}

export type RuleEventTypeId = ValueOf<typeof RuleEventType>

export type RuleEvent = {
  id: RuleEventTypeId
  name: string
  created_at: number
}

export const RuleActionType = {
  ADD_CONTACT: 1,
  ADD_TAG: 2
}

export type RuleActionTypeId = ValueOf<typeof RuleActionType>

export type RuleAction = {
  id: RuleActionTypeId
  name: string
  created_at: number
}

export type ActionMetadata = {
  mailing_ids?: string[] | number[]
  tag?: string
  campaign?: string
}
export type EventMetadata = {
  course_ids: number[]
  courses: {
    id: number
    title: string
  }[]
}

export type Rule = {
  action_id: RuleActionTypeId
  action_metadata: ActionMetadata
  active_at: number
  created_at?: number
  event_id: RuleEventTypeId
  event_metadata: EventMetadata
  id: number
  integration?: Integration
  integration_id: number
  name: string
}

export const ErrorType = {
  CREDENTIALS_CHECK: 1,
  TAG_CREATE: 2,
  CONTACT_ADD: 3,
  SERVICE_UNAVAILABLE: 4,
  REQUEST_TIMEOUT: 5,
  MAILINGLIST_GET: 6,
  CREDENTIALS_GET: 7,
  CREDENTIALS_REFRESH: 8,
  CONTACT_UPDATE: 9,
  CONTACT_GET: 10,
  TAG_CONTACT: 11,
  TARGET_GET: 12
}

export type ErrorTypeId = ValueOf<typeof ErrorType>

export type IntegrationErrorType = {
  id: ErrorTypeId
  name: string
  created_at: number
}

export type IntegrationError = {
  composed_message: string
  created_at: number
  endpoint: string
  error_code: number
  error_type_id: ErrorTypeId
  full_message?: {
    error: {
      code: number
      message: string
    }
    error_details?: {
      message?: string
      raw_response?: string
    }
    raw_response?: string
  }
  headers: any
  id: number
  integration_id: number
  payload?: {
    name?: string
    email?: string
    fields?: {
      last_name?: string
    }
  }
  url?: string
}

export type Mailing = {
  id: number
  title: string
}

export type CourseInfo = {
  id: number
  title: string
  is_active: boolean
}
