import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) return

    const intervalId = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(intervalId)
  }, [delay])
}
