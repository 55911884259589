import { useMemo } from 'react'

import { Box, IconButton, Typography, useTheme, alpha } from '@mui/material'

import { useTranslation } from "react-i18next";

import Icon from 'src/@core/components/icon'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import { getTagList } from 'src/utils/mediaStorage/tagActions'
import { getImageUrl } from 'src/utils/getImageUrl'

import { MediaVariant } from 'src/types/files/media'
import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'

type MediaListRowProps = {
  media: FileType | FolderType
  mediaType: MediaVariant
  isSelected?: boolean
  handleEdit: () => void
  setSelected: (isSelected?: FileType | FolderType) => void
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDoubleClick: () => void
}

const MediaListRow = ({
  media,
  mediaType,
  isSelected,
  onClick,
  onDoubleClick,
  setSelected,
  handleEdit
}: MediaListRowProps) => {
  const { t } = useTranslation();
  const theme = useTheme()

  const memoizedSize = useMemo(() => (media.size / Math.pow(1024, 2)).toFixed(2), [media.size])
  const dateStr = useMemo(() => new Date(media.created_at_epoch).toLocaleString(), [media.created_at_epoch])

  const { multiSelect, setMultiSelect } = useSelectedMedia()

  const renderIcon = () => {
    return (
      <>
        {media.type === 'folder' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: alpha(theme.palette.primary.main, 0.6),
              path: {
                fill: alpha(theme.palette.primary.main, 0.2)
              },
              width: '56px',
              minWidth: '56px',
              height: '42px'
            }}
          >
            <Icon icon='tabler:folder' fontSize='42px' />
          </Box>
        )}

        {(['file', 'pdf'].includes(media.type) || !media.type) && (
          <Box
            sx={{
              bgcolor: alpha(theme.palette.primary.main, 0.2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              color: alpha(theme.palette.primary.main, 0.6),
              width: '56px',
              minWidth: '56px',
              height: '42px'
            }}
          >
            <Icon icon='tabler:file' fontSize='42px' />
          </Box>
        )}

        {media.type === 'image' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: `url(${getImageUrl((media as FileType).file_id || '')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '4px',
              width: '56px',
              minWidth: '56px',
              height: '42px'
            }}
          ></Box>
        )}

        {media.type === 'video' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '4px',
              justifyContent: 'center',
              background: (media as FileType).preview_link
                ? `url(${(media as FileType).preview_link})`
                : alpha(theme.palette.primary.main, 0.2),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '56px',
              minWidth: '56px',
              height: '42px'
            }}
          >
            <Icon icon='tabler:player-play' fontSize='24px' color={theme.palette.common.white} opacity={0.8} />
          </Box>
        )}

        {media.type === 'audio' && (
          <Box
            sx={{
              bgcolor: alpha(theme.palette.primary.main, 0.2),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              color: alpha(theme.palette.primary.main, 0.6),
              width: '56px',
              minWidth: '56px',
              height: '42px'
            }}
          >
            <Icon icon='tabler:music' fontSize='42px' />
          </Box>
        )}
      </>
    )
  }

  return (
    <Box
      sx={{
        width: {
          xs: 'max-content',
          md: '100%'
        },
        display: 'grid',
        gridTemplateColumns: '1fr 150px 110px 110px 30px',
        gap: '20px',
        padding: '10px 15px',
        alignItems: 'center',
        bgcolor:
          multiSelect?.map(selectedMedia => selectedMedia.id).includes(media.id) || (mediaType !== 'file' && isSelected)
            ? alpha(theme.palette.primary.main, 0.08)
            : theme.palette.background.paper,
        border: `1px solid ${multiSelect?.map(selectedMedia => selectedMedia.id).includes(media.id) || (mediaType !== 'file' && isSelected)
            ? theme.palette.primary.main
            : theme.palette.background.paper
          }`,
        cursor: 'pointer'
      }}
      onClick={() => {
        setSelected(media)

        if (mediaType !== 'file') {
          return
        }
        if (multiSelect?.map(selectedMedia => selectedMedia.id).includes(media.id)) {
          setMultiSelect(multiSelect.filter(selectedMedia => selectedMedia.id !== media.id))
        } else {
          setMultiSelect([...(multiSelect || []), media])
        }
      }}
      onDoubleClick={onDoubleClick}
    >
      <Box sx={{ maxWidth: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center', gap: '16px' }}>
        {renderIcon()}
        <Typography
          variant='body1'
          sx={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {media.name}
          <Box sx={{ display: 'flex', gap: theme.spacing(1.5) }}>
            {media.tags && getTagList(media.tags).length > 0 ? (
              <>
                {getTagList(media.tags)
                  .slice(0, 2)
                  .map((tag, tagIndex) => (
                    <Box
                      key={tagIndex}
                      sx={{
                        bgcolor: alpha(theme.palette.secondary.main, 0.2),
                        borderRadius: '4px',
                        padding: theme.spacing(0.5, 1),
                        fontSize: theme.typography.caption.fontSize
                      }}
                    >
                      {tag}
                    </Box>
                  ))}

                {getTagList(media.tags).length > 2 && (
                  <Typography variant='caption'>+ {getTagList(media.tags).length - 2}</Typography>
                )}
              </>
            ) : (
              <Box
                sx={{
                  bgcolor: alpha(theme.palette.secondary.main, 0.2),
                  borderRadius: '4px',
                  cursor: 'pointer',
                  padding: theme.spacing(0.5, 1),
                  fontSize: theme.typography.caption.fontSize,
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.common.white
                  }
                }}
                onClick={() => {
                  setSelected(media)
                  handleEdit()
                }}
              >
                {t('extracted.add_tags')}</Box>
            )}
          </Box>
        </Typography>
      </Box>
      <Box>
        <Typography variant='body2'>{dateStr}</Typography>
      </Box>
      <Box>{media.type}</Box>
      <Box>
        {media.type !== 'folder' && (
          <Typography variant='body2' color='muted' fontWeight={400}>
            {memoizedSize} {t('extracted.mb')}</Typography>
        )}
      </Box>
      <Box>
        <IconButton onClick={onClick}>
          <Icon fontSize='22px' color={theme.palette.primary.main} icon='tabler:dots-vertical' />
        </IconButton>
      </Box>
    </Box>
  )
}

export default MediaListRow
