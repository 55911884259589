import { useState, DragEvent, ChangeEvent, DragEventHandler, useCallback } from 'react'

import { Button, Stack, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'
import FallbackSpinner from 'src/@core/components/spinner'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import { useMediaStorage } from 'src/hooks/useMediaStorage'

import { isFileType } from 'src/utils/files/isFileType'

import MentorcloudModal from '../../modals/MentorcloudModal'

import {
  FileUploaderButtonsContainerBox,
  FileUploaderColoredInnerBox,
  FileUploaderIconWrapperBox,
  FileUploaderSectionBox
} from './styled'

import { FileType } from 'src/types/files/fileData'

type FileUploaderProps = {
  fileInputRef: React.RefObject<HTMLInputElement>
  onUpload: (files: File[]) => void
  onMentorCloudFilesPick?: (value: FileType[]) => void
  accept?: string
  requirements?: string
  mentorCloudEnabled?: boolean
  isLoading?: boolean
}

const FileUploader = ({
  accept = '',
  requirements,
  onUpload,
  fileInputRef,
  mentorCloudEnabled = true,
  onMentorCloudFilesPick,
  isLoading
}: FileUploaderProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [isDragOver, setIsDragOver] = useState(false)

  const { isMentorCloudOpen, openMentorCloud, closeMentorCloud } = useMediaStorage({ mediaType: 'file' })
  const { multiSelect, setMultiSelect, setSelected } = useSelectedMedia()

  const onDrag = useCallback(
    (isDragOver: boolean): DragEventHandler<HTMLDivElement> =>
      e => {
        e.preventDefault()
        setIsDragOver(isDragOver)
      },
    [setIsDragOver]
  )

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    onDrag(false)(e)

    onUpload(Array.from(e.dataTransfer.files))
  }

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.files && onUpload(Array.from(e.target.files))
  }

  const closeCloud = () => {
    closeMentorCloud()
    setSelected()
    setMultiSelect()
  }

  return (
    <FileUploaderSectionBox
      borderColor={`${isDragOver ? theme.palette.primary.main : theme.palette.divider}`}
      onDrop={onDrop}
      onDragLeave={onDrag(false)}
      onDragOver={onDrag(true)}
    >
      <input
        accept={accept}
        multiple
        type='file'
        id='file'
        ref={fileInputRef}
        onChange={handleUpload}
        style={{ display: 'none' }}
      />
      <MentorcloudModal
        mediaType='file'
        key={5}
        isOpen={!!isMentorCloudOpen}
        onClose={closeCloud}
        onCancel={closeCloud}
        onChoose={() => {
          const files = multiSelect
            ?.map(media => {
              if (isFileType(media)) {
                return media
              }
            })
            .filter(Boolean) as FileType[]

          onMentorCloudFilesPick?.(files)
          closeCloud()
        }}
      />
      {isLoading ? (
        <Stack alignItems={'center'} justifyContent={'center'}>
          <FallbackSpinner fullHeight={false} />
        </Stack>
      ) : (
        <FileUploaderColoredInnerBox>
          <FileUploaderIconWrapperBox>
            <Icon strokeWidth={0.5} icon='tabler:file' fontSize={28}></Icon>
          </FileUploaderIconWrapperBox>
          <Typography fontWeight={400} variant='h5' align='center'>
            {t('ACTION.ENTITY.drag_drop_file')}
          </Typography>
          <Typography color={theme.palette.text.disabled} variant='body1'>
            {t('MISC.or')}
          </Typography>
          <FileUploaderButtonsContainerBox>
            <Button onClick={() => fileInputRef.current?.click()} variant='contained'>
              {t('ACTION.ENTITY.browse_entities', { entities: t('FILE_TYPE.files') })}
            </Button>
            {mentorCloudEnabled && (
              <Button variant='outlined' startIcon={<Icon icon='tabler:cloud'></Icon>} onClick={openMentorCloud}>
                {t('ENTITY.mentor_cloud')}
              </Button>
            )}
          </FileUploaderButtonsContainerBox>
          <Typography color={theme.palette.text.disabled} variant='caption'>
            {requirements ? requirements : t('PAGE.UPLOADER.file_req')}
          </Typography>
        </FileUploaderColoredInnerBox>
      )}
    </FileUploaderSectionBox>
  )
}

export default FileUploader
