import { useState, MouseEvent, useCallback } from 'react'

import { Popover, Stack, styled, Tooltip, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import FormHelperText from '@mui/material/FormHelperText'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { UserRolesType } from 'src/utils/getUserRole'

import { PasswordInput } from '../../../common/inputs/PasswordInput'
import { PasswordUpdateFormData } from '../../../../schemas/settings/profile'
import Icon from '../../../../@core/components/icon'
import { useAuth } from '../../../../hooks/useAuth'

const PopoverTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 4.5, 0, 4.4)
}))

const ListStyled = styled(List)(({ theme }) => ({
  padding: theme.spacing(4, 4.5),
  listStyleType: 'disc',
  listStylePosition: 'inside'
}))

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  color: theme.palette.grey[800],
  display: 'list-item',
  padding: 0
}))

type Props = {
  control: Control<PasswordUpdateFormData>
  errors: FieldErrors<PasswordUpdateFormData>
  isSubmitting: boolean
  isDirty: boolean
  onSubmit: () => Promise<void>
}

export const PasswordEditor = ({ control, errors, isSubmitting, onSubmit, isDirty }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS.FORM' })
  const { t: tGlobal } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)
  const { user } = useAuth()

  const isPreviewMode = user?.role === UserRolesType.HIDDEN_CUSTOMER

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <Stack spacing={theme.spacing(6)}>
      <Stack spacing={theme.spacing(1)} marginTop={theme.spacing(6)}>
        <Typography variant='h4'>{tGlobal('PAGE.PROFILE_SETTINGS.password_tab_title')}</Typography>
        <Typography variant='body1'>{t('password_subtitle')}</Typography>
      </Stack>
      <Stack width={{ xs: '100%', md: '50%' }} paddingRight={{ xs: theme.spacing(0), md: theme.spacing(3) }}>
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <PasswordInput
                {...field}
                fullWidth
                label={t('current_password_label')}
                autoComplete='current-password'
                error={Boolean(errors?.password)}
                helperText={errors?.password?.message && t(`validation.${errors?.password?.message}`)}
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>
          )}
        />
      </Stack>
      <Stack>
        <Stack spacing={theme.spacing(6)} direction={{ xs: 'column', md: 'row' }}>
          <Controller
            name='new_password'
            control={control}
            render={({ field }) => (
              <Tooltip
                title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
                placement='top'
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                disableInteractive={false}
              >
                <PasswordInput
                  {...field}
                  fullWidth
                  label={t('new_password_label')}
                  autoComplete='new-password'
                  error={Boolean(errors?.new_password)}
                  helperText={
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={theme.spacing(1)}>
                      {errors?.new_password?.message && (
                        <FormHelperText color={theme.palette.error.main}>
                          {t(`validation.${errors?.new_password?.message}`)}
                        </FormHelperText>
                      )}
                      <Icon
                        icon='tabler:help'
                        fontSize={16}
                        color={theme.palette.grey[500]}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      />
                    </Stack>
                  }
                  inputProps={{ readOnly: isPreviewMode }}
                />
              </Tooltip>
            )}
          />
          <Controller
            name='confirm_password'
            control={control}
            render={({ field }) => (
              <Tooltip
                title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
                placement='top'
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                disableInteractive={false}
              >
                <PasswordInput
                  {...field}
                  fullWidth
                  label={t('confirm_password_label')}
                  autoComplete='new-password'
                  error={Boolean(errors?.confirm_password)}
                  helperText={errors?.confirm_password?.message && t(`validation.${errors?.confirm_password?.message}`)}
                  inputProps={{ readOnly: isPreviewMode }}
                />
              </Tooltip>
            )}
          />
        </Stack>
      </Stack>
      <Stack direction='row' spacing={theme.spacing(4)}>
        <Tooltip
          title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
          placement='top'
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          disableInteractive={false}
        >
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            disabled={!isDirty}
            onClick={isPreviewMode ? undefined : onSubmit}
            color='primary'
          >
            {tGlobal('ACTION.save_changes')}
          </LoadingButton>
        </Tooltip>
      </Stack>
      <Popover
        sx={{ pointerEvents: 'none' }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
      >
        <PopoverTitle variant='h5'>{t('validation.password_req_title')}</PopoverTitle>

        <ListStyled>
          <ListItemStyled>{t('validation.req1')}</ListItemStyled>
          <ListItemStyled>{t('validation.req2')}</ListItemStyled>
          <ListItemStyled>{t('validation.req3')}</ListItemStyled>
          <ListItemStyled>{t('validation.req4')}</ListItemStyled>
        </ListStyled>
      </Popover>
    </Stack>
  )
}
