import { memo } from 'react'

import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { ButtonGroup, InputLabel, Typography } from '@mui/material'
import { useTheme } from '@mui/system'

import CustomTextField from 'src/@core/components/mui/text-field'

import { BaseInputButton, InputButton } from './InputButton'

const StyledCustomTextField = styled(CustomTextField, {
  shouldForwardProp: prop => prop !== 'startButton' && prop !== 'endButton' // Prevent forwarding to DOM
})<{ startButtonProps?: boolean; buttonProps?: boolean }>(({ theme, startButtonProps, buttonProps }) => ({
  '& .MuiInputBase-root.MuiInputBase-sizeSmall': {
    borderRadius: `
      ${startButtonProps ? 0 : theme.shape.borderRadius + 'px'}
      ${buttonProps ? 0 : theme.shape.borderRadius + 'px'}
      ${buttonProps ? 0 : theme.shape.borderRadius + 'px'}
      ${startButtonProps ? 0 : theme.shape.borderRadius + 'px'}
    `,
    ...(buttonProps && {
      borderRight: 0,
      '&:hover, &:focus, &:active, &.Mui-disabled': { borderRight: 0 }
    }),
    ...(startButtonProps && {
      borderLeft: 0,
      '&:hover, &:focus, &:active, &.Mui-disabled': { borderLeft: 0 }
    })
  },
  paddingRight: buttonProps ? 0 : undefined,
  paddingLeft: startButtonProps ? 0 : undefined
}))

type InputGroupProps = {
  label: string
  buttonProps?: BaseInputButton
  secondButtonProps?: BaseInputButton
  startButtonProps?: BaseInputButton
  value?: string | number
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
  required?: boolean
}

const InputGroup = ({
  label,
  buttonProps,
  secondButtonProps,
  startButtonProps,
  onChange,
  value,
  placeholder,
  error,
  disabled,
  required,
  helperText,
  readOnly = false
}: InputGroupProps) => {
  const theme = useTheme()

  return (
    <Stack>
      <Typography
        marginBottom={1}
        lineHeight={1.1}
        color={theme.palette.grey[900]}
        component={InputLabel}
        variant='body2'
      >
        {label}
      </Typography>
      <ButtonGroup>
        {startButtonProps && (
          <Stack flexDirection='row' alignSelf={'end'}>
            {startButtonProps && (
              <InputButton place='start' {...startButtonProps}>
                {startButtonProps.children}
              </InputButton>
            )}
          </Stack>
        )}
        <StyledCustomTextField
          startButtonProps={Boolean(startButtonProps)}
          buttonProps={Boolean(buttonProps)}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          type='text'
          value={value || ''}
          onChange={onChange}
          error={error}
          fullWidth
          InputProps={{ readOnly: readOnly }}
        />
        {(buttonProps || secondButtonProps) && (
          <Stack direction={'row'} alignSelf={'end'}>
            {secondButtonProps && (
              <InputButton place='inner' {...secondButtonProps}>
                {secondButtonProps.children}
              </InputButton>
            )}
            {buttonProps && (
              <InputButton place='end' {...buttonProps}>
                {buttonProps.children}
              </InputButton>
            )}
          </Stack>
        )}
      </ButtonGroup>
      <Typography lineHeight={1.1} error={error} color={theme.palette.grey[900]} component={InputLabel} variant='body2'>
        {helperText}
      </Typography>
    </Stack>
  )
}

export default memo(InputGroup)
