import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { IconButton, IconButtonProps } from '@mui/material'
import { alpha } from '@mui/system'

export const VideoUploaderSectionBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  bgcolor: theme.palette.background.paper,
  border: '1px dashed',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'center'
}))

export const DeleteButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  zIndex: 1
}))

export const UploaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  bgcolor: alpha(theme.palette.primary.main, 0.08),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  justifyContent: 'center',
  padding: theme.spacing(8, 6),
  width: '100%'
}))

export const VideoUploaderDragDropContentTextsBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  borderRadius: '6px',
  color: theme.palette.text.primary,
  height: 38,
  justifyContent: 'center',
  padding: '5px',
  transition: 'all 120ms ease-out',
  width: 38
}))

export const Footer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexWrap: 'wrap',
  alignItems: 'stretch',
  '& > *': {
    flexGrow: 1
  }
}))
