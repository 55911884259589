import { createQueryKeys } from '@lukemorales/query-key-factory'

const design = createQueryKeys('design', {
  generalSettings: {
    queryKey: null
  },
  settings: {
    queryKey: null
  },
  portalToDesign: {
    queryKey: null
  },
  settingCodes: {
    queryKey: null
  },
  templates: {
    queryKey: null
  },
  appTemplates: {
    queryKey: null
  },
  activeTemplate: {
    queryKey: null
  },
  design: {
    queryKey: null
  }
})

export default design
