import { MouseEvent, useState } from 'react'

export const usePopover = (id: string) => {
  const [anchor, setAnchor] = useState<Element | null>(null)

  const open = (event: MouseEvent) => {
    setAnchor(event.currentTarget)
  }

  const close = () => {
    setAnchor(null)
  }

  return {
    open,
    close,
    anchor,
    isOpen: Boolean(anchor),
    id: Boolean(anchor) ? id : undefined
  }
}
