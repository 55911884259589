import { NavGroup } from 'src/@core/layouts/types'

import courses from './courses'
import tools from './tools'
import statistics from './statistics'
import design from './design'
import appRequests from './app-requests'

const mainMenu: NavGroup[] = [...statistics, ...courses, ...tools, appRequests, ...design]

export default mainMenu
