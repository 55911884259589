import { IconButton, useTheme } from '@mui/material'

import Icon from 'src/@core/components/icon'

type ToggleDrawerIconButtonProps = {
  onClick: () => void
  isOpen: boolean
}

const DrawerToggler = ({ onClick, isOpen }: ToggleDrawerIconButtonProps) => {
  const theme = useTheme()

  return (
    <IconButton
      onClick={onClick}
      size='small'
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        position: 'absolute',
        top: 65,
        zIndex: 3,
        left: `calc(100% - 17px)`,
        '&:hover': {
          backgroundColor: theme.palette.primary.hover
        }
      }}
    >
      <Icon icon={`tabler:chevron-${isOpen ? 'left' : 'right'}`} />
    </IconButton>
  )
}

export default DrawerToggler
