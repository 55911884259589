import LoginNav from 'src/components/navigation/vertical/account-menu/LoginNav'

import { NavLink, RenderProps } from '../../../@core/layouts/types'
import { routePaths } from '../../paths'
import { permissionActions } from '../../../configs/permissionActions'
import { subjects } from '../subjects'
import { CustomNavNames } from '../../types'

const customerMenuBase = (name?: string): NavLink[] => [
  {
    title: name || 'NAVIGATION.clientMenu.courses',
    subject: subjects.topMenu,
    aclSubject: subjects.stundentPage,
    action: permissionActions.manage,
    icon: 'tabler:layout-grid',
    path: routePaths.customerDashboard
  },
  {
    title: name || 'NAVIGATION.clientMenu.courses',
    subject: subjects.topMenu,
    aclSubject: subjects.guest,
    action: permissionActions.manage,
    icon: 'tabler:layout-grid',
    render: props => LoginNav({ ...props, iconName: 'tabler:layout-grid' })
  }
]

function customerMenu({
  showCalendar,
  showLeaderboard,
  customNavNames
}: {
  showCalendar: boolean
  showLeaderboard: boolean
  customNavNames?: CustomNavNames
}): NavLink[] {
  const conditionalItems = [
    ...(showLeaderboard
      ? [
          {
            title: customNavNames?.leaderboard || 'NAVIGATION.clientMenu.leaderboard',
            subject: subjects.topMenu,
            aclSubject: subjects.stundentPage,
            action: permissionActions.manage,
            icon: 'tabler:medal-2',
            path: routePaths.customerLeaderboard
          },
          {
            title: customNavNames?.leaderboard || 'NAVIGATION.clientMenu.leaderboard',
            subject: subjects.topMenu,
            aclSubject: subjects.guest,
            action: permissionActions.manage,
            icon: 'tabler:medal-2',
            render: (props: RenderProps) => LoginNav({ ...props, iconName: 'tabler:medal-2' })
          }
        ]
      : []),
    ...(showCalendar
      ? [
          {
            title: customNavNames?.calendar || 'NAVIGATION.clientMenu.calendar',
            subject: subjects.topMenu,
            aclSubject: subjects.stundentPage,
            action: permissionActions.manage,
            icon: 'tabler:calendar',
            path: routePaths.customerCalendar
          },
          {
            title: customNavNames?.calendar || 'NAVIGATION.clientMenu.calendar',
            subject: subjects.topMenu,
            aclSubject: subjects.guest,
            action: permissionActions.manage,
            icon: 'tabler:calendar',
            render: (props: RenderProps) => LoginNav({ ...props, iconName: 'tabler:calendar' })
          }
        ]
      : [])
  ]

  return [...customerMenuBase(customNavNames?.dashboard), ...conditionalItems]
}

export default customerMenu
