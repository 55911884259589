import { Palette } from '@mui/material'

import { Skin } from 'src/@core/layouts/types'

import opacity from '../colorWithOpacity'

const DefaultPalette = (mode: Palette['mode'], skin: Skin): Palette => {
  // ** Vars
  const whiteColor = '#FFF'
  const lightColor = mode === 'light' ? '#DFDFE3' : '#44475B'
  const darkColor = mode === 'light' ? '#4B4B4B' : '#D7D8DE'
  const darkPaperBgColor = '#2F3349'
  const mainColor = mode === 'light' ? darkColor : lightColor
  const bgColor = mode === 'light' ? '#F8F7FA' : '#25293C'
  const borderedBgColor = mode === 'light' ? whiteColor : darkPaperBgColor

  const { colorWithOpacity } = opacity

  const defaultBgColor = () => {
    if (skin === 'bordered') {
      return borderedBgColor
    }

    return bgColor
  }

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      lightPaperBg: whiteColor,
      darkPaperBg: darkPaperBgColor,
      bodyBg: bgColor,
      trackBg: mode === 'light' ? '#F1F0F2' : '#363B54',
      avatarBg: mode === 'light' ? '#DBDADE' : '#4A5072',
      cardBg: borderedBgColor,
      tableHeaderBg: mode === 'light' ? '#5D586C' : '#DBDADE',

      mainBgContrastColor: mode === 'light' ? '#5D586C' : '#DBDADE',
      footerBgColor: defaultBgColor(),
      navbarBg: borderedBgColor,
      navbarText: mode === 'light' ? '#5D586C' : '#DBDADE',
      navbarSecondaryText: mode === 'light' ? '#6F6B7D' : '#C9C7CE'
    },
    mode: mode,
    common: {
      black: '#000',
      white: whiteColor
    },
    primary: {
      100: '#D9DEFD',
      200: '#B3BDFB',
      300: '#8D9DF9',
      400: '#677CF7',
      500: '#415BF5',
      600: '#3B52DD',
      700: '#374DD0',
      800: '#3449C4',
      900: '#3144B8',
      light: '#8D9DF9',
      main: '#415BF5',
      dark: '#374DD0',
      hover: '#3B52DD',
      active: '#374DD0',
      contrastText: whiteColor
    },
    secondary: {
      100: '#EEEEEF',
      200: '#DCDDDF',
      300: '#CBCCCE',
      400: '#B9BBBE',
      500: '#A8AAAE',
      600: '#98999D',
      700: '#8F9194',
      800: '#86888B',
      900: '#7E7F82',
      light: '#CBCCCE',
      main: '#A8AAAE',
      dark: '#8F9194',
      hover: '#98999D',
      active: '#8F9194',
      contrastText: whiteColor
    },
    error: {
      100: '#FBDDDD',
      200: '#F7BBBB',
      300: '#F29899',
      400: '#EE7677',
      500: '#EA5455',
      600: '#D34C4D',
      700: '#C74748',
      800: '#BB4344',
      900: '#AF3F40',
      light: '#F29899',
      main: '#EA5455',
      dark: '#C74748',
      hover: '#D34C4D',
      active: '#C74748',
      contrastText: whiteColor
    },
    warning: {
      100: '#FFECD9',
      200: '#FFD9B4',
      300: '#FFC58E',
      400: '#FFB269',
      500: '#FF9F43',
      600: '#E68F3C',
      700: '#D98739',
      800: '#CC7F36',
      900: '#BF7732',
      light: '#FFC58E',
      main: '#FF9F43',
      dark: '#D98739',
      hover: '#E68F3C',
      active: '#D98739',
      contrastText: whiteColor
    },
    info: {
      100: '#CCF5FA',
      200: '#99ECF6',
      300: '#66E2F1',
      400: '#33D9ED',
      500: '#00CFE8',
      600: '#00BBD1',
      700: '#00B0C5',
      800: '#00A6BA',
      900: '#009BAE',
      light: '#66E2F1',
      main: '#00CFE8',
      dark: '#00B0C5',
      hover: '#00BBD1',
      active: '#00B0C5',
      contrastText: whiteColor
    },
    success: {
      100: '#D4F4E2',
      200: '#A9E9C5',
      300: '#7EDDA9',
      400: '#53D28C',
      500: '#28C76F',
      600: '#24B364',
      700: '#22A95E',
      800: '#209F59',
      900: '#1E9553',
      light: '#7EDDA9',
      main: '#28C76F',
      dark: '#22A95E',
      hover: '#24B364',
      active: '#22A95E',
      contrastText: whiteColor
    },
    grey: {
      50: '#F6F6F7',
      100: '#EDECEE',
      200: '#DBDADE',
      300: '#C9C7CE',
      400: '#B7B5BE',
      500: '#A5A3AE',
      600: '#93909D',
      700: '#817D8D',
      800: '#6F6B7D',
      900: '#5D586C',
      A100: '#F6F6F7',
      A200: '#DBDADE',
      A400: '#B7B5BE',
      A700: '#817D8D'
    },
    text: {
      primary: mode === 'light' ? '#5D586C' : '#DBDADE',
      secondary: mode === 'light' ? '#6F6B7D' : '#C9C7CE',
      disabled: mode === 'light' ? '#A5A3AE' : '#817D8D',
      placeholder: mode === 'light' ? '#B7B5BE' : '#6F6B7D'
    },
    divider: mode === 'light' ? '#DBDADE' : '#434968',
    background: {
      paper: borderedBgColor,
      default: defaultBgColor()
    },
    action: {
      active: colorWithOpacity(mainColor, 54),
      hover: colorWithOpacity(mainColor, 4),
      selected: colorWithOpacity(mainColor, 6),
      selectedOpacity: 0.06,
      disabled: colorWithOpacity(mainColor, 26),
      disabledBackground: colorWithOpacity(mainColor, 12),
      focus: colorWithOpacity(mainColor, 12)
    }
  } as Palette
}

export default DefaultPalette
