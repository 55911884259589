import { ReactNode, useEffect, useMemo, useState } from 'react'

import { useRouter } from 'next/router'

import Spinner from 'src/@core/components/spinner'

// import BlankLayout from 'src/@core/layouts/BlankLayout'

import { UserDataType } from 'src/context/types'

import getHomeRoute from 'src/layouts/components/acl/getHomeRoute'
import { AbilityContext } from 'src/layouts/components/acl/Can'

import authConfig from 'src/configs/auth'
import { buildAbilityFor, canAccessRouteByRole, getAclAbilities } from 'src/configs/acl'

// import NotAuthorized from 'src/pages/401'
import { NextPage } from 'next'

interface AclGuardProps {
  children: ReactNode
  authGuard?: boolean
  guestGuard?: boolean
  isPreviewMode: boolean
  Component: NextPage
}

const AclGuard = (props: AclGuardProps) => {
  const { Component, isPreviewMode, children, guestGuard = false, authGuard = true } = props

  const aclAbilities = useMemo(() => getAclAbilities(Component, isPreviewMode), [Component, isPreviewMode])

  const [_, setIsUserLoading] = useState<boolean>(false)

  const router = useRouter()

  const user: UserDataType = useMemo(() => {
    const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'
    const storage = isPreviewMode ? window?.sessionStorage : window?.localStorage

    if (storage.getItem(authConfig.storageTokenKeyName)) {
      return JSON.parse(storage.getItem(authConfig.storageTokenKeyName) || '')
    }
  }, [])

  const ability = useMemo(() => {
    if (user?.role) {
      return buildAbilityFor(user.role)
    }

    return buildAbilityFor('guest')
  }, [user])

  const handlePreviewUserMessage = (event: MessageEvent) => {
    if (event.data?.type === 'SET_PREVIEW_USER') {
      setIsUserLoading(false)

      const newUrl = router.pathname
      router.replace(newUrl, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (router.query.preview === 'true') {
      setIsUserLoading(true)

      // Send the message to request the preview user from the parent window
      window.opener?.postMessage('REQUEST_PREVIEW_USER', '*')

      window?.addEventListener('message', handlePreviewUserMessage)
    }

    if (user && user.role && !guestGuard && router.route === '/') {
      const homeRoute = getHomeRoute(user.role)

      router.replace(homeRoute)
    }

    return () => {
      window?.removeEventListener('message', handlePreviewUserMessage)
    }
  }, [user, guestGuard, router])

  useEffect(() => {
    const guestGuardCond = guestGuard || router.route === '/404' || router.route === '/500' || !authGuard
    const userAccessCond =
      ability &&
      ability.can(aclAbilities.action, aclAbilities.subject) &&
      canAccessRouteByRole(user?.role ?? 'guest', router.pathname)

    if (!guestGuardCond && !userAccessCond) {
      const targetRoute = user ? getHomeRoute(user.role) : '/login'

      if (router.pathname !== targetRoute) {
        if (!router.query.returnUrl && !user) {
          router.replace({
            pathname: targetRoute,
            query: { returnUrl: router.asPath } // Add returnUrl to query
          })
        } else {
          router.replace(targetRoute)
        }
      }
    }
  }, [ability, aclAbilities.action, aclAbilities.subject, user, authGuard, guestGuard, router])

  // Handle guest guard and unprotected routes
  if (guestGuard || router.route === '/404' || router.route === '/500' || !authGuard) {
    if (ability) {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    } else {
      return <>{children}</>
    }
  }

  // Handle user access
  if (
    ability &&
    ability.can(aclAbilities.action, aclAbilities.subject) &&
    canAccessRouteByRole(user?.role ?? 'guest', router.pathname)
  ) {
    if (router.route === '/') {
      return <Spinner />
    }

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  }

  // redirect to homepage or login instead of showing unauthorized page
  return <Spinner />

  // Render Not Authorized component if access is denied
  // return (
  //   <BlankLayout>
  //     <NotAuthorized />
  //   </BlankLayout>
  // )
}

export default AclGuard
