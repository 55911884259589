import { useMemo } from 'react'

import { Stack, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import { getUserRole, UserRolesType } from 'src/utils/getUserRole'

import useModal from '../../../../hooks/useModal'
import { useDeleteProfileForm } from '../../../../hooks/settings/profile'

import { DeleteAccountModal } from './DeleteAccountModal'

const WarningWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colorWithOpacity(theme.palette.warning.main, 16),
  padding: theme.spacing(3, 4),
  borderRadius: theme.shape.borderRadius
}))

const StyledCheckbox = styled(Checkbox)(() => ({
  padding: 0
}))

export const AccountSettingsEditor = () => {
  const theme = useTheme()

  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS.FORM' })
  const { t: tGlobal } = useTranslation('translation')
  const userRole = getUserRole()

  const { isOpen, open, close } = useModal()

  const { register, onSubmit, isValid, isSubmitting, control, confirmed } = useDeleteProfileForm(close)

  const isPreviewMode = useMemo(() => userRole === UserRolesType.HIDDEN_CUSTOMER, [userRole])

  return (
    <Stack spacing={theme.spacing(6)} marginTop={theme.spacing(6)}>
      <Stack spacing={theme.spacing(4)}>
        <Typography variant='h5'>{t('delete_account')}</Typography>

        <WarningWrapper spacing={theme.spacing(2.5)}>
          <Typography variant='h5' color={theme.palette.warning.main}>
            {t('delete_modal_confirm_title')}
          </Typography>

          <Typography variant='body1' color={theme.palette.warning.main}>
            {t('delete_modal_confirm_info')}
          </Typography>
        </WarningWrapper>

        <Controller
          name='confirm'
          control={control}
          render={({ field: { value, onChange } }) => (
            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top-start'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <FormControlLabel
                label={t('delete_modal_confirm_label')}
                control={<StyledCheckbox checked={value} onChange={isPreviewMode ? undefined : onChange} />}
              />
            </Tooltip>
          )}
        />
      </Stack>

      <Box>
        <Button color='error' variant='contained' onClick={open} disabled={!confirmed}>
          {t('delete_account')}
        </Button>
      </Box>

      <DeleteAccountModal
        isOpen={isOpen}
        onClose={close}
        register={register}
        onSubmit={onSubmit}
        isValid={isValid}
        isSubmitting={isSubmitting}
      />
    </Stack>
  )
}
