import { useQuery } from '@tanstack/react-query'

import contentCreatorsService from 'src/service/content-creators-service'

export const usePortalType = () => {
  return useQuery({
    queryKey: ['portal_type'],
    queryFn: () => contentCreatorsService.getPortalType(),
    select: response => response.data.result
  })
}
