import { ReactNode } from 'react'

export type FormTabHorizontalProps = {
  title: string
  menuIcon?: string
  children?: ReactNode
}

export const FormTabHorizontal = ({ children }: FormTabHorizontalProps) => {
  return <>{children}</>
}
