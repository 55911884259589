import { NavLink, NavSectionTitle } from 'src/@core/layouts/types'

import LoginNav from 'src/components/navigation/vertical/account-menu/LoginNav'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'
import { NavigationMenuItem } from '../../../types/navigation-items'

interface CustomLinksMenuProps {
  customLinks: NavigationMenuItem[]
}

const customMenuLinksBase: NavSectionTitle = {
  sectionTitle: 'NAVIGATION.customLinks.sectionTitle',
  aclSubject: subjects.stundentPage,
  subject: subjects.customLinks,
  action: permissionActions.read
}

const createCustomLinksMenu = (customLinks: NavigationMenuItem[]): (NavLink | NavSectionTitle)[] => [
  customMenuLinksBase,
  ...customLinks.map(customLink => ({
    title: customLink.name,
    aclSubject: subjects.guest,
    subject: subjects.customLinks,
    action: permissionActions.read,
    openInNewTab: customLink.open_in_new_tab,
    icon: 'tabler:link',
    render: LoginNav
  }))
]

const GuestCustomLinksMenu = ({ customLinks }: CustomLinksMenuProps): (NavLink | NavSectionTitle)[] => {
  if (!customLinks) return []

  return createCustomLinksMenu(customLinks)
}

export default GuestCustomLinksMenu
