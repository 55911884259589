import { memo, useMemo } from 'react'

import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import { LayoutProps, NavGroup, NavLink, NavSectionTitle } from 'src/@core/layouts/types'

import { permissionActions } from '../../../../../configs/permissionActions'
import { subjects } from '../../../../../navigation/vertical/subjects'

import HorizontalNavItems from './HorizontalNavItems'

// 16 - paddingX
// 22 - icon width
// 8  - icon gap
// 36 - minimun 36px of text
// const GROUP_CUTOFF_WIDTH = 16 * 2 + 22 + 8 + 36

const ItemsWrapper = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0
})

interface Props {
  settings: LayoutProps['settings']
  items: (NavLink | NavGroup | NavSectionTitle)[]
}

const CustomLinks = memo((props: Props) => {
  const theme = useTheme()

  const { visibleItems, overflowItems, items } = useMemo(() => {
    let overflowItems: (NavGroup | NavLink | NavSectionTitle)[]
    const items = props.items.filter((item): item is NavGroup | NavLink => !(item as NavSectionTitle)?.sectionTitle)
    if (items.length > 2) {
      overflowItems = [
        {
          icon: 'tabler:link',
          title: 'NAVIGATION.customLinks.extraLinks',
          action: permissionActions.manage,
          subject: subjects.customLinks,
          aclSubject: subjects.stundentPage,
          children: items.slice(1)
        }
      ]
    } else if (items.length === 2) {
      overflowItems = items.slice(1)
    } else {
      overflowItems = []
    }

    return {
      visibleItems: items.length > 0 ? items.slice(0, 1) : [],
      overflowItems,
      items
    }
  }, [props.items])

  return (
    <Stack minWidth={0} spacing={theme.spacing(2)} direction='row' alignItems='center'>
      {items.length ? (
        <ItemsWrapper>
          <HorizontalNavItems {...props} horizontalNavItems={visibleItems} hasTitle />
        </ItemsWrapper>
      ) : null}

      {items.length === 2 ? (
        <ItemsWrapper>
          <HorizontalNavItems {...props} horizontalNavItems={overflowItems} hasTitle />
        </ItemsWrapper>
      ) : null}

      {items.length > 2 ? <HorizontalNavItems {...props} horizontalNavItems={overflowItems} hasTitle /> : null}
    </Stack>
  )
})

export default CustomLinks
