import { OwnerStateThemeType } from './'

const Backdrop = () => {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.colorWithOpacity(theme.palette.customColors.main, 70)
              : theme.colorWithOpacity(theme.palette.background.default, 70)
        }),
        invisible: {
          backgroundColor: 'transparent'
        }
      }
    }
  }
}

export default Backdrop
