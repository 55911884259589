import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { IconButton, IconButtonProps } from '@mui/material'
import { alpha } from '@mui/system'

interface CroppedImageProps {
  isCircle?: boolean
}

export const DropArea = styled(Box)<BoxProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  borderRadius: '6px',
  justifyContent: 'center'
}))

export const ImageWrapper = styled(Box)<BoxProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  height: '100%',
  width: '100%'
}))

export const DeleteButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2)
}))

export const CroppedImage = styled('img')<CroppedImageProps>(({ isCircle = false }) => ({
  maxHeight: 300,
  maxWidth: 572,
  overflow: 'hidden',
  borderRadius: isCircle ? '50%' : 6
}))

export const IntroductionWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'center',
  padding: theme.spacing(10, 6),
  position: 'relative'
}))

export const IntroductionIconWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  borderRadius: '6px',
  backgroundSize: 'cover',
  color: theme.palette.text.primary,
  height: 38,
  justifyContent: 'center',
  padding: '5px',
  transition: 'all 120ms ease-out',
  width: 38
}))

export const Footer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexWrap: 'wrap',
  alignItems: 'stretch',
  '& > *': {
    flexGrow: 1
  }
}))
