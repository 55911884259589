import { useMemo } from 'react'

import authService from 'src/service/auth-service'

import { usePortalType } from './usePortalType'

export const useUserPortalInfo = () => {
  const userInfo = authService.getUser()
  const { data: portalTypes } = usePortalType()

  const { isTrialEnded, isTrial, isVerified } = useMemo(() => {
    const userPortalType = portalTypes?.find(type => type.id === userInfo?.portal?.type_id)?.name

    return {
      isTrialEnded: userPortalType === 'trial_ended',
      isTrial: userPortalType === 'trial',
      isVerified:
        userInfo?.portal_member?.verification &&
        Boolean(userInfo?.portal_member?.verification?.verified_at) &&
        userInfo.portal_member?.role_id !== 5
    }
  }, [portalTypes, userInfo?.portal?.type_id, userInfo?.portal_member?.role_id, userInfo?.portal_member?.verification])

  return { isTrialEnded, isTrial, isVerified }
}
