import env from 'src/envs'
import authService from 'src/service/auth-service'

export const getImageUrl = (id: string | number) => {
  const token = authService.getUserToken()

  return `${
    process.env.NODE_ENV === 'production' ? window.location.origin : env.apiUrl
  }/entity_api/image/get/${id}?token=${token}`
}
