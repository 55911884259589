import { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'

import { styled, useTheme } from '@mui/material/styles'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'

import { useTranslation } from 'react-i18next'

import { useMarkAsRead } from '../../hooks/notifications'
import { routePaths } from '../../navigation/paths'
import { hexToRGBA } from '../../@core/utils/hex-to-rgba'

import { Notification } from './Notification'

import { CommunityNotification } from 'src/types/notifications'

const PopoverWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  maxHeight: 'inherit',
  backgroundColor: theme.palette.customColors.navbarBg
}))

const PopoverHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 6),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const PopoverNotificationsWrapper = styled(Stack, { shouldForwardProp: prop => prop !== 'hasGradient' })<{
  hasGradient?: boolean
}>(({ theme, hasGradient }) => ({
  spacing: theme.spacing(1),
  flex: 1,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  overflow: 'auto',
  ...(hasGradient && {
    '&::after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      bottom: theme.spacing(13),
      left: 0,
      right: 0,
      height: theme.spacing(15),
      background: `linear-gradient(to top, ${hexToRGBA(theme.palette.customColors.navbarBg, 20)}, transparent)`
    }
  })
}))

const Wrapper = styled(Stack)({
  maxWidth: 646,
  width: '100%'
})

type Props = {
  notifications: CommunityNotification[]
  isPopover?: boolean
  closePopover?: () => void
}

export const Notifications = ({ notifications, isPopover = false, closePopover }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.NOTIFICATIONS' })
  const router = useRouter()
  const theme = useTheme()

  const { unread, read } = useMemo(() => {
    const unread = notifications.sort((a, b) => b.created_at - a.created_at).filter(notification => !notification.seen)
    const read = notifications.sort((a, b) => b.created_at - a.created_at).filter(notification => notification.seen)

    return { unread, read }
  }, [notifications])

  const { markAsRead, isPending } = useMarkAsRead(isPopover ? 10 : undefined)

  const onMarkAsReadClick = useCallback(() => {
    markAsRead(unread.map(notification => notification.id))
  }, [markAsRead, unread])

  const onAllNotificationsClick = useCallback(() => {
    closePopover?.()
    router.push(routePaths.notifications)
  }, [closePopover, router])

  if (isPopover) {
    return (
      <PopoverWrapper spacing={theme.spacing(6)}>
        <PopoverHeader>
          <Typography variant='h6' color={theme.palette.customColors.navbarText}>
            {t('notifications')}
          </Typography>
          <LoadingButton loading={isPending} onClick={onMarkAsReadClick} size='small' disabled={unread.length === 0}>
            {t('mark_as_read')}
          </LoadingButton>
        </PopoverHeader>
        {notifications.length === 0 ? (
          <Stack justifyContent='center' alignItems='center' flex={1} height='100%'>
            <Typography variant='body1' color={theme.palette.customColors.navbarText}>
              {t('empty')}
            </Typography>
          </Stack>
        ) : (
          <Stack overflow='auto' spacing={theme.spacing(1)}>
            <PopoverNotificationsWrapper hasGradient={notifications.length > 3}>
              {unread.map(notification => (
                <Notification key={notification.id} notification={notification} isPopover closePopover={closePopover} />
              ))}
              {read.map(notification => (
                <Notification key={notification.id} notification={notification} isPopover closePopover={closePopover} />
              ))}
            </PopoverNotificationsWrapper>
            <Stack alignItems='center'>
              <Button size='small' onClick={onAllNotificationsClick}>
                {t('title')}
              </Button>
            </Stack>
          </Stack>
        )}
      </PopoverWrapper>
    )
  }

  return (
    <Wrapper spacing={theme.spacing(4)}>
      {unread.length !== 0 && (
        <Stack spacing={theme.spacing(3)}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>{t('new')}</Typography>
            <LoadingButton loading={isPending} onClick={onMarkAsReadClick}>
              {t('mark_as_read')}
            </LoadingButton>
          </Stack>
          <Stack spacing={theme.spacing(2)}>
            {unread.map(notification => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </Stack>
        </Stack>
      )}
      {read.length !== 0 && (
        <Stack spacing={theme.spacing(3)}>
          <Typography variant='h5'>{t('earlier')}</Typography>
          <Stack spacing={theme.spacing(2)}>
            {read.map(notification => (
              <Notification key={notification.id} notification={notification} />
            ))}
          </Stack>
        </Stack>
      )}
    </Wrapper>
  )
}
