import { ReactNode } from 'react'

import { NavSectionTitle } from 'src/@core/layouts/types'

import Can from 'src/layouts/components/acl/Can'

interface Props {
  children: ReactNode
  navTitle: NavSectionTitle
}

const CanViewNavSectionTitle = (props: Props) => {
  // ** Props
  const { children, navTitle } = props

  if (navTitle && navTitle.auth === false) {
    return <>{children}</>
  }

  return (
    <Can I={navTitle.action} a={navTitle.aclSubject}>
      {children}
    </Can>
  )
}

export default CanViewNavSectionTitle
