import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'

import { ValueOf } from 'src/types/common/utils'
import { VideoUrlFormData, videoUrlSchema } from 'src/schemas/files/VideoUrlSchema'

const VIDEO_URL_YOUTUBE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
const VIDEO_URL_YOUTU_BE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))([^#&?]*).*/

const VIDEO_URL_LOOM_REGEX = /^.*((loom\/)|(v\/)|(\/u\/\w\/)|(share\/)|(embed\/))\??v?=?([^#&?]*).*/

const VideoUrlSource = {
  VIMEO: 'vimeo',
  YOUTU_BE: 'youtu.be',
  YOUTUBE: 'youtube',
  LOOM: 'loom'
} as const

type VideoUrlSource = ValueOf<typeof VideoUrlSource>

const VIDEO_YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed/'
const VIDEO_LOOM_EMBED_URL = 'https://www.loom.com/embed/'
const VIDEO_LOOM_EMBED_CONTROLS = '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'

const VIDEO_VIMEO_EMBED_URL = `https://vimeo.com/api/oembed.json`

const getVideoVimeoUrl = (url: string) => `${VIDEO_VIMEO_EMBED_URL}?url=${url}`
const getVideoLoomEmbedUrl = (id: string) => `${VIDEO_LOOM_EMBED_URL}${id}${VIDEO_LOOM_EMBED_CONTROLS}`

export const matchVideoUrlSource = async (url: string): Promise<string | undefined> => {
  if (url.length === 0) return

  if (url.includes(VideoUrlSource.VIMEO)) {
    const response = await axios.get<{ html: string }>(getVideoVimeoUrl(url))
    const [_, vimeoUrl] = response.data.html.split(`"`)

    return vimeoUrl
  } else if (url.includes(VideoUrlSource.YOUTUBE) || url.includes(VideoUrlSource.YOUTU_BE)) {
    let regex: RegExp
    if (url.includes(VideoUrlSource.YOUTUBE)) {
      regex = VIDEO_URL_YOUTUBE_REGEX
    } else {
      regex = VIDEO_URL_YOUTU_BE_REGEX
    }

    const youtubeUrl = url.match(regex)

    // NOTE: match[7] includes id
    if (!youtubeUrl || youtubeUrl?.[7]?.length !== 11) return

    return `${VIDEO_YOUTUBE_EMBED_URL}${youtubeUrl[7]}`
  } else if (url.includes(VideoUrlSource.LOOM)) {
    const loomUrl = url.match(VIDEO_URL_LOOM_REGEX)

    // NOTE: match[7] includes id
    if (!loomUrl || !loomUrl?.[7]) return

    return getVideoLoomEmbedUrl(loomUrl[7])
  }
}

export const useVideoUrlUploadForm = (defaultValues?: VideoUrlFormData) => {
  return useForm<VideoUrlFormData>({
    resolver: zodResolver(videoUrlSchema),
    defaultValues
  })
}
