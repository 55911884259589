import { useEffect, useRef } from 'react'

import { Box, useTheme } from '@mui/material'

import { useIntersection } from '@mantine/hooks'
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query'
import { useTranslation } from "react-i18next";

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import MediaListRow from './MediaListRow'

import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'
import { MediaVariant } from 'src/types/files/media'

type MediaListViewProps = {
  mediaList: (FileType | FolderType)[]
  mediaType: MediaVariant
  view: 'grid' | 'list'
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<(FileType | FolderType)[], unknown>, Error>>
  setSelected: (isSelected?: FileType | FolderType) => void
  setActive: (active?: FileType & FolderType) => void
  handleEdit: () => void
  setIsCollapsed: (collapsed: number[]) => void
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleDoubleClick: () => void
  isSelected?: (FileType | FolderType) | undefined
  isCollapsed?: number[]
  isModal?: boolean
}

const MediaListView = ({
  mediaList,
  mediaType,
  view,
  fetchNextPage,
  setSelected,
  setActive,
  setIsCollapsed,
  handleClick,
  handleDoubleClick,
  handleEdit,
  isSelected,
  isCollapsed,
  isModal
}: MediaListViewProps) => {
  const { t } = useTranslation();
  const theme = useTheme()

  const listRef = useRef<HTMLDivElement | null>(null)

  const { setMultiSelect } = useSelectedMedia()

  const { ref, entry } = useIntersection({
    root: listRef.current,
    threshold: 0.1
  })

  useEffect(() => {
    if (entry?.isIntersecting && view === 'list') fetchNextPage()
  }, [entry, view])

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto',
        maxHeight: `calc(100vh - ${isModal ? 475 : 318}px)`,
        borderRadius: theme.spacing(1),
        border: '1px solid #DBDADE'
      }}
      ref={listRef}
    >
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 150px 110px 110px 30px',
          gap: '20px',
          textTransform: 'uppercase',
          borderBottom: '1px solid #DBDADE',
          padding: '20px'
        }}
      >
        <Box>{t('extracted.name')}</Box>
        <Box>{t('extracted.date_&_time')}</Box>
        <Box>{t('extracted.type')}</Box>
        <Box>{t('extracted.size')}</Box>
        <Box></Box>
      </Box>
      {mediaList.map((media, i) => (
        <Box key={`media-row-item-${media.id}`} {...(i === mediaList.length - 1 && { ref })}>
          <MediaListRow
            media={media}
            mediaType={mediaType}
            isSelected={isSelected?.id === media.id}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              setSelected(media)
              handleClick(e)
            }}
            onDoubleClick={() => {
              if (media.type === 'folder') {
                setActive(media)
                setIsCollapsed([...(isCollapsed || []), media.id])
              } else {
                handleDoubleClick()
              }
              setSelected()
              setMultiSelect()
            }}
            setSelected={setSelected}
            handleEdit={handleEdit}
          />
        </Box>
      ))}
    </Box>
  )
}

export default MediaListView
