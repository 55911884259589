// ** React imports
import { useState } from 'react'

// ** MUI imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

// ** Core imports
import { useTranslation } from "react-i18next";

import CustomTextField from 'src/@core/components/mui/text-field'
import Icon from 'src/@core/components/icon'

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    width: '93%'
  }
}))

const ModalHeader = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(5, 6, 1)
}))

const ModalBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(6)
}))

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

type TextInputModal = {
  disableSubmitIfDirty?: boolean
  defaultValue?: string
  title: string
  submitText: string
  isOpen: boolean
  onSubmit: (text: string) => void
  onCancel: () => void
}

const TextInputModal = ({
  disableSubmitIfDirty,
  isOpen,
  onSubmit,
  onCancel,
  defaultValue,
  title,
  submitText
}: TextInputModal) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>()

  const handleSubmit = () => {
    if (!value) return
    onSubmit(value)
  }

  return (
    <Modal open={isOpen} onClose={onCancel}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onCancel} />
        </IconBox>
        <ModalHeader>
          <Typography variant='h5'>{title}</Typography>
        </ModalHeader>
        <ModalBody>
          <CustomTextField
            autoFocus
            type='text'
            placeholder={t('FORM.placeholder')}
            InputProps={{ sx: { width: '100%' } }}
            onChange={e => setValue(e.target.value)}
            value={value}
            defaultValue={defaultValue}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant='tonal' onClick={onCancel}>
            {t('ACTION.cancel')}</Button>
          <Button
            variant='contained'
            disabled={disableSubmitIfDirty && (value === undefined || value === defaultValue)}
            onClick={handleSubmit}
          >
            {submitText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TextInputModal
