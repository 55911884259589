export type SupportSettings = {
  is_active: boolean
  id?: number
  portal_id: number
  support_type_id: number
  credentials: {
    value: string
  }
}

export type CredentialFields = {
  key?: string
  email?: string
}

export type SupportType = {
  id: number
  name: string
  credential_fields: CredentialFields
}

export const supportTypeIDs = {
  contact_form: 1,
  intercom: 2,
  helpscout: 3,
  freshdesk: 4,
  membershipio: 5
} as const

export type SupportSettingsWithType = SupportSettings & Omit<SupportType, 'id'>
