import { AxiosResponse } from 'axios'

import service from './service'

import { GetMediaRequest, GetMediaResponse } from 'src/types/files/media'

const requestUrls = {
  // uploadFile: `/mediastorage/file/upload`,
  // getMediaStorageImage: (imageId: string) => `/image/get?id=${imageId}`,
  // deleteMediaStorageImage: '/image/delete',
  // getFile: `/mediastorage/file/get`,
  folder: `/entity_api/entity/folder/get`,
  file: `/entity_api/entity/file/get`

  // deleteFile: `/mediastorage/file/delete`,
  // getVideo: (id: string) => `/mediastorage/file/get?file_id=${id}`,
  // uploadVideo: `/video/upload`,
  // getVideoStatus: `/video/status`
}

const mediaStorageService = {
  getMediaList: async ({ entityType, queryFilters }: GetMediaRequest): Promise<AxiosResponse<GetMediaResponse>> => {
    return service.post(requestUrls[entityType], { ...queryFilters })
  }

  // getVideo: async (requestBody: any): Promise<any> => {
  //   return service.post(requestUrls.getVideo(requestBody.file_id), { responseType: 'blob' })
  // },
  // uploadFile: async (values: any): Promise<any> => {
  //   return service.post(requestUrls.uploadFile, { values })
  // },
  // deleteFile: async (requestBody: any): Promise<any> => {
  //   return service.post(requestUrls.deleteFile, requestBody)
  // },
}

export default mediaStorageService
