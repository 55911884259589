import { forwardRef, useState } from 'react'

import { IconButton, InputAdornment } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'

import CustomTextField from '../../../../@core/components/mui/text-field'
import Icon from '../../../../@core/components/icon'

type Props = TextFieldProps

export const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <CustomTextField
      {...props}
      placeholder={'••••••••••••'}
      type={showPassword ? 'text' : 'password'}
      inputRef={ref}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton edge='end' onMouseDown={e => e.preventDefault()} onClick={() => setShowPassword(!showPassword)}>
              <Icon fontSize='1.25rem' icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
})
