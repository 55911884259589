import React, { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'

import { Box, BoxProps, CircularProgress, Stack, styled, useTheme } from '@mui/material'

import { usePortalSettings } from 'src/hooks/usePortalSettings'

import { getImageUrl } from 'src/utils/getImageUrl'
import { modifyAnimationColors } from 'src/utils/lottie'

import animationData from './data.json'

const CustomLoaderContainer = styled(Stack)({
  position: 'relative'
})

const StyledLogo = styled(Box)({
  position: 'absolute',
  width: 64,
  height: 64,
  bottom: 5,
  right: 5,
  borderRadius: '50%',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
})

const Lottie = dynamic(() => import('react-lottie'), { ssr: false })

const FallbackSpinner = ({ sx, fullHeight = true }: { sx?: BoxProps['sx']; fullHeight?: boolean }) => {
  const [isClient, setIsClient] = useState(false)
  const { portalSettings } = usePortalSettings()

  useEffect(() => {
    setIsClient(true)
  }, [])

  const theme = useTheme()

  if (!isClient) {
    return null
  }

  const useFaviconAsLoader = portalSettings?.general_design_settings?.[0]?.use_favicon_as_loader
  const loaderColor = portalSettings?.general_design_settings?.[0]?.loader_color
  const faviconUrl = getImageUrl(portalSettings?.general_design_settings?.[0]?.design_image?.[0]?.favicon || '')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderColor ? modifyAnimationColors(animationData, loaderColor) : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Box
      sx={{
        height: fullHeight ? '100vh' : theme.spacing(64),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        pointerEvents: 'none',
        ...sx
      }}
    >
      {useFaviconAsLoader ? (
        <CustomLoaderContainer position='relative'>
          <StyledLogo sx={{ backgroundImage: `url(${faviconUrl})` }} />

          <CircularProgress disableShrink size={74} thickness={1.5} sx={{ color: loaderColor }} />
        </CustomLoaderContainer>
      ) : (
        <Lottie options={defaultOptions} height={80} width={80} />
      )}
    </Box>
  )
}

export default FallbackSpinner
