import { MutableRefObject, useEffect } from 'react'

import { Box } from '@mui/system'

import { useIntersection } from '@mantine/hooks'
import { FetchNextPageOptions, InfiniteData, InfiniteQueryObserverResult } from '@tanstack/react-query'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import GridIconImage from './Image'
import GridIconFile from './File'
import GridIconFolder from './Folder'
import GridIconVideo from './Video'
import GridIconAudio from './Audio'

import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'
import { MediaVariant } from 'src/types/files/media'

type GridIconViewProps = {
  setActive: (active?: FileType & FolderType) => void
  setSelected: (isSelected?: FileType & FolderType) => void
  setIsCollapsed: (collapsed: number[]) => void
  handleEdit: () => void
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDoubleClick: () => void
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<(FileType | FolderType)[], unknown>, Error>>
  gridRef: MutableRefObject<HTMLElement | null>
  view: 'grid' | 'list'
  data?: (FileType | FolderType)[]
  isSelected?: (FileType | FolderType) | undefined
  isCollapsed?: number[]
  mediaType: MediaVariant
}

const GridIconView = ({
  setActive,
  setSelected,
  setIsCollapsed,
  handleEdit,
  handleClick,
  onDoubleClick,
  fetchNextPage,
  gridRef,
  view,
  data,
  isSelected,
  isCollapsed,
  mediaType
}: GridIconViewProps) => {
  const { setMultiSelect } = useSelectedMedia()

  const { ref, entry } = useIntersection({
    root: gridRef.current,
    threshold: 0.1
  })

  useEffect(() => {
    if (entry?.isIntersecting && view === 'grid') fetchNextPage()
  }, [entry])

  const renderIcon = (variant: FileType | FolderType) => {
    const commonProps = {
      data: variant,
      isSelected: variant === isSelected,
      setSelected,
      handleClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        setSelected(variant)
        handleClick(e)
      },
      onDoubleClick,
      handleEdit
    }

    switch (variant.type) {
      case 'folder':
        return (
          <GridIconFolder
            {...commonProps}
            onDoubleClick={() => {
              setActive(variant)
              setIsCollapsed([...(isCollapsed || []), variant.id])
              setSelected()
              setMultiSelect()
            }}
          />
        )
      case 'audio':
        return <GridIconAudio {...commonProps} />
      case 'video':
        return <GridIconVideo {...commonProps} />
      case 'pdf':
      case 'file':
        return <GridIconFile {...commonProps} mediaType={mediaType} />
      case 'image':
        return <GridIconImage {...commonProps} />
      default:
        return null
    }
  }

  return (
    <>
      {data?.map((variant, i) => {
        if (!mediaType || variant.type === mediaType || variant.type === 'folder') {
          return (
            <Box {...(i === data.length - 1 && { ref })} key={`media-grid-item-${variant.id}`}>
              {renderIcon(variant)}
            </Box>
          )
        }

        return null
      })}
    </>
  )
}

export default GridIconView
