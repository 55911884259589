import { z } from 'zod'
import * as zod from 'zod'

import { validateTextByEmailVariables } from '../../../utils/settings/emails'
import { EmailVariable } from '../../../types/settings/email/email'

export const createAuthEmailsSchema = (emailVariables: EmailVariable[]) => {
  const passwordVariables = emailVariables.find(v => v.name === 'password_reset')!.allowed_variables
  const registerVariables = emailVariables.find(v => v.name === 'recently_registered_user')!.allowed_variables

  return z.object({
    email_recently_registered_user_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, registerVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_recently_registered_user_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, registerVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    user_password_reset_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, passwordVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    user_password_reset_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, passwordVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      })
  })
}

export type AuthEmailsFormData = zod.infer<ReturnType<typeof createAuthEmailsSchema>>
