import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader'
import Divider from '@mui/material/Divider'

import { NavSectionTitle } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'

import Translations from 'src/layouts/components/Translations'
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle'

interface Props {
  navHover: boolean
  settings: Settings
  item: NavSectionTitle
  collapsedNavWidth: number
  navigationBorderWidth: number
}

// ** Styled Components
const ListSubheader = styled((props: ListSubheaderProps) => <MuiListSubheader component='li' {...props} />)(
  ({ theme }) => ({
    width: theme.spacing(65),
    height: theme.spacing(9.5),
    padding: theme.spacing(4.75, 7.5, 1.25, 7.5),
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    lineHeight: theme.spacing(0.25),
    display: 'flex',
    position: 'static',
    backgroundColor: 'transparent',
    transition: 'padding-left .25s ease-in-out'
  })
)

const VerticalNavSectionTitle = (props: Props) => {
  // ** Props
  const { item, navHover, settings } = props

  // ** Vars
  const { navCollapsed } = settings

  return (
    <CanViewNavSectionTitle navTitle={item}>
      <ListSubheader
        className='nav-section-title'
        sx={{
          ...(navCollapsed && !navHover
            ? {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                px: 0
              }
            : {}),
          '& .MuiTypography-root, & svg': {
            color: 'customColors.navbarSecondaryText'
          }
        }}
      >
        {navCollapsed && !navHover ? (
          <Divider flexItem />
        ) : (
          <Typography noWrap variant='caption' sx={{ textTransform: 'uppercase' }}>
            <Translations text={item.sectionTitle} />
          </Typography>
        )}
      </ListSubheader>
    </CanViewNavSectionTitle>
  )
}

export default VerticalNavSectionTitle
