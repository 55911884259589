import { MouseEvent, useCallback, useState } from 'react'

const useMenu = () => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const open = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }, [])

  const close = useCallback(() => {
    setAnchor(null)
  }, [])

  return {
    anchor,
    isOpen: Boolean(anchor),
    open,
    close
  }
}

export default useMenu
