import { useQuery } from '@tanstack/react-query'

import queries from 'src/queries'
import navigationService, { GetAllNavigationItemRequest } from 'src/service/navigation-items-service'

const request: GetAllNavigationItemRequest = {
  columns: [
    'id',
    'link',
    'name',
    'open_in_new_tab',
    'portal_id',
    'position'
  ],
  order_by: [{
    ascending: true,
    column: 'id'
  }],
}

const useNavigationItems = (enabled = true) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: queries.navigationItems.getAll.queryKey,
    queryFn: () => navigationService.getAll(request),
    select: response => response.data.result.sort((a, b) => a.position - b.position),
    enabled
  })

  return { data, isError, isLoading }
}

export default useNavigationItems
