import { createQueryKeys } from '@lukemorales/query-key-factory'

const leaderboards = createQueryKeys('leaderboards', {
  getLevelAll: {
    queryKey: null
  },
  getStats: {
    queryKey: null
  },
  getCustomerStats: {
    queryKey: null
  }
})

export default leaderboards
