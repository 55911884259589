import { useEffect, useState } from 'react'

import { Box, IconButton, Typography, alpha, useTheme } from '@mui/material'

import { Icon } from '@iconify/react'

import { MediaGridProps } from 'src/types/files/media'

const GridIconFolder = ({ data, isSelected = false, setSelected, handleClick, onDoubleClick }: MediaGridProps) => {
  const theme = useTheme()

  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <Box
      sx={{
        bgcolor: isSelected ? alpha(theme.palette.primary.main, 0.08) : theme.palette.background.paper,
        border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.background.paper}`,
        borderRadius: isSelected ? '8px' : '0',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: {
          xs: '169px',
          md: '189px'
        },
        minWidth: {
          xs: '169px',
          md: '189px'
        },
        height: '177.75px',
        gap: theme.spacing(2),
        opacity: isMounted ? 1 : 0,
        padding: theme.spacing(2),
        transition: 'all .1s ease-in-out',
        transform: `scale(${isMounted ? 1.0 : 0.8})`,
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px'
        }
      }}
      onClick={() => {
        setSelected(data)
      }}
      onDoubleClick={onDoubleClick}
    >
      <Box
        onClick={() => setSelected(data)}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          bgcolor: alpha(theme.palette.primary.main, 0.2),
          borderRadius: '4px',
          display: 'flex',
          minHeight: '98.28px',
          maxHeight: '98.29px',
          justifyContent: 'center'
        }}
      >
        <Icon icon='tabler:folder' fontSize='64px' color={theme.palette.primary.main} />
      </Box>
      <Box
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          height: 'fit-content',
          display: 'flex',
          gap: theme.spacing(2)
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant='caption'
            color={theme.palette.primary.main}
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              userSelect: 'none',
              maxWidth: '109px',
              whiteSpace: 'nowrap'
            }}
            onClick={handleClick}
          >
            {data.name}
          </Typography>
        </Box>

        <IconButton onClick={handleClick}>
          <Icon icon='tabler:dots-vertical' fontSize='18px' color={theme.palette.primary.main} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default GridIconFolder
