import { useMutation } from '@tanstack/react-query'

import fileService from 'src/service/file-service'

import useToast from '../useToast'

export const useImageDelete = (onSuccess?: () => void) => {
  const { notify } = useToast()

  const { mutateAsync: deleteImage, isPending } = useMutation({
    mutationFn: (id: string) => fileService.deleteMediaStorageImage(id),
    onSuccess: () => {
      onSuccess?.()
      notify('IMAGE.delete', { type: 'success' })
    },
    onError: () => notify('IMAGE.delete', { type: 'error' })
  })

  return { deleteImage, isPending }
}
