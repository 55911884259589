import { createQueryKeys } from '@lukemorales/query-key-factory'

const threads = createQueryKeys('threads', {
  all: (topicIds: number[]) => ({
    queryKey: [{ topicIds }]
  }),

  allThreadLikes: (topicId: number[]) => ({
    queryKey: [{ topicId }]
  }),

  allMyPosts: (topicIds: number[], isRelease) => ({
    queryKey: [{ topicIds, isRelease }]
  }),

  allCount: {
    queryKey: null
  },

  detail: (topicId: number) => {
    return { queryKey: [topicId] }
  },

  allCourses: {
    queryKey: null
  },
  modulesByCourse: (courseId: number) => {
    return { queryKey: [courseId] }
  },
  lessonsByModule: (moduleId: number) => {
    return { queryKey: [moduleId] }
  },
  allLessons: {
    queryKey: null
  }
})

export default threads
