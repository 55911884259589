import { createQueryKeys } from '@lukemorales/query-key-factory'

const aiVideos = createQueryKeys('aiVideos', {
  getAIVideoStatistics: {
    queryKey: null
  },
  getAIVideoGenerationAccess: {
    queryKey: null
  },
  getAIVideoGenerationAccessCount: {
    queryKey: null
  }
})

export default aiVideos
