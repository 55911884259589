import { AxiosResponse } from 'axios'

import env from 'src/envs'

import service from './service'

import { FileUploadResult } from 'src/types/files/upload'
import {
  GetFileRequest,
  DeleteFileRequest,
  GetVideoResponse,
  GetVideoStatusResponse,
  AddFolderRequest
} from 'src/types/courses'
import { FileDetailResponse } from 'src/types/files/fileDetail'
import { FileType } from 'src/types/files/fileData'
import { ResponseResult } from 'src/types/common/types'

export type DownloadFileRequest = { file_id: string }
export type DownloadFileResponse = ResponseResult<string>

const requestUrls = {
  getMediaStorageImage: (imageId: string) => `/entity_api/image/get?id=${imageId}`,
  getVideo: (id: string) => `/entity_api/mediastorage/file/get?file_id=${id}`,
  deleteMediaStorageImage: '/entity_api/image/delete',
  getFile: `/entity_api/mediastorage/file/get`,
  getFileById: `/entity_api/entity/file/get`,
  deleteFile: `/entity_api/mediastorage/file/delete`,
  uploadFile: `/entity_api/mediastorage/file/upload`,
  uploadVideo: `/entity_api/video/upload`,
  getVideoStatus: `/entity_api/video/status`,
  addFolder: `/entity_api/entity/folder/add`,
  deleteFolder: `/entity_api/entity/folder/delete`,
  renameFolder: `/entity_api/entity/folder/update`,
  updateFile: `/entity_api/entity/file/update`,
  duplicateFile: `/entity_api/entity/file/add`,
  addThumbnail: `/entity_api/video/preview/set/custom`,
  downloadFile: '/media_api/v1/file/download'
}

const fileService = {
  getVideoStatus: async (requestBody: { file_handle: string }): Promise<GetVideoStatusResponse> => {
    return service.post(requestUrls.getVideoStatus, requestBody)
  },
  uploadFile: async (requestBody: FormData, onProgress?: (progress: number) => void): Promise<FileUploadResult> => {
    const response = await service.post(requestUrls.uploadFile, requestBody, {
      onUploadProgress: progressEvent => {
        if (progressEvent.total) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          onProgress?.(progress)
        }
      }
    })

    return response
  },
  getMediaStorageImage: async (id: string): Promise<string> => {
    return service.get(requestUrls.getMediaStorageImage(id))
  },
  deleteMediaStorageImage: async (id: string) => {
    return service.post(requestUrls.deleteMediaStorageImage, { id })
  },
  getFile: async (requestBody: GetFileRequest) => {
    return service.post(requestUrls.getFile, requestBody, { responseType: 'blob' })
  },

  //TODO Fix after upload file_id issue with identical files are resolved
  getFileDetails: async (id: string | number, attribute?: string): Promise<FileDetailResponse> => {
    return service.post(requestUrls.getFileById, {
      filter_by: [
        {
          attribute: attribute || 'file_id',
          value: id,
          operator: '=='
        }
      ]
    })
  },
  getFilesByIds: async (ids: number[]): Promise<FileDetailResponse> => {
    return service.post(requestUrls.getFileById, {
      filter_by: [
        {
          attribute: 'id',
          value: ids,
          operator: 'in'
        }
      ]
    })
  },

  deleteFile: async (requestBody: DeleteFileRequest) => {
    return service.post(requestUrls.deleteFile, requestBody)
  },

  getImageSource: (fileId: string, token: string) => `/entity_api/${env.apiUrl}/image/get/${fileId}?token=${token}`,
  getVideo: async (requestBody: GetFileRequest) => {
    return service.post(requestUrls.getVideo(requestBody.file_id), { responseType: 'blob' })
  },

  uploadVideo: async (formData: FormData, onProgress?: (progress: number) => void): Promise<GetVideoResponse> => {
    return service.post(requestUrls.uploadVideo, formData, {
      onUploadProgress: progressEvent => {
        if (progressEvent.total) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          onProgress?.(progress)
        }
      }
    })
  },

  addFolder: async (requestBody: AddFolderRequest) => {
    return service.post(requestUrls.addFolder, requestBody)
  },

  deleteFolder: async (id: number) => {
    return service.post(requestUrls.deleteFolder, { filter_by: [{ attribute: 'id', operator: 'in', value: [id] }] })
  },

  renameFolder: async (requestBody: { values: { id: number; name: string } }) => {
    return service.post(requestUrls.renameFolder, requestBody)
  },

  updateFile: async (values: {
    id: number
    name: string
    created_at_epoch: number
    parent_folder_id: number | null
    portal_id: number
    access_type_id: number
    tags?: string | null
  }) => {
    return service.post(requestUrls.updateFile, {
      filter_by: [{ attribute: 'id', operator: '=', value: values.id }],
      values: {
        id: values.id,
        name: values.name,
        portal_id: values.portal_id,
        access_type_id: 2,
        created_at_epoch: values.created_at_epoch,
        parent_folder_id: values.parent_folder_id,
        tags: values.tags
      }
    })
  },

  duplicateFile: async (values: FileType & { portal_id: number; access_type_id: number }) => {
    return service.post(requestUrls.duplicateFile, {
      values: {
        // access_type_id: 2,
        created_at_epoch: values.created_at_epoch,
        extension: values.extension,
        file_id: values.file_id,
        name: values.name,
        parent_folder_id: values.parent_folder_id || null,

        // portal_id: values.portal_id,
        preview_file_id: values.preview_file_id,
        preview_link: values.preview_link,
        size: values.size,
        tags: values.tags
      }
    })
  },

  addThumbnail: async (value: { video_id: string; preview_file_id: string }) => {
    return service.post(requestUrls.addThumbnail, value)
  },
  downloadFile: async (requestBody: DownloadFileRequest): Promise<AxiosResponse<DownloadFileResponse>> => {
    service.defaults.headers['Content-Type'] = 'application/json'
    service.defaults.headers['Accept'] = 'application/json'

    return service.post(requestUrls.downloadFile, requestBody)
  }
}

export default fileService
