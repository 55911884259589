import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const statistics: NavGroup[] = [
  {
    title: 'NAVIGATION.statistics.statistics',
    icon: 'tabler:chart-bar',
    subject: subjects.topMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    children: [
      {
        title: 'NAVIGATION.statistics.dashboard',
        path: routePaths.dashboard,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.statistics.quizResults',
        path: routePaths.quizResults,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
      {
        title: 'NAVIGATION.statistics.leaderboardStats',
        path: routePaths.leaderboardStats,
        aclSubject: subjects.ccPage,
        subject: subjects.topMenu,
        action: permissionActions.manage
      },
    ]
  }
]

export default statistics
