import { styled, useTheme } from '@mui/material/styles'
import { Popover, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Spinner from '../../@core/components/spinner'
import { CommunityNotification } from '../../types/notifications'
import { useSettings } from '../../@core/hooks/useSettings'

import { Notifications } from './Notifications'

const StyledPopover = styled(Popover)({
  '& .MuiPaper-root': {
    maxWidth: 410,
    width: '100%',
    maxHeight: 374
  }
})

type Props = {
  anchor: Element | null
  onClose: () => void
  isOpen: boolean
  id: string | undefined
  isLoading: boolean
  isError: boolean
  notifications: CommunityNotification[]
}

export const NotificationsPopover = ({ anchor, onClose, isOpen, id, isLoading, isError, notifications }: Props) => {
  const { t: tGlobal } = useTranslation()

  const { settings } = useSettings()

  const theme = useTheme()

  return (
    <StyledPopover
      id={id}
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={
        settings.layout === 'horizontal'
          ? { vertical: 'top', horizontal: 'right' }
          : { vertical: 'bottom', horizontal: 'left' }
      }
    >
      {isLoading ? (
        <Spinner sx={{ height: '100%' }} />
      ) : isError ? (
        <Typography padding={theme.spacing(3)}>{tGlobal('ERROR.unexpected')}</Typography>
      ) : (
        <Notifications notifications={notifications} isPopover closePopover={onClose} />
      )}
    </StyledPopover>
  )
}
