import { useRouter } from 'next/router'

import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'

import memberService from '../../../service/member-service'
import useToast from '../../useToast'
import { useAuth } from '../../useAuth'
import { DeleteProfileValidation, DeleteProfileFormData } from '../../../schemas/settings/profile'

export const useDeleteProfileForm = (onSuccess?: () => void) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { isSubmitting, isValid }
  } = useForm<DeleteProfileFormData>({
    resolver: zodResolver(DeleteProfileValidation),
    mode: 'all'
  })
  const router = useRouter()
  const { removeUser } = useAuth()

  const { notify } = useToast()

  const { mutate: deleteProfile } = useMutation({
    mutationFn: memberService.deleteProfile,
    onSuccess: () => {
      removeUser()
      notify('DELETE_ACCOUNT.success', { type: 'success' })
      router.replace('/register')
      onSuccess?.()
    },
    onError: () => notify('DELETE_ACCOUNT.error', { type: 'error' })
  })

  const onSubmit = () => deleteProfile()

  const confirmed = watch('confirm')

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    isSubmitting,
    control,
    confirmed,
    isValid
  }
}
