import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

import { useTranslation } from 'react-i18next'

import {
  UploaderPendingUploadContainerBox,
  UploaderPendingUploadContentBox,
  UploaderPendingUploadContentTextsBox,
  UploaderPendingUploadInnerColoredBox
} from './styled'

type FileUploadProgressProps = {
  fileName: string
  value: number
}

const FileUploadProgress = ({ fileName, value }: FileUploadProgressProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <UploaderPendingUploadContainerBox>
      <UploaderPendingUploadInnerColoredBox>
        <UploaderPendingUploadContentBox>
          <UploaderPendingUploadContentTextsBox>
            <Typography textAlign={'center'} variant='subtitle1' color={theme.palette.primary.main}>
              {t(`ACTION.ENTITY.uploading_${fileName}`)}
            </Typography>
            <Typography textAlign={'center'} variant='caption' color={theme.palette.primary.main}>
              {t('ACTION.do_not_close_tab')}
            </Typography>
          </UploaderPendingUploadContentTextsBox>
          <LinearProgress variant='determinate' value={value} sx={{ width: '100%' }} />
          <Typography color={theme.palette.primary.main} variant='subtitle1'>
            {Math.round(value)}%
          </Typography>
        </UploaderPendingUploadContentBox>
      </UploaderPendingUploadInnerColoredBox>
    </UploaderPendingUploadContainerBox>
  )
}

export default FileUploadProgress
