import { z } from 'zod'
import * as zod from 'zod'

export const CustomSMTPSchema = z.object({
  smtp_from_email: z
    .string({ errorMap: () => ({ message: 'smtp_from_email' }) })
    .email()
    .min(1),
  smtp_from_name: z.string({ errorMap: () => ({ message: 'required' }) }),
  smtp_host: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
  smtp_password: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
  smtp_port: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
  smtp_username: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
  type_of_encryption: z.string({ errorMap: () => ({ message: 'type_of_encryption' }) }),
  is_enabled: z.boolean()
})

export type CustomSMTPFormData = zod.infer<typeof CustomSMTPSchema>
