import { ReactNode, ReactElement, useEffect } from 'react'

import { useRouter } from 'next/router'

import { useAuth } from 'src/hooks/useAuth'

import { decodeUrl, urlPossibleQueryParams } from 'src/utils/url/encodeUrl'

import authConfig from 'src/configs/auth'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  useEffect(
    () => {
      if (!router.isReady) return

      const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'
      const storage = isPreviewMode ? window?.sessionStorage : window?.localStorage
      const pathSegments = window.location.pathname.split('/')

      if (router.asPath.startsWith('/community')) {
        return
      }

      // If the URL is missing "/app/", redirect the user
      if (
        storage.getItem(authConfig.storageTokenKeyName) &&
        !pathSegments.includes('app') &&
        urlPossibleQueryParams.some(queryKey => queryKey in router.query)
      ) {
        router.replace(router.asPath)

        return
      }

      if (pathSegments[2]?.slice(0, 2) === 'SU') {
        const newUrl = decodeUrl(window.location.href.replace('/app/', '/'))

        window.location.href = window.location.origin + '/app/' + newUrl
      }

      if (!storage.getItem(authConfig.storageTokenKeyName)) {
        if (router.asPath !== '/login' && router.asPath !== '/app') {
          router.replace({
            pathname: '/login',
            query: { returnUrl: router.asPath }
          })
        } else {
          router.replace('/login')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  )

  if (auth.loading) {
    return fallback
  }

  return <>{children}</>
}

export default AuthGuard
