import { ReactNode, useEffect, useState } from 'react'

import { Router, useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import type { AppProps } from 'next/app'

import { captureException, ErrorBoundary } from '@sentry/nextjs'
import NProgress from 'nprogress'
import { CacheProvider } from '@emotion/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'prismjs'
import { registerLocale } from 'react-datepicker'
import { de, enGB, sk, hu, mk, cs } from 'date-fns/locale'

import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'
import Spinner from 'src/@core/components/spinner'
import GuestGuard from 'src/@core/components/auth/GuestGuard'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import AclGuard from 'src/@core/components/auth/AclGuard'
import OnboardingGuard from 'src/@core/components/auth/OnboardingGuard'

import { PortalSettingsProvider } from 'src/context/PortalSettingsContext'
import { AuthProvider } from 'src/context/AuthContext'
import { TabProvider } from 'src/contexts/TabContext'
import { SelectedMediaProvider } from 'src/contexts/SelectedMediaContext'

import { CustomDesignProvider } from 'src/hooks/CustomDesignContext'

import UserLayout from 'src/layouts/UserLayout'

import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

import registerServiceWorker from 'src/utils/registerServiceWorker'
import { loadBranding } from 'src/utils/settings/portal-settings/utils'

import themeConfig from 'src/configs/themeConfig'
import 'src/configs/i18n'
import 'src/configs/sentry.client.config'

import 'src/iconify-bundle/icons-bundle-react'

import ReactQueryClientProvider from '../components/ReactQueryClientProvider'

import type { NextPage } from 'next'
import type { EmotionCache } from '@emotion/cache'

import 'prismjs/themes/prism-tomorrow.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '../../styles/globals.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
}
const initLocales = () => {
  registerLocale('en', enGB)
  registerLocale('de', de)
  registerLocale('sk', sk)
  registerLocale('hu', hu)
  registerLocale('mk', mk)
  registerLocale('cz', cs)
}
const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  if (guestGuard) {
    return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
  }
}

const CustomToaster = dynamic(() => import('src/components/common/CustomToaster'), { ssr: false })

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const router = useRouter()
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(
    typeof window !== 'undefined' && sessionStorage.getItem('preview') === 'true'
  )

  const handlePreviewUserMessage = (event: MessageEvent) => {
    if (event.data?.type === 'SET_PREVIEW_USER') {
      setIsPreviewMode(true)
    }
  }

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false
  const getLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true

  const guestGuard = Component.guestGuard ?? false

  useEffect(() => {
    if (typeof window !== 'undefined') {
      registerServiceWorker()

      loadBranding()
    }
  }, [])

  useEffect(() => {
    if (router.query.preview === 'true') {
      // Send the message to request the preview user from the parent window
      window.opener?.postMessage('REQUEST_PREVIEW_USER', '*')

      window?.addEventListener('message', handlePreviewUserMessage)

      return () => window?.removeEventListener('message', handlePreviewUserMessage)
    }
  }, [router.query.preview])

  useEffect(() => initLocales(), [])

  return (
    <ErrorBoundary
      onError={(error, errorInfo) => {
        console.error('Error caught by ErrorBoundary:', error, errorInfo)

        captureException(error, { extra: { componentStack: errorInfo } })

        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }}
    >
      <ReactQueryClientProvider>
        <CacheProvider value={emotionCache}>
          {/* <Head> */}

          {/* <title>Mentortools</title> */}

          {/*<meta property='og:title' content={metaData.ogTitle} />*/}
          {/*<meta property='title' content={metaData.title} />*/}
          {/*<meta property='description' content={metaData.description} />*/}
          {/*<meta property='og:description' content={metaData.ogDescription} />*/}
          {/*<meta property='og:image' content={metaData.ogImage} />*/}
          {/* </Head> */}

          <AuthProvider>
            <PortalSettingsProvider>
              <CustomDesignProvider>
                <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          <TabProvider>
                            <SelectedMediaProvider>
                              <Guard authGuard={authGuard} guestGuard={guestGuard}>
                                <OnboardingGuard fallback={<Spinner />}>
                                  <AclGuard
                                    Component={Component}
                                    isPreviewMode={isPreviewMode}
                                    guestGuard={guestGuard}
                                    authGuard={authGuard}
                                  >
                                    {getLayout(<Component {...pageProps} />)}
                                  </AclGuard>
                                </OnboardingGuard>
                              </Guard>
                              <CustomToaster position={settings.toastPosition} />
                            </SelectedMediaProvider>
                          </TabProvider>
                        </ThemeComponent>
                      )
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </CustomDesignProvider>
            </PortalSettingsProvider>
          </AuthProvider>
        </CacheProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryClientProvider>
    </ErrorBoundary>
  )
}

export default App
