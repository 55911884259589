import { NavLink } from 'src/@core/layouts/types'

import { permissionActions } from '../../../configs/permissionActions'
import { routePaths } from '../../paths'
import { subjects } from '../subjects'

const appRequests: NavLink = {
  title: 'NAVIGATION.rootMenu.appRequests',
  subject: subjects.topMenu,
  aclSubject: subjects.ccPage,
  action: permissionActions.manage,
  icon: 'tabler:device-mobile',
  path: routePaths.appRequests
}

export default appRequests
