import { NavGroup } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const courses: NavGroup[] = [
  {
    title: 'NAVIGATION.courses',
    subject: subjects.topMenu,
    action: permissionActions.manage,
    aclSubject: subjects.courses,
    icon: 'tabler:book-2',
    children: [
      {
        title: 'NAVIGATION.COURSES_CHILDREN.overview',
        path: routePaths.courses,
        action: permissionActions.manage,
        subject: subjects.topMenu,
        aclSubject: subjects.courses
      },
      {
        title: 'NAVIGATION.COURSES_CHILDREN.mediaStorage',
        path: routePaths.mediaStorage,
        action: permissionActions.manage,
        subject: subjects.topMenu,
        aclSubject: subjects.courses
      },
      {
        title: 'NAVIGATION.COURSES_CHILDREN.addNewCourse',
        path: routePaths.addNewCourse,
        textColor: 'primary',
        fontWeight: 600,
        action: permissionActions.manage,
        subject: subjects.topMenu,
        aclSubject: subjects.courses
      }
    ]
  }
]

export default courses
