import { OwnerStateThemeType } from './'

const Tooltip = () => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }: OwnerStateThemeType) => ({
          padding: theme.spacing(1, 3),
          fontSize: theme.typography.body1.fontSize,
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.colorWithOpacity(theme.palette.customColors.main, 90)
              : theme.colorWithOpacity(theme.palette.customColors.trackBg, 90)
        }),
        arrow: ({ theme }: OwnerStateThemeType) => ({
          color:
            theme.palette.mode === 'light'
              ? theme.colorWithOpacity(theme.palette.customColors.main, 90)
              : theme.colorWithOpacity(theme.palette.customColors.trackBg, 90)
        })
      }
    }
  }
}

export default Tooltip
