import { ElementType, Fragment } from 'react'

import { useRouter } from 'next/router'
import Link from 'next/link'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import MuiListItem, { ListItemProps } from '@mui/material/ListItem'

import clsx from 'clsx'

import { NavLink } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'
import { handleURLQueries } from 'src/@core/layouts/utils'

import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

import themeConfig from 'src/configs/themeConfig'

import { subjects } from '../../../../../navigation/vertical/subjects'

interface Props {
  item: NavLink
  settings: Settings
  hasParent: boolean
  hasTitle?: boolean
}

const ListItem = styled(MuiListItem)<
  ListItemProps & { component?: ElementType; href?: string; target?: '_blank' | undefined }
>(({ theme }) => ({
  width: 'auto',
  borderRadius: theme.shape.borderRadius,
  '& .MuiTypography-root, & .MuiListItemIcon-root': {
    color: theme.palette.customColors.navbarSecondaryText
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  '&.active, &.active:hover': {
    boxShadow: `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
    background: `linear-gradient(
      72.47deg,
      ${theme.palette.primary.main} 22.16%,
      ${hexToRGBA(theme.palette.primary.main, 0.7)} 76.47%
    )`,
    '&:focus-visible': {
      background: `linear-gradient(
        72.47deg,
        ${theme.palette.primary.dark} 22.16%,
        ${hexToRGBA(theme.palette.primary.dark, 0.7)} 76.47%
      )`
    },
    '& .MuiTypography-root, & .MuiListItemIcon-root': {
      color: theme.palette.customColors.navbarText
    }
  },
  '&:focus-visible': {
    outline: 0,
    backgroundColor: theme.palette.action.focus
  }
}))

const ListStyled = styled(List)({ padding: 0 })

const HorizontalNavLink = (props: Props) => {
  // ** Props
  const { item, hasParent, hasTitle } = props

  // ** Hook & Vars
  const router = useRouter()
  const { navSubItemIcon, menuTextTruncate } = themeConfig

  // const icon = item.icon ? item.icon : navSubItemIcon
  const { icon } = item

  const theme = useTheme()

  const Wrapper = !hasParent ? ListStyled : Fragment

  const isNavLinkActive = () => {
    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true
    } else {
      return false
    }
  }

  return (
    <CanViewNavLink navLink={item}>
      <Wrapper {...(!hasParent ? { component: 'div' } : {})} {...hasTitle && { title: item.title }}>
        {item.render ? (
          <ListItem
            className={clsx({ active: isNavLinkActive() })}
            href={item.path === undefined ? '/' : `${item.path}`}
            sx={{
              padding: 0,
              margin: 0,
              ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
              ...(!hasParent
                ? {
                  '&.active, &.active:hover': {
                    boxShadow: theme => `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
                    background: theme =>
                      `linear-gradient(72.47deg, ${theme.palette.primary.main} 22.16%, ${hexToRGBA(
                        theme.palette.primary.main,
                        0.7
                      )} 76.47%)`,
                    '&:focus-visible': {
                      background: theme =>
                        `linear-gradient(72.47deg, ${theme.palette.primary.dark} 22.16%, ${hexToRGBA(
                          theme.palette.primary.dark,
                          0.7
                        )} 76.47%)`
                    },
                    '& .MuiTypography-root, & .MuiListItemIcon-root': {
                      color: 'common.white'
                    }
                  }
                }
                : {
                  mx: 2,
                  width: theme => `calc(100% - ${theme.spacing(2 * 2)})`,
                  '&.active, &.active:hover': {
                    '&:focus-visible': {
                      backgroundColor: theme => hexToRGBA(theme.palette.primary.main, 0.24)
                    }
                  }
                })
            }}
          >
            <item.render
              title={item.title}
              listItemIconProps={{
                sx: {
                  margin: 0,
                  minHeight: theme.spacing(5.5),
                  minWidth: theme.spacing(5.5),
                  padding: theme.spacing(3)
                }
              }}
              menuItemWrapperBoxProps={{ sx: { padding: 0 } }}
            />
          </ListItem>
        ) : (
          <ListItem
            component={Link}
            disabled={item.disabled}
            {...(item.disabled && { tabIndex: -1 })}
            className={clsx({ active: isNavLinkActive() })}
            target={item.openInNewTab ? '_blank' : undefined}
            href={item.path === undefined ? '/' : `${item.path}`}
            onClick={e => {
              if (item.path === undefined) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
            sx={{
              ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
              ...(!hasParent
                ? {
                  '&.active, &.active:hover': {
                    boxShadow: theme => `0px 2px 6px ${hexToRGBA(theme.palette.primary.main, 0.48)}`,
                    background: theme =>
                      `linear-gradient(72.47deg, ${theme.palette.primary.main} 22.16%, ${hexToRGBA(
                        theme.palette.primary.main,
                        0.7
                      )} 76.47%)`,
                    '&:focus-visible': {
                      background: theme =>
                        `linear-gradient(72.47deg, ${theme.palette.primary.dark} 22.16%, ${hexToRGBA(
                          theme.palette.primary.dark,
                          0.7
                        )} 76.47%)`
                    },
                    '& .MuiTypography-root, & .MuiListItemIcon-root': {
                      color: 'common.white'
                    }
                  }
                }
                : {
                  mx: 2,
                  width: theme => `calc(100% - ${theme.spacing(2 * 2)})`,
                  '&.active, &.active:hover': {
                    '&:focus-visible': {
                      backgroundColor: theme => hexToRGBA(theme.palette.primary.main, 0.24)
                    }
                  }
                })
            }}
          >
            <Box sx={{ gap: 2, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(menuTextTruncate && { overflow: 'hidden' })
                }}
              >
                {icon ? (
                  <ListItemIcon sx={{ mr: 2 }}>
                    <UserIcon icon={icon} fontSize={icon === navSubItemIcon ? '0.625rem' : '1.375rem'} />
                  </ListItemIcon>
                ) : null}
                {item.subject !== subjects.bottomMenu && (
                  <Typography {...(menuTextTruncate && { noWrap: true })}>
                    <Translations text={item.title} />
                  </Typography>
                )}
              </Box>
              {item.badgeContent ? (
                <Chip
                  size='small'
                  label={item.badgeContent}
                  color={item.badgeColor || 'primary'}
                  sx={{
                    height: 22,
                    minWidth: 22,
                    borderRadius: 1,
                    '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' }
                  }}
                />
              ) : null}
            </Box>
          </ListItem>
        )}
      </Wrapper>
    </CanViewNavLink>
  )
}

export default HorizontalNavLink
