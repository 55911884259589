import { createQueryKeys } from '@lukemorales/query-key-factory'

const topics = createQueryKeys('topics', {
  all: (communityId?: number) => ({
    queryKey: [{ communityId }]
  }),
  one: (topicId: number) => ({
    queryKey: [{ topicId }]
  })
})

export default topics
