import { AxiosResponse } from 'axios'

import { ResponseResult } from '../types/common/types'
import { EmailSettingsContent } from '../types/settings/email/email'
import { NotificationSettings } from '../types/settings'
import { requestUrls } from '../configs/url'
import { CommunityNotification } from '../types/notifications'

import service from './service'
import authService from './auth-service'

export type GetDefaultEmailTemplatesRequest = { template_code: string; language: string }
export type GetDefaultEmailTemplatesResponse = ResponseResult<EmailSettingsContent>

export type UpdateNotificationSettingsRequest = Omit<NotificationSettings, 'user_id' | 'id'>
export type UpdateNotificationSettingsResponse = ResponseResult<boolean>

export type MarkNotificationsAsReadRequest = { notification_ids: number[] }
export type MarkNotificationsAsReadResponse = ResponseResult<boolean>

export type GetNotificationSettingsResponse = ResponseResult<NotificationSettings>

export type GetNotificationsRequest = { limit?: number }
export type GetNotificationsResponse = ResponseResult<CommunityNotification[]>

const notificationService = {
  getDefaultEmailTemplates: (
    requestParams: GetDefaultEmailTemplatesRequest
  ): Promise<AxiosResponse<GetDefaultEmailTemplatesResponse>> =>
    service.get(requestUrls.getEmailTemplates, {
      params: {
        ...requestParams,
        component_name: 'notifications-default-customer-email-templates'
      }
    }),
  getNotificationSettings: (): Promise<AxiosResponse<GetNotificationSettingsResponse>> => {
    return service.get(requestUrls.getNotificationSettings, {
      params: {
        token: authService.getUserToken(),
        'ngsw-bypass': true
      }
    })
  },
  getNotifications: ({ limit }: GetNotificationsRequest): Promise<AxiosResponse<GetNotificationsResponse>> => {
    return service.get(requestUrls.getNotifications, {
      params: {
        token: authService.getUserToken(),
        'ngsw-bypass': true,
        limit
      }
    })
  },
  markAsRead: (
    requestBody: MarkNotificationsAsReadRequest
  ): Promise<AxiosResponse<MarkNotificationsAsReadResponse>> => {
    return service.post(requestUrls.markNotificationsAsRead, requestBody)
  },
  updateNotificationSettings: (
    requestBody: UpdateNotificationSettingsRequest
  ): Promise<AxiosResponse<UpdateNotificationSettingsResponse>> => {
    return service.post(requestUrls.updateNotificationSettings, requestBody)
  }
}

export default notificationService
