import { useQuery } from '@tanstack/react-query'

import communityService from 'src/service/community-service'

import queries from '../../queries'

export const useCommunities = () => {
  return useQuery({
    queryKey: queries.communities.all.queryKey,
    queryFn: () =>
      communityService.getAll({
        limit: 9999,
        offset: 0
      }),
    select: response => response.data.result
  })
}
