import { deepmerge } from '@mui/utils'
import { PaletteMode, ThemeOptions } from '@mui/material'

import chroma from 'chroma-js'

import { Settings } from 'src/@core/context/settingsContext'

import UserThemeOptions from 'src/layouts/UserThemeOptions'

import { FontKey, getFont } from 'src/fonts'

import palette from './palette'
import spacing from './spacing'
import shadows, { ShadowPalette } from './shadows'
import colorWithOpacity from './colorWithOpacity'
import overrides from './overrides'
import typography from './typography'
import breakpoints from './breakpoints'
import DefaultPalette from './palette'

import { Theme } from 'src/types/settings/portal-settings'

const generateColorShades = (baseColor: string, contrastColor?: string) => {
  return {
    100: chroma(baseColor).brighten(2).hex(),
    200: chroma(baseColor).brighten(1.5).hex(),
    300: chroma(baseColor).brighten(1).hex(),
    400: chroma(baseColor).brighten(0.5).hex(),
    500: baseColor,
    600: chroma(baseColor).darken(0.5).hex(),
    700: chroma(baseColor).darken(1).hex(),
    800: chroma(baseColor).darken(1.5).hex(),
    900: chroma(baseColor).darken(2).hex(),
    light: chroma(baseColor).brighten(1).hex(),
    main: baseColor,
    dark: chroma(baseColor).darken(1).hex(),
    hover: chroma(baseColor).darken(0.5).hex(),
    active: chroma(baseColor).darken(1.2).hex(),
    contrastText: contrastColor || (chroma.contrast(baseColor, 'white') > 4.5 ? '#FFF' : '#000')
  }
}

const themeOptions = (settings: Settings, overrideMode: PaletteMode, customDesign?: Theme): ThemeOptions => {
  const { skin, mode, direction } = settings
  const defaultPalette = DefaultPalette(mode === 'semi-dark' ? overrideMode : mode, skin)

  //customDesign colors
  const primaryColor = customDesign?.colors_light.primary_color || defaultPalette.primary.main
  const primaryContrastColor = customDesign?.colors_light.primary_contrast_color || defaultPalette.primary.contrastText
  const secondaryColor = customDesign?.colors_light.secondary_color || defaultPalette.secondary.main
  const mainBgColor = customDesign?.colors_light.main_bg_color || defaultPalette.background.default
  const boxBgColor = customDesign?.colors_light.box_bg_color || defaultPalette.background.paper
  const mainBgContrastColor =
    customDesign?.colors_light.main_bg_contrast_color || defaultPalette.customColors.mainBgContrastColor
  const textPrimaryColor = customDesign?.colors_light.main_text_color || defaultPalette.text.primary
  const textSecondaryColor = customDesign?.colors_light.secondary_text_color || defaultPalette.text.secondary
  const cardBgColor = customDesign?.colors_light.box_bg_color || defaultPalette.customColors.cardBg

  const navBgColor = customDesign?.colors_light.nav_bg_color || defaultPalette.customColors.navbarBg
  const textNavColor = customDesign?.colors_light.nav_text_color || defaultPalette.customColors.navbarText

  const userThemeConfig: ThemeOptions = Object.assign({}, UserThemeOptions())

  const mergedThemeConfig: ThemeOptions = deepmerge(
    {
      breakpoints: breakpoints(),
      direction,
      components: overrides(settings),
      palette: palette(mode === 'semi-dark' ? overrideMode : mode, skin),
      ...spacing,
      ...colorWithOpacity,
      shape: { borderRadius: 6 },
      mixins: { toolbar: { minHeight: 64 } },
      shadows: shadows(mode === 'semi-dark' ? overrideMode : mode),
      shadow: ShadowPalette(mode === 'semi-dark' ? overrideMode : mode, skin),
      typography
    },
    userThemeConfig
  )

  const defaultFont = getFont('Public Sans')
  const selectedFontPrimary = getFont(customDesign?.primary_font as FontKey)
  const selectedFontSecondary = getFont(customDesign?.secondary_font as FontKey)

  return deepmerge(mergedThemeConfig, {
    ...(customDesign
      ? {
          typography: {
            fontFamily: customDesign.primary_font,
            h1: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            h2: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            h3: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            h4: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            h5: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            h6: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.primary_bold ? 700 : 500,
              fontStyle: customDesign.primary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.primary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.main_text_color
            },
            subtitle1: {
              fontFamily: selectedFontPrimary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 700 : 500,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            subtitle2: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 700 : 500,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            body1: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 600 : 400,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            body2: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 600 : 400,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            button: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 700 : 500,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.primary_contrast_color
            },
            caption: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 600 : 400,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            overline: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 600 : 400,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            },
            inherit: {
              fontFamily: selectedFontSecondary.style.fontFamily,
              fontWeight: customDesign.secondary_bold ? 700 : 500,
              fontStyle: customDesign.secondary_italic ? 'italic' : 'normal',
              textDecoration: customDesign.secondary_underline ? 'underline' : 'none',
              color: customDesign.colors_light.secondary_text_color
            }
          }
        }
      : {
          typography: {
            fontFamily: defaultFont.style.fontFamily,
            h1: {
              fontFamily: defaultFont.style.fontFamily
            },
            h2: {
              fontFamily: defaultFont.style.fontFamily
            },
            h3: {
              fontFamily: defaultFont.style.fontFamily
            },
            h4: {
              fontFamily: defaultFont.style.fontFamily
            },
            h5: {
              fontFamily: defaultFont.style.fontFamily
            },
            h6: {
              fontFamily: defaultFont.style.fontFamily
            },
            subtitle1: {
              fontFamily: defaultFont.style.fontFamily
            },
            subtitle2: {
              fontFamily: defaultFont.style.fontFamily
            },
            body1: {
              fontFamily: defaultFont.style.fontFamily
            },
            body2: {
              fontFamily: defaultFont.style.fontFamily
            },
            button: {
              fontFamily: defaultFont.style.fontFamily
            },
            caption: {
              fontFamily: defaultFont.style.fontFamily
            },
            overline: {
              fontFamily: defaultFont.style.fontFamily
            },
            inherit: {
              fontFamily: defaultFont.style.fontFamily
            }
          }
        }),
    palette: {
      primary: generateColorShades(primaryColor, primaryContrastColor),
      secondary: generateColorShades(secondaryColor),
      background: {
        default: mainBgColor,
        paper: boxBgColor
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor
      },
      customColors: {
        navbarBg: navBgColor,
        navbarText: textNavColor,
        navbarSecondaryText: chroma(textNavColor).brighten(0.5).hex(),
        mainBgContrastColor: mainBgContrastColor,
        cardBg: cardBgColor
      }
    }
  })
}

export default themeOptions
