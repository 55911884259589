import { createQueryKeys } from '@lukemorales/query-key-factory'

const lessons = createQueryKeys('lessons', {
  all: {
    queryKey: null
  },
  detail: (lessonId: number) => {
    return { queryKey: [lessonId] }
  }
})

export default lessons
