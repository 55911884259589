import { createQueryKeys } from '@lukemorales/query-key-factory'

const navigationItems = createQueryKeys('navigationItems', {
  getAll: {
    queryKey: null
  },
  update: {
    queryKey: null
  },
  delete: {
    queryKey: null
  }
})

export default navigationItems
