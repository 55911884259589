import { ReactNode } from 'react'

import { NavLink } from 'src/@core/layouts/types'

import Can from 'src/layouts/components/acl/Can'

interface Props {
  navLink: NavLink
  children: ReactNode
}

const CanViewNavLink = (props: Props) => {
  // ** Props
  const { children, navLink } = props

  if (navLink?.auth === false || !navLink?.action || !navLink?.aclSubject) {
    return <>{children}</>
  }

  return (
    <Can I={navLink.action} a={navLink.aclSubject}>
      {children}
    </Can>
  )
}

export default CanViewNavLink
