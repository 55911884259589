import { NavLink } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'
import NotificationsNav from '../../../components/navigation/vertical/account-menu/NotificationsNav'
import HelpCenterLink from '../../../components/navigation/vertical/account-menu/HelpCenterNav'
import SettingsNav from '../../../components/navigation/vertical/account-menu/SettingsNav'

import UserNavLink from './UserNavLink'

const accountMenu: NavLink[] = [
  {
    title: 'NAVIGATION.accountMenu.mentortoolsUser',
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    render: UserNavLink
  },
  {
    title: 'NAVIGATION.accountMenu.helpCenter',
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    path: '',
    icon: 'tabler:help',
    render: HelpCenterLink
  },
  {
    title: 'NAVIGATION.accountMenu.settings',
    path: routePaths.settings,
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    icon: 'tabler:settings',
    render: SettingsNav
  },
  {
    title: 'NAVIGATION.accountMenu.notifications',
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.stundentPage,
    icon: 'tabler:bell',
    render: NotificationsNav
  },
  {
    title: 'NAVIGATION.accountMenu.notifications',
    path: routePaths.notifications,
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage,
    icon: 'tabler:bell',
    render: NotificationsNav
  },
  {
    title: 'NAVIGATION.accountMenu.mentortoolsUser',
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.stundentPage,
    render: UserNavLink
  },
  {
    title: 'NAVIGATION.accountMenu.mentortoolsUser',
    subject: subjects.bottomMenu,
    action: permissionActions.manage,
    aclSubject: subjects.rootPage,
    render: UserNavLink
  }
]

export default accountMenu
