import {
  Baloo_2,
  Cormorant,
  IBM_Plex_Sans,
  Josefin_Sans,
  League_Spartan,
  Marcellus,
  Merriweather,
  Montserrat,
  Noto_Sans,
  Open_Sans,
  Oswald,
  Outfit,
  Playfair_Display,
  Poppins,
  PT_Sans,
  Public_Sans,
  Quicksand,
  Roboto_Mono,
  Rubik
} from 'next/font/google'
import localFont from 'next/font/local'

const merriweather = Merriweather({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const openSans = Open_Sans({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['italic', 'normal'] })
const oswald = Oswald({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['normal'] })
const outfit = Outfit({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['normal'] })
const playfairDisplay = Playfair_Display({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const PTSans = PT_Sans({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['italic', 'normal'] })
const quicksand = Quicksand({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['normal'] })
const robotoMono = Roboto_Mono({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const rubik = Rubik({ subsets: ['latin'], weight: ['400', '700'], display: 'swap', style: ['italic', 'normal'] })
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const marcellus = Marcellus({
  subsets: ['latin'],
  weight: ['400'],
  display: 'swap',
  style: ['normal']
})
const baloo2 = Baloo_2({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
  display: 'swap',
  style: ['normal']
})
const josefinSans = Josefin_Sans({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700'],
  display: 'swap',
  style: ['italic', 'normal']
})
const notoSans = Noto_Sans({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  display: 'swap',
  style: ['italic', 'normal']
})
const leagueSpartan = League_Spartan({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  display: 'swap',
  style: ['normal']
})
const cormorant = Cormorant({
  subsets: ['latin'],
  weight: ['400', '600'],
  display: 'swap',
  style: ['normal', 'italic']
})
const ibmPlexSans = IBM_Plex_Sans({
  subsets: ['latin'],
  weight: ['400'],
  display: 'swap',
  style: ['normal']
})

const publicSans = Public_Sans({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  display: 'swap',
  style: ['normal']
})

// available in next/font/google, but missing either bold or italic variant, so they're loaded locally
const anton = localFont({
  src: [
    {
      path: 'Anton/Anton-Regular.ttf',
      weight: '400',
      style: 'normal'
    }
  ],
  variable: '--font-anton',
  display: 'swap'
})

const bebasNeue = localFont({
  src: [
    {
      path: 'Bebas_neue/BebasNeue-Regular.ttf',
      weight: '400',
      style: 'normal'
    }
  ],
  variable: '--font-bebas-neue',
  display: 'swap'
})

const creteRound = localFont({
  src: [
    {
      path: 'Crete_Round/CreteRound-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: 'Crete_Round/CreteRound-Italic.ttf',
      weight: '400',
      style: 'italic'
    }
  ],
  variable: '--font-crete-round',
  display: 'swap'
})

const dosis = localFont({
  src: [
    {
      path: 'Dosis/Dosis-VariableFont_wght.ttf',
      weight: '100 900',
      style: 'normal'
    }
  ],
  variable: '--font-dosis',
  display: 'swap'
})

const enriqueta = localFont({
  src: [
    {
      path: 'Enriqueta/Enriqueta-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: 'Enriqueta/Enriqueta-Medium.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: 'Enriqueta/Enriqueta-SemiBold.ttf',
      weight: '600',
      style: 'normal'
    },
    {
      path: 'Enriqueta/Enriqueta-Bold.ttf',
      weight: '700',
      style: 'normal'
    }
  ],
  variable: '--font-enriqueta',
  display: 'swap'
})

const inter = localFont({
  src: [
    {
      path: 'Inter/Inter-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: 'Inter/Inter-Medium.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: 'Inter/Inter-Bold.ttf',
      weight: '700',
      style: 'normal'
    },
    {
      path: 'Inter/static/Inter-Thin.ttf',
      weight: '100',
      style: 'normal'
    },
    {
      path: 'Inter/static/Inter-ExtraBold.ttf',
      weight: '800',
      style: 'normal'
    },
    {
      path: 'Inter/Inter-VariableFont_slnt_wght.ttf',
      weight: '100 900',
      style: 'normal'
    }
  ],
  variable: '--font-inter',
  display: 'swap'
})

const jaldi = localFont({
  src: [
    {
      path: 'Jaldi/Jaldi-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: 'Jaldi/Jaldi-Bold.ttf',
      weight: '700',
      style: 'normal'
    }
  ],
  variable: '--font-jaldi',
  display: 'swap'
})

const robotoSlab = localFont({
  src: [
    {
      path: 'Roboto_Slab/RobotoSlab-VariableFont_wght.ttf',
      weight: '100 900',
      style: 'normal'
    }
  ],
  variable: '--font-roboto-slab',
  display: 'swap'
})

export const loadedFonts = {
  Anton: anton,
  'Bebas Neue': bebasNeue,
  'Crete Round': creteRound,
  Dosis: dosis,
  Enriqueta: enriqueta,
  Inter: inter,
  Jaldi: jaldi,
  Merriweather: merriweather,
  Montserrat: montserrat,
  'Open Sans': openSans,
  Oswald: oswald,
  Outfit: outfit,
  'Playfair Display': playfairDisplay,
  'PT Sans': PTSans,
  Quicksand: quicksand,
  'Roboto Mono': robotoMono,
  'Roboto Slab': robotoSlab,
  Rubik: rubik,
  Poppins: poppins,
  Marcellus: marcellus,
  'Baloo 2': baloo2,
  'Josefin Sans': josefinSans,
  'Noto Sans': notoSans,
  'League Spartan': leagueSpartan,
  Cormorant: cormorant,
  'IBM Plex Sans': ibmPlexSans,
  'Public Sans': publicSans
} as const

export const getFont = (fontName?: FontKey) => {
  return fontName ? loadedFonts[fontName] : loadedFonts['Public Sans']
}

export type FontKey = keyof typeof loadedFonts

export const AVAILABLE_FONT_FAMILIES = Object.keys(loadedFonts) as FontKey[]
