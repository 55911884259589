import { createQueryKeys } from '@lukemorales/query-key-factory'

const optins = createQueryKeys('optins', {
  allOptins: {
    queryKey: null
  },
  allOptinsCount: {
    queryKey: null
  },
  detail: (optinId: number) => {
    return { queryKey: [optinId] }
  }
})

export default optins
