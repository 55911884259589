import { z } from 'zod'
import * as zod from 'zod'

import { validateTextByEmailVariables } from '../../../utils/settings/emails'
import { EmailVariable } from '../../../types/settings/email/email'
import { addressSchema, createNameSchema } from '../profile'

export const createGeneralEmailsSchema = (emailVariables: EmailVariable[]) => {
  const footerVariables = emailVariables.find(v => v.name === 'contact_info_footer')!.allowed_variables

  return createNameSchema().merge(
    z.object({
      email: z
        .string({ errorMap: () => ({ message: 'email' }) })
        .min(1)
        .email()
        .or(z.literal('')),
      phone_number: z
        .string()
        .optional()
        .refine(value => !value || /^\+?[0-9\s-]{7,15}$/.test(value), 'phone_number')
        .optional(),
      address: addressSchema,
      contact_info_footer: z
        .string()
        .trim()
        .min(1)
        .superRefine((val, ctx) => {
          const invalids = validateTextByEmailVariables(val, footerVariables)
          if (invalids.length) {
            ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
          }
        })
    })
  )
}

export type GeneralEmailsFormData = zod.infer<ReturnType<typeof createGeneralEmailsSchema>>
