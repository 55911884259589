import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'

import useModal from '../../../hooks/useModal'
import Icon from '../../../@core/components/icon'

import { ContactFormModal } from './ContactFormModal'

const TypographyStyled = styled(Typography)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))

export const ContactForm = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'FOOTER' })

  const { close, open, isOpen } = useModal()

  return (
    <>
      <TypographyStyled variant='body1' onClick={open}>
        <Icon icon='bi:chat-fill' fontSize={16} />

        {t('help')}
      </TypographyStyled>
      <ContactFormModal onClose={close} isOpen={isOpen} />
    </>
  )
}
