import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import {
  AppTheme,
  DesignImage,
  DesignProperties,
  DesignSettings,
  FooterCustomLink,
  FooterSetting,
  GeneralDesignSetting,
  PortalDesignBase,
  PortalDesignWithApp,
  PortalToDesignSetting,
  Theme,
  UpdateAppTheme
} from '../types/settings/portal-settings'
import { DeepPartial, Overwrite } from '../types/common/utils'
import { DesignSettingsCode } from '../types/constants-types'

import service from './service'

import { FilterParams, ResponseResult } from 'src/types/common/types'

export type UpdatePortalDesignImagesRequest = FilterParams<keyof DesignImage, unknown, Partial<DesignImage>>
export type UpdatePortalDesignImagesResponse = ResponseResult<number>

export type UpdateGeneralDesignRequest = FilterParams<
  keyof GeneralDesignSetting,
  unknown,
  Partial<GeneralDesignSetting>
>
export type UpdateGeneralDesignResponse = ResponseResult<number>

export type UpdateDesignRequest = FilterParams<keyof DesignSettings, unknown, DeepPartial<DesignSettings>>
export type UpdateDesignResponse = ResponseResult<boolean>

export type UpdateFooterDesignSettingsRequest = FilterParams<
  keyof FooterSetting,
  unknown,
  Overwrite<
    Partial<FooterSetting>,
    {
      custom_links: Omit<FooterCustomLink, 'id' | 'footer_design_settings_id'>[]
    }
  >
>

export type UpdateFooterDesignSettingsResponse = ResponseResult<boolean>

export type GetDesignSettingsCodesRequest = FilterParams
export type GetDesignSettingsCodesResponse = ResponseResult<DesignSettingsCode[]>

export type GetGeneralDesignSettingsRequest = FilterParams<
  keyof GeneralDesignSetting | '*' | '**',
  unknown,
  Partial<GeneralDesignSetting>
>
export type GetGeneralDesignSettingsResponse = ResponseResult<GeneralDesignSetting[]>

export type GetPortalToDesignSettingsRequest = FilterParams<keyof DesignProperties, unknown, Partial<DesignProperties>>
export type GetPortalToDesignSettingsResponse = ResponseResult<PortalToDesignSetting[]>

export type ActivatePortalDesignResponse = ResponseResult<boolean>

export type GetPortalDesignResponse = ResponseResult<PortalDesignWithApp>

export type GetDesignSettingsRequest = FilterParams<keyof DesignSettings | '*' | '**', unknown, Partial<DesignSettings>>
export type GetDesignSettingsResponse = ResponseResult<DesignSettings[]>
export type GetDesignTemplatesResponse = ResponseResult<Theme[]>
export type GetDesignTemplateResponse = ResponseResult<Theme>
export type GetDesignAppTemplatesResponse = ResponseResult<AppTheme[]>

const designService = {
  getGeneralDesignSettings: async (
    requestBody: GetGeneralDesignSettingsRequest
  ): Promise<AxiosResponse<GetGeneralDesignSettingsResponse>> => {
    return service.post(requestUrls.getGeneralDesignSettings, requestBody)
  },
  getPortalToDesignSettings: async (
    requestBody: GetPortalToDesignSettingsRequest
  ): Promise<AxiosResponse<GetPortalToDesignSettingsResponse>> => {
    return service.post(requestUrls.getPortalToDesignSettings, requestBody)
  },
  getSettingsCodes: async (
    requestBody?: GetDesignSettingsCodesRequest
  ): Promise<AxiosResponse<GetDesignSettingsCodesResponse>> => {
    return service.post(requestUrls.getDesignSettingsCodes, requestBody)
  },
  getDesignSettings: async (
    requestBody: GetDesignSettingsRequest
  ): Promise<AxiosResponse<GetDesignSettingsResponse>> => {
    return service.post(requestUrls.getDesignSettings, requestBody)
  },

  getActiveDesignTemplate: async (): Promise<AxiosResponse<GetDesignTemplateResponse>> => {
    return service.get(requestUrls.getActiveDesignTemplate)
  },

  getDesignTemplates: async (): Promise<AxiosResponse<GetDesignTemplatesResponse>> => {
    return service.get(requestUrls.getDesignTemplates)
  },

  getDesignAppTemplates: async (): Promise<AxiosResponse<GetDesignAppTemplatesResponse>> => {
    return service.get(requestUrls.getDesignAppTemplates)
  },

  getDesign: (): Promise<AxiosResponse<GetPortalDesignResponse>> => {
    return service.get(requestUrls.portalDesign)
  },
  activateDesign: (requestBody: PortalDesignBase): Promise<AxiosResponse<ActivatePortalDesignResponse>> => {
    return service.patch(requestUrls.portalDesign, requestBody)
  },
  updatePortalDesignImages: async (
    requestBody: UpdatePortalDesignImagesRequest
  ): Promise<AxiosResponse<UpdatePortalDesignImagesResponse>> => {
    return service.post(requestUrls.updatePortalDesignImages, requestBody)
  },
  updateGeneralDesign: async (
    requestBody: UpdateGeneralDesignRequest
  ): Promise<AxiosResponse<UpdateGeneralDesignResponse>> => {
    return service.post(requestUrls.updateGeneralDesignSettings, requestBody)
  },
  updateDesign: async (
    requestBody: Omit<Theme, 'id' | 'name' | 'is_active' | 'is_preset' | 'is_default'>
  ): Promise<AxiosResponse<UpdateDesignResponse>> => {
    return service.post(requestUrls.webDesignCustom, requestBody)
  },

  updateAppDesign: async (requestBody: UpdateAppTheme): Promise<AxiosResponse<UpdateDesignResponse>> => {
    return service.post(requestUrls.appDesignCustom, requestBody)
  },
  updateFooterDesignSettings: async (
    requestBody: UpdateFooterDesignSettingsRequest
  ): Promise<AxiosResponse<UpdateFooterDesignSettingsResponse>> => {
    return service.post(requestUrls.updateFooterDesignSettings, requestBody)
  }
}

export default designService
