import { useQuery } from '@tanstack/react-query'

import queries from '../../../queries'
import communityService from '../../../service/community-service'
import notificationService from '../../../service/notification-service'

export const useNotificationSettings = () => {
  const {
    data: communityNotificationSettings,
    isLoading: isCommunityNotificationSettingsLoading,
    isError: isCommunityNotificationSettingsError
  } = useQuery({
    ...queries.settings.communityNotifications,
    queryFn: communityService.getNotificationSettings,
    select: response => response.data.result
  })

  const {
    data: notificationSettings,
    isLoading: isNotificationSettingsLoading,
    isError: isNotificationSettingsError
  } = useQuery({
    ...queries.settings.notifications,
    queryFn: notificationService.getNotificationSettings,
    select: response => response.data.result
  })

  return {
    data: communityNotificationSettings &&
      notificationSettings && { communityNotificationSettings, notificationSettings },
    isLoading: isCommunityNotificationSettingsLoading || isNotificationSettingsLoading,
    isError: isCommunityNotificationSettingsError || isNotificationSettingsError
  }
}
