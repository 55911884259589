import { createQueryKeys } from '@lukemorales/query-key-factory'

const contentCreators = createQueryKeys('contentCreators', {
  allContentCreators: {
    queryKey: null
  },
  allContentCreatorsCount: {
    queryKey: null
  },
  portalEntity: (ids: number[]) => {
    return { queryKey: [ids] }
  }
})

export default contentCreators
