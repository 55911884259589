import { ChangeEvent, useState, useEffect } from 'react'

import getFileFromDataUrl from 'src/utils/getFileFromDataUrl'
import { getImageUrl } from 'src/utils/getImageUrl'

import useToast from '../useToast'
import { useImageDelete } from '../files/useImageDelete'
import { useImageUpload } from '../files/useImageUpload'

type UseImageUploaderProps = {
  imageId: string | null
  formSetterFn?: (id: string) => void
}

const useImageUploader = ({ imageId, formSetterFn }: UseImageUploaderProps) => {
  const { notify } = useToast()

  const [uploadedImageId, setUploadedImageId] = useState<string | null>(null)
  const [source, setSource] = useState<string | null>(null)
  const [cropSource, setCropSource] = useState<string | null>(null)

  useEffect(() => {
    if (imageId) {
      const imageUrl = getImageUrl(imageId)
      setSource(imageUrl)
      setCropSource(imageUrl)
      setUploadedImageId(imageId)
    }
  }, [imageId])

  const {
    progress,
    isPending: isImageUploading,
    uploadImage,
    onChangeProgress
  } = useImageUpload(fileId => {
    setUploadedImageId(fileId)
    formSetterFn?.(fileId)
  })

  const { deleteImage, isPending: isImageDeleting } = useImageDelete(() => {
    setSource(null)
    setCropSource(null)
    setUploadedImageId(null)
  })

  const onUpload = async (imageToUpload: File) => {
    onChangeProgress(0)
    if (!imageToUpload) return
    if (!imageToUpload.type.startsWith('image')) return

    const formData = new FormData()
    formData.append('payload', imageToUpload)

    const fileReader = new FileReader()

    fileReader.addEventListener('load', () => typeof fileReader.result === 'string' && setCropSource(fileReader.result))
    fileReader.readAsDataURL(imageToUpload)

    return await uploadImage(formData)
  }

  const onSelectImage = (callback?: () => void) => async (e: ChangeEvent<HTMLInputElement>) => {
    const imageToUpload = e.target.files?.[0]
    if (imageToUpload) await onUpload(imageToUpload)
    callback?.()
  }

  const onApplyCrop = (callback?: () => void) => async (imageUrl: string) => {
    onChangeProgress(0)

    const cropApplyErrorCallback = () => {
      notify('IMAGE.upload', { type: 'error' })
    }
    callback?.()
    const image = await getFileFromDataUrl(imageUrl, cropApplyErrorCallback)
    if (!image) return

    const formData = new FormData()
    formData.append('payload', image)

    setSource(imageUrl)
    await uploadImage(formData)
  }

  const onChangeCropSource = (url: string) => setCropSource(url)
  const onDeleteUploadedImage = async () => {
    if (uploadedImageId) {
      formSetterFn?.('')
      await deleteImage(uploadedImageId)
    }
  }
  const onClearCropSource = (callback?: () => void) => () => {
    setCropSource(null)
    callback?.()
  }

  return {
    source,
    progress,
    cropSource,
    isImageUploading,
    isImageDeleting,
    onUpload,
    onDeleteUploadedImage,
    onChangeCropSource,
    onApplyCrop,
    onSelectImage,
    onClearCropSource
  }
}

export default useImageUploader
