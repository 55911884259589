import { useMemo } from 'react'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'

import useToast from 'src/hooks/useToast'

import { createProfileValidationSchema, ProfileFormData } from '../../../schemas/settings/profile'
import { Member } from '../../../types/members'
import { useUpdateMember } from '../../members/useUpdateMember'
import queries from '../../../queries'

function setupDefaultValues(profileData: Member, customNameEnabled: boolean): ProfileFormData {
  return {
    ...profileData,
    address: { ...profileData.address },
    nickname:
      profileData.nickname || (customNameEnabled ? profileData.first_name + ' ' + profileData.last_name : undefined),
    avatar_id: profileData.avatar_id || undefined
  }
}

export const useProfileSettingsForm = (portalMember: Member, customNameEnabled = false) => {
  const queryClient = useQueryClient()
  const { notify } = useToast()
  const profileValidation = useMemo(() => createProfileValidationSchema(customNameEnabled), [customNameEnabled])

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty }
  } = useForm<ProfileFormData>({
    resolver: zodResolver(profileValidation),
    defaultValues: setupDefaultValues(portalMember, customNameEnabled),
    mode: 'all'
  })

  const { updateMemberAsync } = useUpdateMember()

  const onSubmit = async (formData: ProfileFormData) => {
    try {
      const values = {
        ...formData,
        portal_member_id: String(portalMember.id)
      }

      await updateMemberAsync(values)
      reset(undefined, {
        keepDirtyValues: true,
        keepValues: true
      })
      queryClient.invalidateQueries({ queryKey: [queries.members.detail(portalMember.id)] })
    } catch (error) {
      notify('MEMBER.update', { type: 'error' })
    }
  }

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    setValue,
    watch,
    errors,
    isSubmitting,
    isValid,
    isDirty
  }
}
