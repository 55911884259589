import { createQueryKeys } from '@lukemorales/query-key-factory'

const aiVideoCreation = createQueryKeys('aiVideoCreation', {
  getAIVideoGenerationAccess: {
    queryKey: null
  },

  getAvatarList: {
    queryKey: null
  },

  getVoiceList: {
    queryKey: null
  },

  getGenerationSettings: {
    queryKey: null
  },

  getVideoPresentationList: {
    queryKey: null
  },

  getVideoPresentationCount: {
    queryKey: null
  }
})

export default aiVideoCreation
