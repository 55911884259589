import { z } from 'zod'

export const zodRegistrationFormSchema = (requireTosApproval?: boolean) =>
  z
    .object({
      email: z.string().email('email'),
      firstName: z.string().min(1, 'field_required'),
      lastName: z.string().min(1, 'field_required'),
      password: z.string().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/, 'password_combination_required'),
      confirmPassword: z.string().optional(),
      termsChecked: requireTosApproval
        ? z.literal(true, {
            errorMap: () => ({
              message: 'terms_checked'
            })
          })
        : z.boolean().optional()
    })
    .refine(data => data.password === data.confirmPassword, {
      message: 'confirm_password',
      path: ['confirmPassword']
    })

export type RegistrationFormType = z.infer<ReturnType<typeof zodRegistrationFormSchema>>
