const IMAGE_CACHING_WORKER_URL = '/app/_next/static/service-worker.js'

export default function registerServiceWorker(): void {
  if (!('serviceWorker' in navigator)) {
    return
  }

  window.addEventListener('load', () => {
    registerImageCachingServiceWorker(IMAGE_CACHING_WORKER_URL)
  })
}

async function registerImageCachingServiceWorker(workerURL: string): Promise<void> {
  const registrations = await navigator.serviceWorker.getRegistrations()
  const expectedServiceWorkerURL = new URL(workerURL, window.location.origin).href

  const isRegistered = registrations.some(registration => registration.active?.scriptURL === expectedServiceWorkerURL)

  if (!isRegistered) {
    await navigator.serviceWorker.register(workerURL)
  }
}
