import { createQueryKeys } from '@lukemorales/query-key-factory'

const calendar = createQueryKeys('calendar', {
  all: ({ startsAt, endsAt }: { startsAt: number; endsAt: number }) => {
    return { queryKey: [startsAt, endsAt] }
  },
  timestamp: {
    queryKey: null
  },
  frequencies: {
    queryKey: null
  },
  timezones: {
    queryKey: null
  },
  access: {
    queryKey: null
  }
})

export default calendar
