import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import queries from '../../queries'
import notificationService from '../../service/notification-service'
import { useAuth } from '../useAuth'

export const useNotifications = (limit?: number, enabled = false) => {
  const { user } = useAuth()

  const [initiallyEnabled, setInitiallyEnabled] = useState(false)

  useEffect(() => setInitiallyEnabled(enabled), [enabled])

  const {
    isLoading,
    isError,
    data: notifications = []
  } = useQuery({
    ...queries.notifications.all(limit, user?.id),
    queryFn: () => notificationService.getNotifications({ limit }),
    select: response => response.data.result,
    enabled: initiallyEnabled,
    staleTime: 0
  })

  return {
    isLoading,
    isError,
    notifications
  }
}
