import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import queries from 'src/queries'

import memberService from '../../service/member-service'
import { LOCALES } from '../../utils'
import contentCreatorsService from '../../service/content-creators-service'
import { getUserRole, UserRolesType } from '../../utils/getUserRole'

import { Member, MemberKeys } from 'src/types/members'
import { FilterParams } from 'src/types/common/types'

export type UseMemberProps = {
  id: number
  filters: FilterParams<MemberKeys, number>
}

const useMember = ({ id, filters }: UseMemberProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.COMMON' })
  const role = getUserRole()

  const { data: portalEntity } = useQuery({
    queryKey: [queries.contentCreators.portalEntity([id])],
    queryFn: () =>
      contentCreatorsService.getPortalEntity({
        columns: ['id', 'hostname', 'custom_domain'],
        filter_by: [{ attribute: 'id', operator: 'in', value: [id] }]
      }),
    select: response => response.data.result[0],
    enabled: role === UserRolesType.ADMIN
  })

  const { data, isError, isLoading } = useQuery({
    queryKey: [queries.members.detail(id)],
    queryFn: () =>
      memberService.getOne({
        ...filters,
        filter_by: [{ attribute: 'id', operator: '==', value: id }]
      }),
    select: (response): Member => {
      const member = response.data.result?.[0]

      if (role === UserRolesType.HIDDEN_CUSTOMER) {
        member.first_name = t('preview_mode_first_name')
        member.last_name = t('preview_mode_last_name')
        member.nickname = t('preview_mode_nickname')
      }

      return {
        ...member,
        registered_at: new Date(member?.registered_at).toLocaleString(LOCALES, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }
    }
  })

  return {
    data,
    portalEntity,
    isError,
    isLoading
  }
}

export default useMember
