export type NotificationSettings = {
  user_id: number
  id: number
  receive_email_notifications: boolean
  receive_in_app_notifications: boolean
}

export const notificationSettingsType = ['respect_community', 'posts_comments', 'posts', 'interaction'] as const

export type NotificationSettingsType = (typeof notificationSettingsType)[number]

export const usedNotificationTypes = ['posts_comments', 'posts', 'interaction'] as const

export type CommunityNotificationSettings = {
  id: number | null
  user_id: number
  notification_settings_type: NotificationSettingsType
}
