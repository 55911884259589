import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import { PortalEntity, PortalEntityKeys } from '../types/content-creators'
import { LoginResponse } from '../context/types'
import { Member, MemberKeys } from '../types/members'

import service from './service'

import { FilterParams, ResponseResult } from 'src/types/common/types'

export type GetAllContentCreatorsRequest = FilterParams<MemberKeys>
export type GetAllContentCreatorsResponse = ResponseResult<Member[]>
export type GetCountContentCreatorsRequest = FilterParams<MemberKeys>
export type GetCountContentCreatorsResponse = ResponseResult<number>
export type GetPortalEntityRequest = FilterParams<PortalEntityKeys, number[]>
export type GetPortalEntityResponse = ResponseResult<PortalEntity[]>
export type GetPortalTypeResponse = ResponseResult<{ name: string; id: number }[]>
export type LoginAsCCRequest = { portal_id: number }
export type GetGendersRequest = FilterParams
export type GetGendersResponse = ResponseResult<{ name: string; id: number }[]>
export type GetNicheRequest = FilterParams
export type GetNicheResponse = ResponseResult<{ name: string; id: number }[]>
export type UpsertPortalAffiliateRequest = FilterParams<unknown, number, { portal_id: number; digistore_id: string }>
export type UpsertPortalAffiliateResponse = ResponseResult<boolean>
export type UpdatePortalMemberRequest = FilterParams<
  unknown,
  number,
  { id: number; gender_id?: number; niche_id?: number }
>
export type UpdatePortalMemberResponse = ResponseResult<number>

const contentCreatorsService = {
  getAll: async (requestBody: GetAllContentCreatorsRequest): Promise<AxiosResponse<GetAllContentCreatorsResponse>> => {
    return service.post(requestUrls.getMembers, requestBody)
  },
  getCount: async (
    requestBody: GetCountContentCreatorsRequest
  ): Promise<AxiosResponse<GetCountContentCreatorsResponse>> => {
    return service.post(requestUrls.getMembersCount, requestBody)
  },
  getPortalEntity: async (requestBody: GetPortalEntityRequest): Promise<AxiosResponse<GetPortalEntityResponse>> => {
    return service.post(requestUrls.getPortalEntity, requestBody)
  },
  getPortalType: async (): Promise<AxiosResponse<GetPortalTypeResponse>> => {
    return service.get(requestUrls.getPortalType)
  },
  loginAsCC: async (requestBody: LoginAsCCRequest): Promise<LoginResponse> => {
    return service.post(requestUrls.loginAsCC, requestBody)
  },
  getGenders: async (requestBody: GetGendersRequest): Promise<AxiosResponse<GetGendersResponse>> => {
    return service.post(requestUrls.getGenders, requestBody)
  },
  getNiche: async (requestBody: GetNicheRequest): Promise<AxiosResponse<GetNicheResponse>> => {
    return service.post(requestUrls.getGenders, requestBody)
  },
  upsertPortalAffiliate: async (
    requestBody: UpsertPortalAffiliateRequest
  ): Promise<AxiosResponse<UpsertPortalAffiliateResponse>> => {
    return service.post(requestUrls.upsertPortalAffiliate, requestBody)
  },
  updatePortalMember: async (
    requestBody: UpdatePortalMemberRequest
  ): Promise<AxiosResponse<UpdatePortalMemberResponse>> => {
    return service.post(requestUrls.updatePortalMember, requestBody)
  }
}

export default contentCreatorsService
