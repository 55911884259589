import { useState } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  Checkbox,
  InputAdornment,
  Stack,
  useTheme,
  FormControlLabel,
  styled,
  IconButton,
  Typography
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'

import CustomTextField from 'src/@core/components/mui/text-field'
import Icon from 'src/@core/components/icon'

import { getGDPRLinks } from 'src/utils/settings/portal-settings/utils'

import { RegistrationFormType } from 'src/schemas/auth/registration.validation'

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.primary.main} !important`
}))

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.secondary
  }
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.154,
  margin: theme.spacing(1, 0, 0),
  fontSize: theme.typography.body2.fontSize
}))

type RegistrationFormProps = {
  isLoading: boolean
  requiredChecks: { showTermsPage?: boolean; requireTosApproval?: boolean }
  form: UseFormReturn<RegistrationFormType>
  onSubmit: (data: RegistrationFormType) => void
  isLoginDialog?: boolean
  backToLogin?: () => void
}

export const RegistrationForm = ({
  isLoading,
  requiredChecks: { showTermsPage, requireTosApproval },
  form: { control, formState, handleSubmit },
  onSubmit,
  isLoginDialog = false,
  backToLogin
}: RegistrationFormProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.REGISTER' })
  const { t: tGlobal } = useTranslation('translation')

  const router = useRouter()

  const theme = useTheme()

  const toggleShowPassword = () => setShowPassword(s => !s)
  const toggleShowConfirmPassword = () => setShowConfirmPassword(s => !s)

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={theme.spacing(4)}>
        <Controller
          name={'email'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              required
              autoFocus
              fullWidth
              label={t('FORM.email_label')}
              placeholder={t('FORM.email_placeholder')}
              value={value}
              onChange={onChange}
              error={Boolean(formState.errors.email)}
              helperText={formState.errors.email && t(`VALIDATIONS.${formState.errors.email.message}`)}
            />
          )}
        />

        <Controller
          name={'firstName'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              required
              fullWidth
              label={t('FORM.first_name_label')}
              placeholder={t('FORM.first_name_placeholder')}
              value={value}
              onChange={onChange}
              error={Boolean(formState.errors.firstName)}
              helperText={formState.errors.firstName && t(`VALIDATIONS.${formState.errors.firstName.message}`)}
            />
          )}
        />

        <Controller
          name={'lastName'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              required
              fullWidth
              label={t('FORM.last_name_label')}
              placeholder={t('FORM.last_name_placeholder')}
              value={value}
              onChange={onChange}
              error={Boolean(formState.errors.lastName)}
              helperText={formState.errors.lastName && t(`VALIDATIONS.${formState.errors.lastName.message}`)}
            />
          )}
        />

        <Controller
          name={'password'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              fullWidth
              required
              label={t('FORM.password_label')}
              placeholder={t('FORM.password_placeholder')}
              value={value}
              onChange={onChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onMouseDown={e => e.preventDefault()} onClick={toggleShowPassword}>
                      <Icon width={24} icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(formState.errors.password)}
              helperText={formState.errors.password && t(`VALIDATIONS.${formState.errors.password.message}`)}
            />
          )}
        />

        <Controller
          name={'confirmPassword'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              fullWidth
              required
              label={t('FORM.confirm_password_label')}
              placeholder={t('FORM.confirm_password_placeholder')}
              value={value}
              onChange={onChange}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' onMouseDown={e => e.preventDefault()} onClick={toggleShowConfirmPassword}>
                      <Icon width={24} icon={showConfirmPassword ? 'tabler:eye' : 'tabler:eye-off'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(formState.errors.confirmPassword)}
              helperText={
                formState.errors.confirmPassword && t(`VALIDATIONS.${formState.errors.confirmPassword.message}`)
              }
            />
          )}
        />

        {requireTosApproval && (
          <Stack>
            <Controller
              name='termsChecked'
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <StyledFormControlLabel
                  onChange={onChange}
                  checked={value}
                  control={<Checkbox />}
                  label={
                    <Stack
                      direction='row'
                      alignItems='center'
                      flexWrap='wrap'
                      justifyContent='center'
                      gap={theme.spacing(1)}
                    >
                      <Typography sx={{ color: 'text.secondary' }}>{t('i_agree')}</Typography>

                      <Typography
                        component={LinkStyled}
                        href={`${window.location.origin}/terms-conditions`}
                        onClick={e => e.preventDefault()}
                      >
                        {t('privacy_policy_terms')}
                      </Typography>
                    </Stack>
                  }
                />
              )}
            />

            {Boolean(formState.errors.termsChecked) && (
              <StyledTypography color={theme.palette.error.main}>
                {formState.errors.termsChecked && t(`VALIDATIONS.${formState.errors.termsChecked.message}`)}
              </StyledTypography>
            )}
          </Stack>
        )}

        <LoadingButton fullWidth loading={isLoading} disabled={isLoading} type='submit' variant='contained'>
          {t('BUTTON.register')}
        </LoadingButton>

        <Stack alignItems='center' flexWrap='wrap' direction='row' justifyContent='center' gap={theme.spacing(2)}>
          <Typography sx={{ color: 'text.secondary' }}>{t('registration_title')}</Typography>

          {
            isLoginDialog ? <Typography sx={{ cursor: 'pointer' }} color={'primary.main'} onClick={backToLogin}>
              {t('log_in_instead')}
            </Typography> : <Typography component={LinkStyled} href='/login'>
              {t('log_in_instead')}
            </Typography>
          }
        </Stack>

        <Stack direction='row' gap={theme.spacing(4)} justifyContent={'center'}>
          {getGDPRLinks(showTermsPage, router.basePath).map(({ title, url }) => (
            <Typography key={title} component={'a'} href={url} variant='body1' sx={{ textDecoration: 'none' }}>
              {tGlobal(title)}
            </Typography>
          ))}
        </Stack>
      </Stack>

      {/* TODO: the auth with provider is not yet developed */}
      {/* <Divider
                sx={{
                  color: 'text.disabled',
                  '& .MuiDivider-wrapper': { px: 6 },
                  fontSize: theme.typography.body2.fontSize,
                  my: theme => `${theme.spacing(6)} !important`
                }}
              >
                or
              </Divider> */}
      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton href='/' component={Link} sx={{ color: '#497ce2' }} onClick={e => e.preventDefault()}>
                  <Icon icon='mdi:facebook' />
                </IconButton>
                <IconButton href='/' component={Link} sx={{ color: '#1da1f2' }} onClick={e => e.preventDefault()}>
                  <Icon icon='mdi:twitter' />
                </IconButton>
                <IconButton
                  href='/'
                  component={Link}
                  onClick={e => e.preventDefault()}
                  sx={{ color: theme => (theme.palette.mode === 'light' ? '#272727' : 'grey.300') }}
                >
                  <Icon icon='mdi:github' />
                </IconButton>
                <IconButton href='/' component={Link} sx={{ color: '#db4437' }} onClick={e => e.preventDefault()}>
                  <Icon icon='mdi:google' />
                </IconButton>
              </Box> */}
    </form>
  )
}
