import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

import { useTranslation } from 'react-i18next'

import Spinner from '../../../../@core/components/spinner'
import { ProfileSettingsForm } from '../forms'
import useMember from '../../../../hooks/members/useMember'
import { useAuth } from '../../../../hooks/useAuth'
import { profileFilters } from '../config'

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(4.5)} 0 ${theme.colorWithOpacity(theme.palette.grey[500], 1)}`
}))

export const ProfileSettings = () => {
  const auth = useAuth()

  const { data: member, isLoading, isError } = useMember({ id: auth.user!.id, filters: profileFilters })
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS' })

  if (isLoading)
    return (
      <Container>
        <Spinner />
      </Container>
    )

  if (isError || !member)
    return (
      <Container>
        <Typography>{t('error')}</Typography>
      </Container>
    )

  return <ProfileSettingsForm member={member} />
}
