/**
 *  Set Home URL based on User Roles
 */

import { routePaths } from 'src/navigation/paths'

//TODO: add 3 more pages for cc, root user, and user
const routerByRole: { [key: string]: string } = {
  '1': routePaths.dashboard, // cc
  '2': routePaths.customerDashboard, //user
  '4': `${routePaths.customerDashboard}?preview=true`, // preview customer
  '5': routePaths.courses // root
}

const getHomeRoute = (role: string) => {
  return routerByRole[role]
}

export default getHomeRoute
