import { z } from 'zod'

// (?!.*\s{2,}) - disallow two or more consecutive spaces
// /[\p{L} .\-]+$/u - allow any Unicode character, plus '.', '-' and space
export const nameRegex = /^(?!.*\s{2,})[\p{L} .\-]+$/u

export const NameRequiredSchema = z.object({
  first_name: z.string().min(1, 'required').max(50, 'first_name').regex(nameRegex, 'first_name'),
  last_name: z.string().min(1, 'required').max(50, 'last_name').regex(nameRegex, 'last_name')
})

export const createNameSchema = (isRequired = false) =>
  isRequired
    ? NameRequiredSchema
    : z.object({
        first_name: z
          .string({ errorMap: () => ({ message: 'first_name' }) })
          .max(50)
          .regex(nameRegex)
          .or(z.literal(''))
          .optional(),
        last_name: z
          .string({ errorMap: () => ({ message: 'last_name' }) })
          .max(50)
          .regex(nameRegex)
          .or(z.literal(''))
          .optional()
      })
