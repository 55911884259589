import { useCallback } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import notificationService from '../../service/notification-service'
import useToast from '../useToast'
import queries from '../../queries'
import { useAuth } from '../useAuth'

export const useMarkAsRead = (limit?: number, showToast = true) => {
  const { user } = useAuth()
  const { notify } = useToast()
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: (unreadIds: number[]) => notificationService.markAsRead({ notification_ids: unreadIds }),
    onSuccess: () => {
      queryClient.invalidateQueries(queries.notifications.all(limit, user?.id))
      showToast && notify('NOTIFICATIONS.mark_as_read', { type: 'success' })
    },
    onError: () => notify('NOTIFICATIONS.mark_as_read', { type: 'error' })
  })

  const markAsRead = useCallback((unreadIds: number[]) => mutate(unreadIds), [mutate])

  return {
    markAsRead,
    isPending
  }
}
