export const idToLanguageMap: Record<number, string> = {
  1: 'en', // English
  2: 'de', // German
  3: 'sk', // Slovak
  4: 'hu', // Hungarian
  5: 'mk', // Macedonian
  6: 'cz' // Czech
}

export const languageToIdMap: Record<string, number> = {
  en: 1,
  de: 2,
  sk: 3,
  hu: 4,
  mk: 5,
  cz: 6
}
