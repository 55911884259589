import { createQueryKeys } from '@lukemorales/query-key-factory'

const landings = createQueryKeys('landings', {
  allLandings: {
    queryKey: null
  },
  allNewLandings: {
    queryKey: null
  },
  allLandingsCount: {
    queryKey: null
  },
  allNewLandingsCount: {
    queryKey: null
  },
  landingByTitle: {
    queryKey: null
  },

  detail: (landingId?: number) => {
    return { queryKey: [landingId] }
  },

  settings: (landingId: number) => {
    return { queryKey: [landingId] }
  }
})

export default landings
