import { LottieAnimation, Shape } from 'src/types/lottie'

export const modifyAnimationColors = (data: any, newColor: string): LottieAnimation => {
  const hexToNormalizedRgb = (hex: string): number[] => [
    parseInt(hex.slice(1, 3), 16) / 255,
    parseInt(hex.slice(3, 5), 16) / 255,
    parseInt(hex.slice(5, 7), 16) / 255
  ]

  const rgbColor = hexToNormalizedRgb(newColor)

  const updateColors = (shapes: Shape[]) => {
    shapes.forEach(shape => {
      if (shape.mn === 'ADBE Vector Graphic - Stroke loader' && shape.c) {
        shape.c.k = rgbColor
      }

      if (shape.it) updateColors(shape.it)
    })
  }

  const clonedData: LottieAnimation = JSON.parse(JSON.stringify(data)) // Deep clone to prevent mutation
  clonedData.layers.forEach(layer => {
    if (layer.shapes) updateColors(layer.shapes)
  })
  clonedData.assets?.forEach(asset => {
    asset.layers?.forEach(layer => {
      if (layer.shapes) updateColors(layer.shapes)
    })
  })

  return clonedData
}
