import { useRouter } from 'next/router'

import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'
import { LayoutProps } from 'src/@core/layouts/types'
import Customizer from 'src/@core/components/customizer'
import ScrollToTop from 'src/@core/components/scroll-to-top'

import { useAuth } from 'src/hooks/useAuth'
import { useUserPortalInfo } from 'src/hooks/portal/useUserPortalInfo'

import { routePaths } from 'src/navigation/paths'
import Can from 'src/layouts/components/acl/Can'
import { subjects } from 'src/navigation/vertical/subjects'

import { Footer, WelcomeBox } from 'src/components/customer/common'
import { PreviewModeBar } from 'src/components/common/PreviewModeBar'
import { TrialBar } from 'src/components/common/TrialBar'
import TrialEndedModal from 'src/components/common/modals/TrialEndedModal'

import { UserRolesType } from 'src/utils/getUserRole'

import themeConfig from 'src/configs/themeConfig'
import { permissionActions } from 'src/configs/permissionActions'

import { hexToRGBA } from '../utils/hex-to-rgba'

import Navigation from './components/horizontal/navigation'

const HorizontalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  ...(themeConfig.horizontalMenuAnimation && { overflow: 'clip' })
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const HorizontalLayout = (props: LayoutProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isTrialEnded, isTrial, isVerified } = useUserPortalInfo()

  // ** Props
  const { hidden, children, settings, scrollToTop, contentHeightFixed, horizontalLayoutProps } = props

  const router = useRouter()

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings
  const appBarProps = horizontalLayoutProps?.appBar?.componentProps
  const userNavMenuContent = horizontalLayoutProps?.navMenu?.content

  let userAppBarStyle = {}
  if (appBarProps && appBarProps.sx) {
    userAppBarStyle = appBarProps.sx
  }
  const userAppBarProps = Object.assign({}, appBarProps)
  delete userAppBarProps.sx

  return (
    <HorizontalLayoutWrapper className='layout-wrapper'>
      <MainContentWrapper className='layout-content-wrapper' sx={{ ...(contentHeightFixed && { maxHeight: '100vh' }) }}>
        {/* Navbar (or AppBar) and Navigation Menu Wrapper */}
        <AppBar
          color='default'
          elevation={skin === 'bordered' ? 0 : 2}
          className='layout-navbar-and-nav-container'
          position={appBar === 'fixed' ? 'sticky' : 'static'}
          sx={{
            alignItems: 'center',
            color: 'text.primary',
            justifyContent: 'center',
            ...(appBar === 'static' && { zIndex: 13 }),
            transition: 'border-bottom 0.2s ease-in-out',
            ...(appBarBlur && { backdropFilter: 'blur(6px)' }),
            backgroundColor: theme => hexToRGBA(theme.palette.background.paper, appBarBlur ? 0.95 : 1),
            ...(skin === 'bordered' && { borderBottom: theme => `1px solid ${theme.palette.divider}` }),
            ...userAppBarStyle
          }}
          {...userAppBarProps}
        >
          <Can I={permissionActions.read} a={subjects.ccPage}>
            {(!isVerified || isTrial) && !isTrialEnded && <TrialBar />}
            <TrialEndedModal isOpen={isTrialEnded} onClose={close} />
          </Can>
          <Can I={permissionActions.read} a={subjects.preview}>
            <PreviewModeBar />
          </Can>
          {/* Navbar / AppBar */}
          {/*<Box*/}
          {/*  className='layout-navbar'*/}
          {/*  sx={{*/}
          {/*    width: '100%',*/}
          {/*    ...(navHidden ? {} : { borderBottom: theme => `1px solid ${theme.palette.divider}` })*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Toolbar*/}
          {/*    className='navbar-content-container'*/}
          {/*    sx={{*/}
          {/*      mx: 'auto',*/}
          {/*      ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),*/}
          {/*      minHeight: theme => `${(theme.mixins.toolbar.minHeight as number) - 2}px !important`*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <AppBarContent*/}
          {/*      {...props}*/}
          {/*      hidden={hidden}*/}
          {/*      settings={settings}*/}
          {/*      saveSettings={saveSettings}*/}
          {/*      appBarContent={horizontalLayoutProps?.appBar?.content}*/}
          {/*      appBarBranding={horizontalLayoutProps?.appBar?.branding}*/}
          {/*    />*/}
          {/*  </Toolbar>*/}
          {/*</Box>*/}
          {/* Navigation Menu */}
          {navHidden ? null : (
            <Box className='layout-horizontal-nav' sx={{ width: '100%', ...horizontalLayoutProps?.navMenu?.sx }}>
              <Toolbar
                className='horizontal-nav-content-container'
                sx={{
                  mx: 'auto',
                  backgroundColor: 'customColors.navbarBg',
                  ...(contentWidth === 'boxed' && { '@media (min-width:1440px)': { maxWidth: 1440 } }),
                  minHeight: theme =>
                    `${(theme.mixins.toolbar.minHeight as number) - 4 - (skin === 'bordered' ? 1 : 0)}px !important`
                }}
              >
                {(userNavMenuContent && userNavMenuContent(props)) || (
                  <Navigation
                    {...props}
                    horizontalNavItems={
                      (horizontalLayoutProps as NonNullable<LayoutProps['horizontalLayoutProps']>).navMenu?.navItems
                    }
                  />
                )}
              </Toolbar>
            </Box>
          )}
        </AppBar>

        {[routePaths.customerDashboard].includes(router.pathname) && <WelcomeBox />}

        {/* Content */}
        <ContentWrapper
          className='layout-page-content'
          sx={{
            ...(contentHeightFixed && { display: 'flex', overflow: 'hidden' }),
            ...(contentWidth === 'boxed' && {
              mx: 'auto',
              '@media (min-width:1440px)': { maxWidth: 1440 },
              '@media (min-width:1200px)': { maxWidth: '100%' }
            })
          }}
        >
          {children}
        </ContentWrapper>
        {/* Footer */}
        {/*<Footer {...props} footerStyles={footerProps?.sx} footerContent={footerProps?.content} />*/}
        {/* Customizer */}

        {user?.role === UserRolesType.CUSTOMER ||
        user?.role === UserRolesType.HIDDEN_CUSTOMER ||
        themeConfig.disableCustomizer ||
        hidden ? null : (
          <Customizer />
        )}
        {/* Scroll to top button */}
        {scrollToTop ? (
          scrollToTop(props)
        ) : (
          <ScrollToTop className='mui-fixed'>
            <Fab color='primary' size='small' aria-label={t('extracted.scroll_back_to_top')}>
              <Icon icon='tabler:arrow-up' />
            </Fab>
          </ScrollToTop>
        )}

        {[routePaths.customerDashboard, routePaths.customerCourseDetails].includes(router.pathname) && <Footer />}
      </MainContentWrapper>
    </HorizontalLayoutWrapper>
  )
}

export default HorizontalLayout
