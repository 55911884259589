import { ReactNode } from 'react'

import { NavGroup, NavLink } from 'src/@core/layouts/types'

import Can from 'src/layouts/components/acl/Can'

interface Props {
  navGroup: NavGroup
  children: ReactNode
}

// Function to filter visible children based on permissions
const filterVisibleChildren = (arr: NavGroup[]): NavGroup[] => {
  return arr.filter((item: NavGroup) => {
    if (item.children) {
      // Recursively filter children
      item.children = filterVisibleChildren(item.children as NavGroup[])

      // Keep the item only if it has visible children
      return item.children.length > 0
    } else {
      // Check if the user has permission to view this item
      return !!item.action && !!item.aclSubject
    }
  })
}

const CanViewNavGroup = (props: Props) => {
  // ** Props: children elements and navigation group
  const { children, navGroup } = props

  // Render the group if it's not restricted by authentication or permissions
  if (navGroup?.auth === false) {
    return <>{children}</>
  }

  if (navGroup) {
    const visibleChildren = filterVisibleChildren((navGroup.children as NavLink[]) || [])

    return (
      <Can I={navGroup.action} a={navGroup.aclSubject}>
        {visibleChildren.length > 0 ? children : null}
      </Can>
    )
  }

  return null
}

export default CanViewNavGroup
