import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import unsplashService from 'src/service/unsplash-service'
import queries from 'src/queries'

const useGetStockImageDetails = () => {
  const [selectedId, setSelectedId] = useState<string>('')

  const { data: imageDetails } = useQuery({
    ...queries.images.detail(selectedId),
    queryFn: () => unsplashService.getImage({ id: selectedId }),
    enabled: !!selectedId,
    select: response => response.data
  })

  return {
    imageDetails,
    setSelectedId
  }
}

export default useGetStockImageDetails
