import { createQueryKeys } from '@lukemorales/query-key-factory'

const threadMessagesReplies = createQueryKeys('threadMessagesReplies', {
  all: (treeId: number, threadId: number) => ({
    queryKey: [{ treeId }, { threadId }]
  }),

  allCount: {
    queryKey: null
  }
})

export default threadMessagesReplies
