import { createQueryKeys } from '@lukemorales/query-key-factory'

const academyCustomization = createQueryKeys('academyCustomization', {
  getWelcomeBlock: {
    queryKey: null
  },
  getCourseList: {
    queryKey: null
  },
  getCoverImage: {
    queryKey: null
  }
})

export default academyCustomization
