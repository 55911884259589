import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'

import { Icon } from '@iconify/react'

import { FolderType } from 'src/types/files/folder'

type RenderMenuProps = {
  data: RenderFolderType[]
  isCollapsed: number[]
  setIsCollapsed: (collapsed: number[]) => void
  setActiveFolder: (FolderType?: FolderType) => void
  isActiveFolder?: FolderType
}

type RenderFolderType = FolderType & {
  children: RenderFolderType[]
}

const RenderMenu = ({ data, isCollapsed, setIsCollapsed, isActiveFolder, setActiveFolder }: RenderMenuProps) => {
  const theme = useTheme()

  return (
    <>
      {data.map((folder, index) =>
        data.find(f => f.type === 'folder') ? (
          <Box key={`data-${folder.name}-${folder.id}-${index}`}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: isActiveFolder?.id === folder.id ? alpha(theme.palette.primary.main, 0.2) : 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                gap: theme.spacing(2),
                padding: theme.spacing(1.5, 2, 1.5, 2),
                transition: 'all .1s ease-in-out',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1)
                }
              }}
              onClick={() => {
                setActiveFolder(folder)
                if (isCollapsed.includes(folder.id)) {
                  setIsCollapsed(isCollapsed.filter(f => f !== folder.id))
                } else {
                  setIsCollapsed([...isCollapsed, folder.id])
                }
              }}
              key={`click-${folder.name}-${folder.id}-${index}`}
            >
              <Icon color={theme.palette.primary.main} fontSize='24px' icon='tabler:folder' />
              <Typography
                sx={{
                  marginLeft: 'auto',
                  flex: 1,
                  color: isActiveFolder?.id === folder.id ? theme.palette.primary.main : theme.typography.body2.color
                }}
                variant='body2'
              >
                {folder.name}
              </Typography>
              {folder.children.length > 0 && (
                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <Icon
                    style={{ alignSelf: 'flex-end' }}
                    color={theme.palette.primary.main}
                    fontSize='16px'
                    icon={isCollapsed && isCollapsed?.includes(folder.id) ? 'tabler:chevron-up' : 'tabler:chevron-down'}
                  />
                </Box>
              )}
            </Box>
            <Collapse in={isCollapsed?.includes(folder.id)}>
              <Box sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(3, 0, 3, 4) }}>
                {folder.children.length > 0 && (
                  <RenderMenu
                    data={folder.children}
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    isActiveFolder={isActiveFolder}
                    setActiveFolder={setActiveFolder}
                  />
                )}
              </Box>
            </Collapse>
          </Box>
        ) : (
          <Box
            sx={{
              alignItems: 'center',
              background: isActiveFolder?.id === folder.id ? alpha(theme.palette.primary.main, 0.2) : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              display: 'flex',
              gap: theme.spacing(2),
              padding: theme.spacing(1.5, 2, 1.5, 2),
              transition: 'all .1s ease-in-out',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1)
              }
            }}
            onClick={() => setActiveFolder(folder)}
            key={`icon-${folder.name}-${folder.id}-${index}`}
          >
            <Icon color={theme.palette.primary.main} fontSize='24px' icon='tabler:folder' />
            <Typography
              variant='body2'
              sx={{
                color:
                  isCollapsed.includes(folder.id) || isActiveFolder?.id === folder.id
                    ? theme.palette.primary.main
                    : theme.typography.body2.color
              }}
            >
              {folder.name}
            </Typography>
          </Box>
        )
      )}
    </>
  )
}

export default RenderMenu

export function mapChildren(arr: FolderType[]): RenderFolderType[] {
  let mappedArr: RenderFolderType[] = arr.filter(x => x.parent_folder_id === null).map(x => ({ ...x, children: [] }))

  for (let i = 0; i < arr.length; i++) {
    const folder = arr[i]
    if (folder.parent_folder_id === null) {
      continue
    }

    mappedArr = mappedArr.map(x => ({ ...x, children: helper(arr, x) }))
  }

  return mappedArr
}

function helper(arr: FolderType[], item: FolderType): RenderFolderType[] {
  return arr.filter(x => x.parent_folder_id === item.id).map(x => ({ ...x, children: helper(arr, x) }))
}
