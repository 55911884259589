import { z } from 'zod'
import * as zod from 'zod'

import { EmailVariable } from '../../../types/settings/email/email'
import { validateTextByEmailVariables } from '../../../utils/settings/emails'

export function createOptinEmailsSchema(emailVariables: EmailVariable[]) {
  const singleOptinVariables = emailVariables.find(v => v.name === 'single_optin_welcome_course')!.allowed_variables
  const doubleOptinWelcomeVariables = emailVariables.find(
    v => v.name === 'double_optin_welcome_course'
  )!.allowed_variables
  const doubleOptinConfirmVariables = emailVariables.find(
    v => v.name === 'double_optin_confirmation_course'
  )!.allowed_variables

  return z.object({
    email_course_optin_welcome_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, singleOptinVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_course_optin_welcome_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, singleOptinVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_course_double_optin_welcome_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, doubleOptinWelcomeVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_course_double_optin_welcome_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, doubleOptinWelcomeVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_course_double_optin_confirmation_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, doubleOptinConfirmVariables)
        if (invalids.length) {
          ctx.addIssue({
            code: 'custom',
            message: invalids.join(', ')
          })
        }
      }),
    email_course_double_optin_confirmation_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, doubleOptinConfirmVariables)
        if (invalids.length) {
          ctx.addIssue({
            code: 'custom',
            message: invalids.join(', ')
          })
        }
      })
  })
}

export type OptinEmailsFormData = zod.infer<ReturnType<typeof createOptinEmailsSchema>>
