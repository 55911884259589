import { ReactNode, useMemo } from 'react'

import Zoom from '@mui/material/Zoom'
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'

import { usePortalSettings } from '../../../hooks/usePortalSettings'
import { supportTypeIDs } from '../../../types/settings'
import { useAuth } from '../../../hooks/useAuth'
import { UserRolesType } from '../../../utils/getUserRole'

interface ScrollToTopProps {
  className?: string
  children: ReactNode
}

const ScrollToTopStyled = styled(Box, { shouldForwardProp: prop => prop !== 'bottom' })<{
  bottom: string
}>(({ theme, bottom }) => ({
  zIndex: 11,
  position: 'fixed',
  right: theme.spacing(6),
  bottom: bottom
}))

const ScrollToTop = (props: ScrollToTopProps) => {
  // ** Props
  const { children, className } = props

  // ** init trigger
  const trigger = useScrollTrigger({
    threshold: 400,
    disableHysteresis: true
  })

  const { user } = useAuth()

  const theme = useTheme()

  const { portalSettings } = usePortalSettings()

  const bottom = useMemo(() => {
    if (user?.role !== UserRolesType.CUSTOMER && user?.role !== UserRolesType.HIDDEN_CUSTOMER) return theme.spacing(10)

    const activeCustomerSupport = portalSettings?.support_settings.find(el => el?.is_active)
    if (activeCustomerSupport?.support_type_id === supportTypeIDs.contact_form) return theme.spacing(10)
    if (
      activeCustomerSupport?.support_type_id === supportTypeIDs.freshdesk ||
      activeCustomerSupport?.support_type_id === supportTypeIDs.intercom
    )
      return theme.spacing(20)
    if (activeCustomerSupport?.support_type_id === supportTypeIDs.helpscout) return theme.spacing(27)

    return theme.spacing(10)
  }, [portalSettings?.support_settings, theme, user?.role])

  const handleClick = () => {
    const anchor = document.querySelector('body')
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Zoom in={trigger}>
      <ScrollToTopStyled bottom={bottom} className={className} onClick={handleClick} role='presentation'>
        {children}
      </ScrollToTopStyled>
    </Zoom>
  )
}

export default ScrollToTop
