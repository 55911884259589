import { useCallback, useMemo, useState } from 'react'

import { styled, useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'

import { useTranslation } from 'react-i18next'

import CustomTextField from 'src/@core/components/mui/text-field'
import Icon from 'src/@core/components/icon'

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  minHeight: 260,
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('sm')]: {
    width: '93%'
  }
}))

const ModalHeader = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(5, 6, 1)
}))

const ModalBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(5, 6)
}))

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

type DeleteConfirmationModalProps = {
  isOpen: boolean
  entityType: string
  confirmWord: string
  strictCheck?: boolean
  strictMatch?: boolean
  preventPaste?: boolean
  preventContextMenu?: boolean
  onClose: () => void
  onDelete?: () => void
  onCancel?: () => void
}

const DeleteConfirmationModal = ({
  isOpen,
  entityType,
  confirmWord,
  strictCheck = true,
  strictMatch = true,
  preventPaste = false,
  preventContextMenu = false,
  onClose,
  onDelete,
  onCancel
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation()

  const [value, setValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const isValidInput = useMemo(
    () => (strictCheck ? value === confirmWord : value.toLowerCase() === confirmWord.toLowerCase()),
    [confirmWord, strictCheck, value]
  )

  const isDisabledButton = useMemo(
    () => (strictMatch ? !isValidInput : value.length === 0),
    [strictMatch, isValidInput, value.length]
  )

  const theme = useTheme()

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    setValue(target.value)
  }, [])

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      if (preventPaste) event.preventDefault()
    },
    [preventPaste]
  )

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (preventContextMenu) event.preventDefault()
    },
    [preventContextMenu]
  )

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel()
    onClose()
  }, [onClose, onCancel])

  const handleDelete = useCallback(() => {
    if (isValidInput) {
      if (onDelete) onDelete()
      onClose()
    } else if (strictCheck && value === confirmWord.toLowerCase()) {
      setErrorMessage(t('PAGE.DELETE_CONFIRM_MODAL.input_case_issue'))
    } else {
      setErrorMessage(t('PAGE.DELETE_CONFIRM_MODAL.confirm_word_mismatch', { confirm_word: confirmWord }))
    }
  }, [strictCheck, confirmWord, isValidInput, value, onDelete, onClose])

  return (
    <Modal open={isOpen} onClose={onClose} onContextMenu={handleContextMenu}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onClose} />
        </IconBox>
        <ModalHeader>
          <Typography variant='h5'>{t(`ACTION.ENTITY.delete_confirm_question_${entityType}`)}</Typography>
        </ModalHeader>
        <ModalBody>
          <Typography
            variant='body1'
            sx={{ userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', WebkitUserSelect: 'none' }}
          >
            {t('ACTION.delete_confirmation', { confirm_word: confirmWord })}
          </Typography>
          <CustomTextField
            autoFocus
            type='text'
            autoComplete='off'
            placeholder={t('FORM.delete')}
            InputProps={{ sx: { width: '100%' } }}
            onChange={handleChange}
            onPaste={handlePaste}
            {...(errorMessage && {
              sx: {
                '& .MuiInputBase-root': {
                  color: theme.palette.error.main,
                  borderColor: theme.palette.error.main
                }
              }
            })}
          />
          {errorMessage && (
            <Typography variant='caption' sx={{ color: theme.palette.error.main }}>
              {errorMessage}
            </Typography>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant='outlined' color='primary' onClick={handleCancel}>
            {t('ACTION.cancel')}
          </Button>
          <Button
            variant='outlined'
            color='error'
            disabled={isDisabledButton}
            startIcon={<Icon icon='tabler:trash' fontSize={18} />}
            onClick={handleDelete}
          >
            {t('ACTION.delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteConfirmationModal
