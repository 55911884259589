import { useEffect, useState, useCallback, useRef } from 'react'

import {
  DefinedInitialDataInfiniteOptions,
  InfiniteData,
  useInfiniteQuery,
  useQueryClient
} from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'

import unsplashService from 'src/service/unsplash-service'
import queries from 'src/queries'

import { Prettify } from 'src/types/common/utils'
import { GetImagesReponse, ImageResult } from 'src/types/unsplash'

type RefinedQueryOptions = Prettify<
  Omit<
    Partial<DefinedInitialDataInfiniteOptions<ImageResult[], Error, InfiniteData<ImageResult[], unknown>, any, number>>,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  >
>

type UseInfiniteImagesProps = {
  queryOptions?: RefinedQueryOptions
}

const useInfiniteImages = ({ queryOptions }: UseInfiniteImagesProps = {}) => {
  const observer = useRef<IntersectionObserver>()
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { data, fetchNextPage, refetch, isLoading, hasNextPage, isFetching } = useInfiniteQuery({
    ...queries.images.infinite,
    queryFn: async ({ pageParam }) => {
      const { data } = await unsplashService.getImages({ searchTerm: debouncedSearchTerm, page: pageParam })

      return data.results
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length ? allPages.length + 1 : undefined
    },
    initialPageParam: 1,
    initialData: {
      pages: [
        // ? Huh?!?!
        queryClient.getQueriesData<GetImagesReponse>({ queryKey: ['get-unsplash-images'] })[0]?.[1]?.data?.results || []
      ],
      pageParams: [1]
    },
    ...queryOptions
  })

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })

      if (node) observer.current.observe(node)
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  )

  useEffect(() => {
    refetch()
  }, [debouncedSearchTerm, refetch])

  return {
    data,
    isLoading,
    fetchNextPage,
    refetch,
    lastElementRef,
    setSearchTerm
  }
}

export default useInfiniteImages
