import React, { createContext, useContext, useState, ReactNode } from 'react'

type TabContextType = {
  activeTab: number
  setActiveTab: (tabIndex: number | ((prevTab: number) => number)) => void
}

const TabContext = createContext<TabContextType | undefined>(undefined)

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const updateActiveTab = (tabIndex: number | ((prevTab: number) => number)) => {
    setActiveTab(tabIndex)
  }

  return <TabContext.Provider value={{ activeTab, setActiveTab: updateActiveTab }}>{children}</TabContext.Provider>
}

export const useTab = () => {
  const context = useContext(TabContext)

  if (!context) throw new Error('XContext must be called from within the XContextProvider')

  return context
}
