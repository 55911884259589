import React, { createContext, useContext, useState, ReactNode } from 'react'

import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'

type SelectedMediaContextType = {
  isSelected: FileType | FolderType | undefined
  setSelected: (isSelected?: FileType | FolderType) => void
  multiSelect: (FileType | FolderType)[] | undefined
  setMultiSelect: (isSelected?: (FileType | FolderType)[]) => void
}

const defaultValue: SelectedMediaContextType = {
  isSelected: undefined,
  setSelected: () => {},
  multiSelect: [],
  setMultiSelect: () => {}
}

const SelectedMediaContext = createContext<SelectedMediaContextType | undefined>(defaultValue)

export const SelectedMediaProvider = ({ children }: { children: ReactNode }) => {
  const [isSelected, setSelected] = useState<FileType | FolderType>()
  const [multiSelect, setMultiSelect] = useState<(FileType | FolderType)[]>()

  return (
    <SelectedMediaContext.Provider value={{ isSelected, setSelected, multiSelect, setMultiSelect }}>
      {children}
    </SelectedMediaContext.Provider>
  )
}

export const useSelectedMedia = () => {
  const context = useContext(SelectedMediaContext)

  if (!context) throw new Error('XContext must be called from within the XContextProvider')

  return context
}
