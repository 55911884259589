import { FileType } from 'src/types/files/fileData'

export const isFileTypeAccepted = (file: FileType | File, accept?: string): boolean => {
  if (!accept) return true // No validation if no accept is defined

  const acceptedTypes = accept.split(',').map(type => type.trim())

  return acceptedTypes.some(type => {
    // Handle file extensions and MIME types differently based on the input type
    if (file instanceof File) {
      // If it's a native File object, we use file.type (MIME type) and file.name for the extension
      const extension = file.name.split('.').pop()?.toLowerCase()
      if (type.startsWith('.')) {
        // Check for file extension
        return `.${extension}` === type.toLowerCase()
      }

      // Otherwise, check for MIME type
      return file.type === type
    } else if (file.extension) {
      // If it's a FileType object with an extension field
      if (type.startsWith('.')) {
        // Check for file extension
        return file.extension.toLowerCase() === type.toLowerCase()
      }

      // Otherwise, check for MIME type
      return file.type === type
    }

    return false
  })
}
