import Link from 'next/link'

import { Stack, styled, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'

import CustomTextField from 'src/@core/components/mui/text-field'
import Icon from 'src/@core/components/icon'

import { ForgotPasswordFormType } from 'src/schemas/auth'

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize
}))

const TypographyContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { mr: 1 }
})

type ForgotPasswordProps = {
  isLoading: boolean
  form: UseFormReturn<ForgotPasswordFormType>
  onSubmmit: (data: ForgotPasswordFormType) => void
}

export const ForgotPasswordForm = ({
  isLoading,
  form: { control, formState, handleSubmit },
  onSubmmit
}: ForgotPasswordProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.FORGOT_PASSWORD' })

  const theme = useTheme()

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmmit)}>
      <Stack gap={theme.spacing(4)}>
        <Controller
          name={'email'}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <CustomTextField
              required
              autoFocus
              fullWidth
              label={t('FORM.email_label')}
              placeholder={t('FORM.email_placeholder')}
              value={value}
              onChange={onChange}
              error={Boolean(formState.errors.email)}
              helperText={formState.errors.email && t(`VALIDATIONS.${formState.errors.email.message}`)}
            />
          )}
        />

        <LoadingButton fullWidth type='submit' variant='contained' loading={isLoading} disabled={isLoading}>
          {t('BUTTON.send_new_password')}
        </LoadingButton>

        <TypographyContainer>
          <StyledLink href='/login'>
            <Icon fontSize='1.25rem' icon='tabler:chevron-left' />
            <span>{t('BUTTON.back_to_login')}</span>
          </StyledLink>
        </TypographyContainer>
      </Stack>
    </form>
  )
}
