import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import { SettingsSmtp } from '../types/settings/portal-settings'

import service from './service'

import { FilterParams, ResponseResult } from 'src/types/common/types'
import {
  EmailTemplate,
  EmailSettingsContent,
  EmailType,
  EmailVariable,
  TestEmail,
  EmailSettingsContentKeys,
  WelcomeEmailType,
  WelcomeEmailSettings,
  NewSender,
  Sender
} from 'src/types/settings/email/email'

export type GetEmailVariablesRequest = FilterParams<keyof EmailVariable, EmailType[]>
export type GetEmailVariablesResponse = ResponseResult<EmailVariable[]>
export type ValidateEmailTemplateRequest = EmailTemplate
export type ValidateEmailTemplateResponse = ResponseResult<boolean>
export type SendTestThankYouEmailRequest = TestEmail
export type SendTestThankYouEmailResponse = ResponseResult<boolean>
export type SendTestActivationEmailRequest = TestEmail
export type SendTestActivationEmailResponse = ResponseResult<boolean>
export type GetEmailSettingsRequest = FilterParams<EmailSettingsContentKeys, unknown>
export type GetEmailSettingsResponse = ResponseResult<EmailSettingsContent[]>
export type UpdateEmailSettingsRequest = FilterParams<EmailSettingsContentKeys, unknown, Partial<EmailSettingsContent>>
export type UpdateEmailSettingsResponse = ResponseResult<boolean>
export type GetWelcomeEmailTypesRequest = FilterParams
export type GetWelcomeEmailTypesResponse = ResponseResult<WelcomeEmailType[]>
export type GetWelcomeEmailSettingsRequest = FilterParams<(keyof WelcomeEmailSettings)[], unknown, WelcomeEmailSettings>
export type GetWelcomeEmailSettingsResponse = ResponseResult<WelcomeEmailSettings[]>
export type UpdateWelcomeEmailSettingsRequest = FilterParams<WelcomeEmailSettings[], unknown, WelcomeEmailSettings>
export type UpdateWelcomeEmailSettingsResponse = ResponseResult<boolean>
export type AddSenderRequest = NewSender
export type AddSenderResponse = ResponseResult<boolean>
export type GetSenderListRequest = FilterParams
export type GetSenderListResponse = ResponseResult<Sender[]>
export type GetSMTPSettingsResponse = ResponseResult<SettingsSmtp[]>
export type GetSenderCountResponse = ResponseResult<number>
export type CheckSenderDNSRequest = { id: number }
export type CheckSenderDNSResponse = ResponseResult<Sender>
export type ActivateSenderRequest = { id: number }
export type ActivateSenderResponse = ResponseResult<boolean>
export type DeactivateSenderRequest = { id: number }
export type DeactivateSenderResponse = ResponseResult<Sender>
export type UpdateSMTPRequest = Partial<SettingsSmtp>
export type UpdateSMTPResponse = ResponseResult<boolean>
export type SendHelpEmailRequest = { message: string; subject: string }
export type SendHelpEmailResponse = ResponseResult<boolean>

const emailService = {
  getEmailVariables: async (requestBody: GetEmailVariablesRequest): Promise<AxiosResponse<GetEmailVariablesResponse>> =>
    service.post(requestUrls.getEmailVariables, requestBody),
  validateEmailTemplate: async (
    requestBody: ValidateEmailTemplateRequest
  ): Promise<AxiosResponse<ValidateEmailTemplateResponse>> =>
    service.post(requestUrls.validateEmailTemplate, requestBody),
  sendTestThankYouEmail: async (
    requestBody: SendTestThankYouEmailRequest
  ): Promise<AxiosResponse<SendTestActivationEmailResponse>> =>
    service.post(requestUrls.sendTestThankYouEmail, requestBody),
  sendTestActivationEmail: async (
    requestBody: SendTestActivationEmailRequest
  ): Promise<AxiosResponse<SendTestActivationEmailResponse>> =>
    service.post(requestUrls.sendTestActivationEmail, requestBody),
  updateWelcomeEmailSettings: (
    requestBody: UpdateWelcomeEmailSettingsRequest
  ): Promise<AxiosResponse<UpdateWelcomeEmailSettingsResponse>> =>
    service.post(requestUrls.updateWelcomeEmailSettings, requestBody),
  getWelcomeEmailSettings: (
    requestBody?: GetWelcomeEmailSettingsRequest
  ): Promise<AxiosResponse<GetWelcomeEmailSettingsResponse>> =>
    service.post(requestUrls.getWelcomeEmailSettings, requestBody),
  getWelcomeEmailTypes: (
    requestBody?: GetWelcomeEmailTypesRequest
  ): Promise<AxiosResponse<GetWelcomeEmailTypesResponse>> =>
    service.post(requestUrls.getWelcomeEmailTypes, requestBody),
  getEmailSettings: (requestBody?: GetEmailSettingsRequest): Promise<AxiosResponse<GetEmailSettingsResponse>> =>
    service.post(requestUrls.getEmailSettings, requestBody),
  updateEmailSettings: (requestBody: UpdateEmailSettingsRequest): Promise<AxiosResponse<UpdateEmailSettingsResponse>> =>
    service.post(requestUrls.updateEmailSettings, requestBody),
  addSender: (requestBody: AddSenderRequest): Promise<AxiosResponse<AddSenderResponse>> =>
    service.post(requestUrls.addSender, requestBody),
  getSendersList: (requestBody: GetSenderListRequest): Promise<AxiosResponse<GetSenderListResponse>> =>
    service.post(requestUrls.getSendersList, requestBody),
  getSMTPSettings: (): Promise<AxiosResponse<GetSMTPSettingsResponse>> => service.post(requestUrls.getSMTPSettings),
  getSendersCount: (): Promise<AxiosResponse<GetSenderCountResponse>> => service.post(requestUrls.getSendersCount),
  checkSenderDNS: (requestBody: CheckSenderDNSRequest): Promise<AxiosResponse<CheckSenderDNSResponse>> =>
    service.post(requestUrls.checkSenderDNS, requestBody),
  deactivateSender: (requestBody: DeactivateSenderRequest): Promise<AxiosResponse<DeactivateSenderResponse>> =>
    service.post(requestUrls.deactivateSender, requestBody),
  activateSender: (requestBody: ActivateSenderRequest): Promise<AxiosResponse<ActivateSenderResponse>> =>
    service.post(requestUrls.activateSender, requestBody),
  updateSMTPSettings: (requestBody: UpdateSMTPRequest): Promise<AxiosResponse<UpdateSMTPResponse>> =>
    service.post(requestUrls.updateSMTPSettings, requestBody),
  sendHelpEmail: (requestBody: SendHelpEmailRequest): Promise<AxiosResponse<SendHelpEmailResponse>> =>
    service.post(requestUrls.sendHelpEmail, requestBody)
}

export default emailService
