import { FilterParams } from '../../../../types/common/types'
import { MemberKeys } from '../../../../types/members'

export const profileFilters: FilterParams<MemberKeys, number> = {
  columns: [
    'id',
    'email',
    'first_name',
    'last_name',
    'address',
    'avatar_id',
    'registered_at',
    'nickname',
    'phone_number'
  ]
}
