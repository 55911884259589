import { createQueryKeys } from '@lukemorales/query-key-factory'

const paymentSettings = createQueryKeys('paymentSettings', {
  marketplaceInfo: {
    queryKey: null
  },
  marketplaceBindings: {
    queryKey: null
  },
  portalApiKey: {
    queryKey: null
  },
  addMarketplaceProvider: {
    queryKey: null
  },
  createCopercartCredentials: {
    queryKey: null
  }
})

export default paymentSettings
