import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { alpha } from '@mui/system'

export const FileUploaderSectionBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  border: '1px dashed',
  borderRadius: '6px',
  justifyContent: 'center',
  padding: theme.spacing(8)
}))

export const FileUploaderColoredInnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'center',
  paddingX: theme.spacing(6),
  paddingY: theme.spacing(10),
  position: 'relative'
}))

export const FileUploaderIconWrapperBox = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha('#4B465C', 0.08),
  borderRadius: '6px',
  backgroundSize: 'cover',
  color: theme.palette.text.primary,
  height: 38,
  justifyContent: 'center',
  padding: '5px',
  transition: 'all 120ms ease-out',
  width: 38
}))

export const FileUploaderButtonsContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))
