import { createQueryKeys } from '@lukemorales/query-key-factory'

const settings = createQueryKeys('settings', {
  allSupportSettings: {
    queryKey: null
  },
  allSupportTypes: {
    queryKey: null
  },
  legalDocuments: {
    queryKey: null
  },
  gdprInfo: {
    queryKey: null
  },
  analyticsInfo: {
    queryKey: null
  },
  largeBannerType: {
    queryKey: null
  },
  trackingConditionType: {
    queryKey: null
  },
  createCopercartCredentials: {
    queryKey: null
  },
  communitySettings: {
    queryKey: null
  },
  communityDisplayNames: {
    queryKey: null
  },
  affiliateLinks: {
    queryKey: null
  },
  affiliateLinksCount: {
    queryKey: null
  },
  configuration: {
    queryKey: null
  },
  design: {
    queryKey: null
  },
  notifications: {
    queryKey: null
  },
  communityNotifications: {
    queryKey: null
  }
})

export default settings
