import { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import fileService from 'src/service/file-service'

import useToast from '../useToast'

export const useImageUpload = (onSuccess?: (fileId: string) => void) => {
  const { notify } = useToast()

  const [progress, setProgress] = useState<number>(0)
  const onChangeProgress = useCallback((progress: number) => setProgress(progress), [setProgress])

  const { mutateAsync: uploadImage, isPending } = useMutation({
    mutationFn: (formData: FormData) => fileService.uploadFile(formData, onChangeProgress),
    onSuccess: response => {
      notify('IMAGE.upload', { type: 'success' })
      onSuccess?.(response.data.result.file_id)
    }
  })

  return useMemo(
    () => ({ uploadImage, isPending, progress, onChangeProgress }),
    [isPending, onChangeProgress, progress, uploadImage]
  )
}
