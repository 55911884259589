import Router from 'next/router'

import axios from 'axios'

import authConfig from 'src/configs/auth'

import env from 'src/envs'
import authService from 'src/service/auth-service'
import { skipLogoutUrls } from 'src/service/service'

import { mapRequestDataToAbstractEntityPayload } from './lib/payload-mappers'

import { FilterParams } from 'src/types/common/types'

export const abstractEntityService = axios.create({
  baseURL: env.apiUrl,
  withCredentials: true
})

abstractEntityService.interceptors.request.use(request => {
  request.url = abstractEntityService.getUri({ url: request.url })

  const data: FilterParams = request?.data

  const userToken = authService.getUserToken()
  if (userToken) {
    request.headers.Authorization = `Bearer ${userToken}`
  }

  request.data = mapRequestDataToAbstractEntityPayload(data)

  return request
})

abstractEntityService.interceptors.response.use(
  response => response,
  error => {
    if (
      window.localStorage.getItem(authConfig.storageTokenKeyName) &&
      error.response.status === 403 &&
      !skipLogoutUrls.some(skipUrl => error.config.url.includes(skipUrl))
    ) {
      window.localStorage.removeItem(authConfig.mentorToolsUserObjectKeyName)
      window.localStorage.removeItem(authConfig.storageTokenKeyName)
      window.localStorage.removeItem(authConfig.portalSettingsKeyName)

      Router.push('/login')
    }

    return Promise.reject(error)
  }
)
