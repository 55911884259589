import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { PasswordUpdateFormData, PasswordUpdateValidation } from '../../../schemas/settings/profile'
import useToast from '../../useToast'
import authService from '../../../service/auth-service'

function setupDefaultValues(): PasswordUpdateFormData {
  return {
    password: '',
    confirm_password: '',
    new_password: ''
  }
}

export const useUpdatePasswordForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty }
  } = useForm<PasswordUpdateFormData>({
    resolver: zodResolver(PasswordUpdateValidation),
    defaultValues: setupDefaultValues(),
    mode: 'all'
  })

  const { notify } = useToast()

  const { mutate: updatePassword } = useMutation({
    mutationFn: authService.updatePassword,
    onSuccess: ({ data }) => {
      if (data?.error) {
        notify(data?.error, { type: 'error' })

        return
      }
      notify('PROFILE_SETTINGS.password', { type: 'success' })
      reset(undefined, {
        keepDirtyValues: true,
        keepValues: true
      })
    },
    onError: error => {
      const axiosError = error as AxiosError<{ error: string }>
      const errorMessage = axiosError.response?.data?.error

      notify(errorMessage || 'PROFILE_SETTINGS.password', { type: 'error' })
    }
  })

  const onSubmit = (formData: PasswordUpdateFormData) => {
    updatePassword(formData)
  }

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
    errors,
    isSubmitting,
    isDirty
  }
}
