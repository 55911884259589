import { createQueryKeys } from '@lukemorales/query-key-factory'

const loginPageDesign = createQueryKeys('loginPageDesign', {
  getAllText: {
    queryKey: null
  },
  getAllImages: {
    queryKey: null
  }
})

export default loginPageDesign
