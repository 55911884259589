import { useMemo, useState } from 'react'

import { Box, Stack, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { usePortalSettings } from 'src/hooks/usePortalSettings'

import { VideoPlayer } from 'src/components/common/VideoPlayer'

import { getImageUrl } from 'src/utils/getImageUrl'

const HeaderWrapper = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center'
}))

const BackgroundImage = styled(Box)(({ bgcolor, bgImage }: { bgImage?: string; bgcolor?: string }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  width: '100%',
  height: '100%',
  minHeight: 246,
  backgroundBlendMode: 'overlay',
  top: 0,
  left: 0,
  zIndex: 0,

  ...(bgImage && { backgroundImage: `url(${bgImage})` }),

  ...(bgcolor && {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: bgcolor || 'transparent'
    }
  })
}))

const MediaImage = styled(Box)(({ bgImage }: { bgImage?: string }) => ({
  position: 'relative',
  margin: 'auto',
  width: '100%',
  height: '100%',
  borderRadius: 6,
  maxHeight: 300,
  ...(bgImage && {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%'
  }),
  '&::before': {
    content: '""',
    display: 'block',
    margin: 'auto',
    paddingTop: '56.25%',
    maxHeight: 300,
    height: '100%'
  }
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  maxWidth: 1124,
  width: '100%',
  padding: theme.spacing(6),
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 4px 18px 0 ${theme.colorWithOpacity('#4B465C', 10)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  margin: theme.spacing(15, 0, 15, 0),
  zIndex: 1,

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(6),
    alignItems: 'center'
  }
}))

const ExpandableText = styled(Typography)(({ isExpanded }: { isExpanded: boolean }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: isExpanded ? 'visible' : 'hidden',
  WebkitLineClamp: isExpanded ? 'none' : 5,
  textOverflow: isExpanded ? 'none' : 'ellipsis'
}))

const MediaWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  maxWidth: 282,
  height: '100%',
  width: '100%',
  margin: 'auto',
  paddingRight: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    paddingRight: 0,
    height: '100%'
  }
}))

const TextWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: 793,
  width: '100%',
  gap: theme.spacing(2),
  paddingRight: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    paddingRight: 0
  }
}))

export const WelcomeBox = () => {
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [isExpanded, setIsExpanded] = useState(false)
  const { portalSettings } = usePortalSettings()
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.ACADEMY_CUSTOMIZATION.WELCOME_BLOCK' })

  const welcomeBox = useMemo(() => portalSettings?.welcome_box_settings?.[0], [portalSettings?.welcome_box_settings])

  const welcomeBoxContent = useMemo(
    () =>
      welcomeBox?.content_blocks.map(item => ({
        ...item.content,
        type_id: item.type_id,
        is_active: item.is_active
      })) ?? [],
    [welcomeBox?.content_blocks]
  )

  const filteredMedia = useMemo(() => welcomeBoxContent.filter(item => Boolean(item.is_active)), [welcomeBoxContent])

  const toggleIsExpanded = () => setIsExpanded(s => !s)

  if (!welcomeBox?.show) return null

  return (
    <HeaderWrapper>
      <BackgroundImage
        paddingBottom={theme.spacing(8)}
        bgcolor={welcomeBox?.bg_color}
        bgImage={welcomeBox?.bg_img ? getImageUrl(`${welcomeBox?.bg_img}`) : ''}
      />

      <Stack width={'100%'} paddingX={8} alignItems='center'>
        <ContentWrapper>
          <TextWrapper>
            <Typography variant='h3'>
              {welcomeBox?.headline?.trim() ? welcomeBox.headline : t('deafult_headline')}
            </Typography>

            <Typography variant='h4'>
              {welcomeBox?.subheadline?.trim() ? welcomeBox.subheadline : t('deafult_subheadline')}
            </Typography>

            <ExpandableText onClick={toggleIsExpanded} variant='body1' isExpanded={isExpanded}>
              {welcomeBox?.description?.trim() ? welcomeBox.description : t('deafult_description')}
            </ExpandableText>
          </TextWrapper>

          {welcomeBoxContent.length > 0 && (
            <MediaWrapper>
              {filteredMedia.map((media, index) => (
                <Stack key={index}>
                  {media.type_id === 4 ? (
                    <VideoPlayer
                      key={index}
                      fileId={media.file_id}
                      link={media.link}
                      {...(isMediumScreen && { height: 300 })}
                    />
                  ) : (
                    <MediaImage bgImage={media.file_id ? getImageUrl(media.file_id) : ''} />
                  )}
                </Stack>
              ))}
            </MediaWrapper>
          )}
        </ContentWrapper>
      </Stack>
    </HeaderWrapper>
  )
}
