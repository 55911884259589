import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { useTranslation } from 'react-i18next'

import { CommunityNotification } from '../../types/notifications'
import CustomAvatar from '../../@core/components/mui/avatar'
import { getImageUrl } from '../../utils/getImageUrl'
import { getFormattedDate } from '../../utils/notifications/getFormattedDate'
import { useMarkAsRead } from '../../hooks/notifications'

const Ellipsis = styled(Box, { shouldForwardProp: prop => prop !== 'radius' && prop !== 'color' })<{
  radius?: string
  color?: string
}>(({ radius, color }) => ({
  backgroundColor: color,
  padding: radius,
  borderRadius: '100%'
}))

const Wrapper = styled(Stack, { shouldForwardProp: prop => prop !== 'seen' && prop !== 'isPopover' })<{
  seen?: boolean
  isPopover?: boolean
}>(({ theme, seen, isPopover }) => ({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: !isPopover ? theme.spacing(4) : theme.spacing(1),
  opacity: seen ? 0.8 : undefined,
  paddingBottom: !isPopover ? theme.spacing(4) : undefined,
  paddingTop: !isPopover ? theme.spacing(4) : undefined
}))

type Props = {
  notification: CommunityNotification
  isPopover?: boolean
  closePopover?: () => void
}

export const Notification = ({
  notification: { id, created_at, is_anonymous, message, portal_member, seen, source_type, visit_url, source_id },
  isPopover = false,
  closePopover
}: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.NOTIFICATIONS' })

  const theme = useTheme()
  const avatarImgSrc = portal_member?.avatar_id ? getImageUrl(portal_member.avatar_id) : ''
  const { markAsRead } = useMarkAsRead(undefined, false)
  const router = useRouter()

  const basePath = `${window.location.origin}/app`
  const threadPath = visit_url?.replace('community/communities/', 'community/').replace(/\/$/, '')
  const threadUrl = `${basePath}/${threadPath}${source_type === 'comment' ? `?comment_id=${source_id}` : ''}`

  const onNotificationClick = useCallback(async () => {
    if (!seen) {
      await markAsRead([id])
    }

    if (isPopover) closePopover?.()

    router.push(threadUrl)
  }, [seen, isPopover, closePopover, router, threadUrl, markAsRead, id])

  return (
    <Wrapper seen={seen} onClick={onNotificationClick} isPopover={isPopover}>
      <Stack direction='row' alignItems='center' spacing={theme.spacing(4)} overflow='hidden'>
        <CustomAvatar src={avatarImgSrc} />
        <Stack minWidth='0' spacing={!isPopover ? theme.spacing(1) : undefined}>
          <Stack spacing={theme.spacing(1)}>
            <Stack direction='row' alignItems='center' spacing={theme.spacing(2)}>
              <Typography
                variant='h6'
                lineHeight={1}
                color={isPopover ? theme.palette.customColors.navbarText : theme.palette.grey[400]}
              >
                {is_anonymous
                  ? t('anonymous')
                  : !portal_member?.first_name && !portal_member?.last_name
                  ? t('community_member')
                  : `${portal_member.first_name} ${portal_member.last_name}`}
              </Typography>

              <Ellipsis
                color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]}
                radius={theme.spacing(0.25)}
              />

              <Typography
                variant='body2'
                lineHeight={1}
                color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]}
              >
                {/* TODO: add mobile app progress notifications */}
                {t(`source_type.${source_type}`)}
              </Typography>
            </Stack>
            <Typography
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
              color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]}
            >
              {message}
            </Typography>
          </Stack>
          {isPopover ? (
            <Typography
              whiteSpace='nowrap'
              variant='body2'
              color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]}
            >
              {getFormattedDate(new Date(created_at), t)}
            </Typography>
          ) : null}
        </Stack>
      </Stack>

      <Stack direction='row' alignItems='center' spacing={theme.spacing(8)}>
        {!isPopover ? (
          <Stack spacing={theme.spacing(1)}>
            <Typography
              whiteSpace='nowrap'
              variant='body2'
              color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]}
            >
              {getFormattedDate(new Date(created_at), t)}
            </Typography>
            {/* waiting for BE on this */}
            {/*<Typography  whiteSpace='nowrap' variant='body2' color={isPopover ? theme.palette.customColors.navbarSecondaryText : theme.palette.grey[400]} fontWeight={500}>*/}
            {/*  You commented*/}
            {/*</Typography>*/}
          </Stack>
        ) : null}
        {!seen ? <Ellipsis color={theme.palette.primary.main} radius={theme.spacing(1.5)} /> : null}
      </Stack>
    </Wrapper>
  )
}
