import { createQueryKeys } from '@lukemorales/query-key-factory'

const orders = createQueryKeys('orders', {
  allOrders: {
    queryKey: null
  },
  allOrdersCount: {
    queryKey: null
  }
})

export default orders
