import { z } from 'zod'
import * as zod from 'zod'

const DELETE_LITERAL = 'DELETE'

export const DeleteProfileValidation = z.object({
  deleteLiteral: z.literal(DELETE_LITERAL),
  confirm: z.boolean()
})

export type DeleteProfileFormData = zod.infer<typeof DeleteProfileValidation>
