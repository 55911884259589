import { createQueryKeys } from '@lukemorales/query-key-factory'

const courseAccess = createQueryKeys('courseAccess', {
  ownershipReason: (customerId: number) => {
    return { queryKey: [customerId] }
  },
  lessonProgress: (portalMemberId: number) => {
    return { queryKey: [portalMemberId] }
  },
  courseDetails: (courseId: number[]) => {
    return { queryKey: [courseId] }
  },
  coursePaymentCheck: (courseId: number, portal_member_id: number) => {
    return { queryKey: [courseId, portal_member_id] }
  },
  courseAccessCheck: (courseIds: number[], portal_member_id: number) => {
    return { queryKey: [courseIds, portal_member_id] }
  },
  courseProgress: (courseId: number[]) => {
    return { queryKey: [courseId] }
  }
})

export default courseAccess
