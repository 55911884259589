import { createQueryKeys } from '@lukemorales/query-key-factory'

const quizStatistics = createQueryKeys('quizStatistics', {
  getAll: {
    queryKey: null
  },
  getQuizPasses: (customerId: number, lessonsIds: number[]) => {
    return { queryKey: [customerId, lessonsIds] }
  },
  getQuizResults: (assessmentId: number) => {
    return { queryKey: [assessmentId] }
  }
})

export default quizStatistics
