import { createQueryKeys } from '@lukemorales/query-key-factory'

const products = createQueryKeys('products', {
  all: {
    queryKey: null
  },
  languages: {
    queryKey: null
  },
  productTypes: {
    queryKey: null
  }
})

export default products
