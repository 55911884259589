import { createQueryKeys } from '@lukemorales/query-key-factory'

import { DEFAULT_LIST_QUERY_KEY } from './constants'

type ListFilters = {
  page: number
  perPage: number
  isArchived: boolean
  searchTerm: string
}

const courses = createQueryKeys('courses', {
  all: {
    queryKey: null
  },
  courseToCommunity: (communityId?: number) => {
    return { queryKey: [{ communityId }] }
  },
  list: (filters?: Partial<ListFilters>) => {
    if (!filters || JSON.stringify(filters) === '{}') return { queryKey: [DEFAULT_LIST_QUERY_KEY] }

    const obj: Record<string, unknown> = {}

    for (const [key, value] of Object.entries(filters)) {
      try {
        obj[key as keyof ListFilters] = JSON.parse(value.toString())
      } catch (_) {
        obj[key as keyof ListFilters] = value
      }
    }

    return { queryKey: [{ filters: obj }] }
  },
  detail: (courseId: number) => {
    return { queryKey: [courseId] }
  },
  overallCount: {
    queryKey: null
  },
  count: {
    queryKey: null
  },
  infinite: {
    queryKey: null
  },
  coursesByOrders: {
    queryKey: null
  },
  coursesDictIsActive: {
    queryKey: null
  },
  coursesDict: {
    queryKey: null
  },
  coursesInfo: {
    queryKey: null
  }
})

export default courses
