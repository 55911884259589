import { useState } from 'react'

import { useRouter } from 'next/router'

import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'
import { LayoutProps } from 'src/@core/layouts/types'
import Customizer from 'src/@core/components/customizer'
import ScrollToTop from 'src/@core/components/scroll-to-top'

import { useAuth } from 'src/hooks/useAuth'
import { useUserPortalInfo } from 'src/hooks/portal/useUserPortalInfo'

import { routePaths } from 'src/navigation/paths'
import Can from 'src/layouts/components/acl/Can'
import { subjects } from 'src/navigation/vertical/subjects'

import { Footer, WelcomeBox } from 'src/components/customer/common'
import { PreviewModeBar } from 'src/components/common/PreviewModeBar'
import { TrialBar } from 'src/components/common/TrialBar'
import TrialEndedModal from 'src/components/common/modals/TrialEndedModal'

import { UserRolesType } from 'src/utils/getUserRole'

import themeConfig from 'src/configs/themeConfig'
import { permissionActions } from 'src/configs/permissionActions'

import CustomNavigation from '../../components/layouts/components/vertical/navigation'

import AppBar from './components/vertical/appBar'

// import Footer from './components/shared-components/footer'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { isTrialEnded, isTrial, isVerified } = useUserPortalInfo()

  // ** Props
  const { hidden, settings, children, scrollToTop, contentHeightFixed, verticalLayoutProps } = props

  const router = useRouter()

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        {/* Navigation Menu */}
        {navHidden && !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <CustomNavigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper
          className='layout-content-wrapper'
          sx={{ ...(contentHeightFixed && { maxHeight: '100vh' }) }}
        >
          {/* AppBar Component */}
          {verticalLayoutProps.appBar && (
            <AppBar
              toggleNavVisibility={toggleNavVisibility}
              appBarContent={verticalLayoutProps.appBar?.content}
              appBarProps={verticalLayoutProps.appBar?.componentProps}
              {...props}
            />
          )}
          <Can I={permissionActions.read} a={subjects.preview}>
            <PreviewModeBar />
          </Can>
          <Can I={permissionActions.read} a={subjects.ccPage}>
            {(!isVerified || isTrial) && !isTrialEnded && <TrialBar />}
            <TrialEndedModal isOpen={isTrialEnded} onClose={close} />
          </Can>
          {hidden && (
            <Box sx={{ p: 2 }}>
              <IconButton color='inherit' onClick={toggleNavVisibility}>
                <Icon icon='tabler:menu-2' fontSize={20} />
              </IconButton>
            </Box>
          )}

          {[routePaths.customerDashboard].includes(router.pathname) && <WelcomeBox />}

          {/* Content */}
          <ContentWrapper
            className='layout-page-content'
            sx={{
              ...(contentHeightFixed && {
                overflow: 'hidden',
                '& > :first-of-type': { height: '100%' }
              }),
              ...(contentWidth === 'boxed' && {
                '@media (min-width:1440px)': { maxWidth: 1440 },
                mx: 'auto',
                '@media (min-width:1200px)': { maxWidth: '100%' }
              }),
              ...(hidden && { pt: 0 })
            }}
          >
            {children}
          </ContentWrapper>
          {/* Footer Component */}

          {[routePaths.customerDashboard, routePaths.customerCourseDetails].includes(router.pathname) && <Footer />}
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {user?.role === UserRolesType.CUSTOMER ||
      user?.role === UserRolesType.HIDDEN_CUSTOMER ||
      disableCustomizer ||
      hidden ? null : (
        <Customizer />
      )}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label={t('extracted.scroll_back_to_top')}>
            <Icon icon='tabler:arrow-up' />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout
