import { memo, useMemo } from 'react'

import Link from 'next/link'

import Box from '@mui/material/Box'
import { Stack } from '@mui/material'
import { createTheme, responsiveFontSizes, styled, Theme, ThemeProvider, useTheme } from '@mui/material/styles'
import List from '@mui/material/List'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LoadingButton } from '@mui/lab'

import groupBy from 'lodash.groupby'

import { LayoutProps } from 'src/@core/layouts/types'

import { useCustomDesign } from 'src/hooks/CustomDesignContext'

import Can from '../../../../../layouts/components/acl/Can'
import { subjects } from '../../../../../navigation/vertical/subjects'
import { permissionActions } from '../../../../../configs/permissionActions'
import Logo from '../../vertical/navigation/Logo'
import { usePortalSettings } from '../../../../../hooks/usePortalSettings'
import { getImageUrl } from '../../../../../utils/getImageUrl'
import { useAuth } from '../../../../../hooks/useAuth'
import Icon from '../../../../components/icon'
import getHomeRoute from '../../../../../layouts/components/acl/getHomeRoute'
import { getUserRole } from '../../../../../utils/getUserRole'
import { DefaultCCLogo } from '../../shared-components/icons'
import themeOptions from '../../../../theme/ThemeOptions'
import themeConfig from '../../../../../configs/themeConfig'

import HorizontalNavItems from './HorizontalNavItems'
import CustomLinks from './CustomLinks'

const LinkStyled = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const NavigationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 1400,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  backgroundColor: theme.palette.customColors.navbarBg
}))

const ListStyled = styled(List)<{ gap?: string }>(({ gap }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap,
  alignItems: 'center',
  '& li:last-child': {
    margin: 0
  }
}))

const LogoImage = styled(Box, { shouldForwardProp: prop => prop !== 'logo' })<{ logo: string }>(({ logo }) => ({
  flex: 1,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundImage: logo,
  width: 204,
  height: 52
}))

const ButtonStyled = styled(LoadingButton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 500
})

interface Props {
  settings: LayoutProps['settings']
  horizontalNavItems: NonNullable<NonNullable<LayoutProps['horizontalLayoutProps']>['navMenu']>['navItems']
}

const Navigation = memo((props: Props) => {
  const { settings } = props
  const theme = useTheme()
  const { portalSettings } = usePortalSettings()
  const navigationLogoUrl = useMemo(
    () =>
      portalSettings?.portal_design_images?.[0]?.small_logo &&
      `url(${getImageUrl(portalSettings?.portal_design_images?.[0]?.small_logo)})`,
    [portalSettings?.portal_design_images]
  )

  const { loginAsPreview, isLoginAsPreviewPending } = useAuth()
  const customDesign = useCustomDesign()

  // ** Create new theme for the navigation menu when mode is `semi-dark`
  let darkTheme = createTheme(themeOptions(settings, 'dark', customDesign))

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    darkTheme = responsiveFontSizes(darkTheme)
  }

  const userRole = getUserRole()
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))

  const { topMenu, bottomMenu, customLinks } = useMemo(() => {
    const group = groupBy(props.horizontalNavItems, item => item.subject)

    return {
      topMenu: group?.topMenu,
      bottomMenu: group?.bottomMenu,
      customLinks: group?.customLinks
    }
  }, [props.horizontalNavItems])

  return (
    <ThemeProvider theme={darkTheme}>
      <NavigationWrapper>
        <Stack minWidth={0} direction='row' alignItems='center' spacing={mdUp ? theme.spacing(2) : undefined}>
          <Stack direction='row'>
            <Stack direction='row' alignItems='center' spacing={mdUp ? theme.spacing(6) : theme.spacing(4)}>
              <LinkStyled href={getHomeRoute(userRole)}>
                <Can a={subjects.stundentPage} I={permissionActions.read}>
                  {navigationLogoUrl ? <LogoImage logo={navigationLogoUrl} /> : <DefaultCCLogo />}
                </Can>
                <Can not a={subjects.stundentPage} I={permissionActions.read}>
                  <Logo isLabelVisible />
                </Can>
              </LinkStyled>
              <ListStyled disablePadding gap={mdUp ? theme.spacing(2) : undefined}>
                {topMenu ? <HorizontalNavItems {...props} horizontalNavItems={topMenu} /> : null}
              </ListStyled>
            </Stack>
          </Stack>
          {customLinks && <CustomLinks {...props} items={customLinks} />}
        </Stack>

        <Box>
          <Stack direction='row' alignItems='center' justifyContent='center' spacing={theme.spacing(2)}>
            <Can a={subjects.ccPage} I={permissionActions.read}>
              <ButtonStyled
                fullWidth
                variant='tonal'
                size='small'
                onClick={loginAsPreview}
                loading={isLoginAsPreviewPending}
                disabled={isLoginAsPreviewPending}
              >
                <Icon icon='tabler:arrow-up-right' fontSize={16} />
              </ButtonStyled>
            </Can>
            <Stack direction='row'>
              {bottomMenu ? <HorizontalNavItems {...props} horizontalNavItems={bottomMenu} /> : null}
            </Stack>
          </Stack>
        </Box>
      </NavigationWrapper>
    </ThemeProvider>
  )
})

export default Navigation
