import { Box, BoxProps, styled, Stack } from '@mui/material'
import { StackProps } from '@mui/system'

const EditorBackground = styled(Box)<BoxProps>(() => ({
  backgroundImage: 'url(/app/images/Checker.png)',
  backgroundSize: '5%',
  opacity: '10%',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}))

const CloseButton = styled(Stack)<StackProps>(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  top: theme.spacing(-4),
  right: theme.spacing(-4),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4]
}))

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  width: '95%',
  padding: theme.spacing(16),
  maxWidth: 732,
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  }
}))

export { EditorBackground, CloseButton, ModalContent }
