import { useCallback, useMemo } from 'react'

import { Stack, styled, Tooltip, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import FormHelperText from '@mui/material/FormHelperText'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form'

import { UserRolesType } from 'src/utils/getUserRole'

import { ProfileFormData } from '../../../../schemas/settings/profile'
import CustomTextField from '../../../../@core/components/mui/text-field'
import ImageUploader from '../../../common/uploaders/image-uploader/ImageUploader'
import { Member } from '../../../../types/members'
import { formatDateDMY } from '../../../../utils/settings/profile/formatDate'
import { useAuth } from '../../../../hooks/useAuth'
import { usePortalSettings } from '../../../../hooks/usePortalSettings'

const LinkStyled = styled(Link)(() => ({
  textDecoration: 'underline'
}))

type Props = {
  register: UseFormRegister<ProfileFormData>
  errors: FieldErrors<ProfileFormData>
  setValue: UseFormSetValue<ProfileFormData>
  avatarId: string | null
  member: Member
  onSubmit: () => Promise<void>
  isSubmitting: boolean
  isDirty: boolean
}

export const PersonalSettingsEditor = ({
  register,
  errors,
  setValue,
  member,
  onSubmit,
  isSubmitting,
  isDirty,
  avatarId
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS.FORM' })
  const { t: tGlobal } = useTranslation()

  const setAvatarValue = useCallback((id: string) => setValue('avatar_id', id), [setValue])

  const { user } = useAuth()

  const isPreviewMode = user?.role === UserRolesType.HIDDEN_CUSTOMER
  const isCustomer = user?.role === UserRolesType.CUSTOMER || isPreviewMode

  const { portalSettings } = usePortalSettings()
  const communitySettings = useMemo(() => portalSettings?.community_settings?.[0], [portalSettings?.community_settings])

  return (
    <Stack spacing={theme.spacing(6)}>
      <Stack spacing={theme.spacing(1.5)} marginTop={theme.spacing(6)}>
        <Typography variant='h5'>{tGlobal('PAGE.PROFILE_SETTINGS.personal_tab_title')}</Typography>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Stack spacing={theme.spacing(1)}>
            <Typography variant='body2'>{t('profile_picture_label')}</Typography>

            <ImageUploader
              existingImageId={avatarId}
              formSetterFn={setAvatarValue}
              isCircle={true}
              accept='.jpg, .jpeg, .png, .heic'
              aspectRatio={1}
            />
          </Stack>

          <Stack spacing={theme.spacing(1)} alignItems='flex-end' textAlign='right'>
            <Typography variant='body1' color='#B7B4BE'>
              {t('registered_at', { date: formatDateDMY(new Date(member.registered_at)) })}
            </Typography>
            <Typography variant='body1' color='#B7B4BE'>
              {t('member_number', { number: member.id })}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={{ xs: theme.spacing(3), md: theme.spacing(4) }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={theme.spacing(4)}>
          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <CustomTextField
              fullWidth
              label={t('first_name_label')}
              {...register('first_name')}
              error={Boolean(errors?.first_name)}
              helperText={errors.first_name && t(`validation.${errors.first_name.message}`)}
              inputProps={{ readOnly: isPreviewMode }}
            />
          </Tooltip>

          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <CustomTextField
              fullWidth
              label={t('last_name_label')}
              {...register('last_name')}
              error={Boolean(errors?.last_name)}
              helperText={errors.last_name && t(`validation.${errors.last_name.message}`)}
              inputProps={{ readOnly: isPreviewMode }}
            />
          </Tooltip>
        </Stack>

        {isCustomer && communitySettings?.display_name_setting_code === 'custom_name' && (
          <Stack width={`calc(50% - ${theme.spacing(2)})`}>
            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <CustomTextField
                fullWidth
                label={t('nickname')}
                {...register('nickname')}
                error={Boolean(errors?.nickname)}
                helperText={errors.nickname && t(`validation.${errors.nickname.message}`)}
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>
          </Stack>
        )}

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={theme.spacing(4)}>
          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <CustomTextField
              label={t('email_label')}
              {...(isPreviewMode ? {} : register('email'))}
              disabled={!isCustomer}
              fullWidth
              error={Boolean(errors?.email)}
              helperText={
                !isCustomer ? (
                  <FormHelperText>
                    {t('email_helper_text')}
                    <LinkStyled href={`mailto:${tGlobal('MISC.support_email')}`}>
                      {tGlobal('MISC.support_email')}
                    </LinkStyled>
                  </FormHelperText>
                ) : (
                  errors.email && t(`validation.${errors.email.message}`)
                )
              }
              inputProps={{ readOnly: isPreviewMode }}
            />
          </Tooltip>

          <Tooltip
            title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
            placement='top'
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            disableInteractive={false}
          >
            <CustomTextField
              fullWidth
              label={t('phone_number')}
              {...register('phone_number')}
              error={Boolean(errors?.phone_number)}
              helperText={errors.phone_number && t(`validation.${errors.phone_number.message}`)}
              inputProps={{ readOnly: isPreviewMode }}
            />
          </Tooltip>
        </Stack>

        <Stack spacing={theme.spacing(4)}>
          {!isCustomer && (
            <CustomTextField
              fullWidth
              label={t('company_label')}
              {...register('address.company')}
              error={Boolean(errors?.address?.company)}
              helperText={errors.address?.company && t(`validation.${errors.address?.company.message}`)}
            />
          )}
        </Stack>

        <Stack spacing={theme.spacing(4)}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={theme.spacing(4)}>
            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <CustomTextField
                fullWidth
                label={t('street_and_number_label')}
                {...register('address.street_and_number')}
                error={Boolean(errors?.address?.street_and_number)}
                helperText={
                  errors.address?.street_and_number && t(`validation.${errors.address?.street_and_number.message}`)
                }
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>

            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <CustomTextField
                fullWidth
                label={t('postal_code_label')}
                {...register('address.postal_code')}
                error={Boolean(errors?.address?.postal_code)}
                helperText={errors.address?.postal_code && t(`validation.${errors.address?.postal_code.message}`)}
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>
          </Stack>
        </Stack>

        <Stack spacing={theme.spacing(4)}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={theme.spacing(4)}>
            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <CustomTextField
                fullWidth
                label={t('city_label')}
                {...register('address.city')}
                error={Boolean(errors?.address?.city)}
                helperText={errors.address?.city && t(`validation.${errors.address?.city.message}`)}
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>

            <Tooltip
              title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <CustomTextField
                label={t('country_label')}
                fullWidth
                {...register('address.country')}
                error={Boolean(errors?.address?.country)}
                helperText={errors.address?.country && t(`validation.${errors.address?.country.message}`)}
                inputProps={{ readOnly: isPreviewMode }}
              />
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction='row' spacing={theme.spacing(4)}>
        <Tooltip
          title={isPreviewMode ? tGlobal('PAGE.COMMON.preview_mode_tooltip') : ''}
          placement='top'
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          disableInteractive={false}
        >
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            disabled={!isDirty}
            onClick={isPreviewMode ? undefined : onSubmit}
          >
            {tGlobal('ACTION.save_changes')}
          </LoadingButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
