import { AxiosResponse } from 'axios'

import { requestUrls } from '../configs/url'
import { GeneratedPassword, Member, MemberKeys } from '../types/members'
import { DeepPartial } from '../types/common/utils'

import service from './service'

import { FilterParams, ResponseResult } from 'src/types/common/types'

export type GetAllMemberRequest = FilterParams<MemberKeys>
export type GetAllMemberResponse = ResponseResult<Member[]>
export type GetOneMemberRequest = FilterParams<MemberKeys, number>
export type GetOneMemberResponse = ResponseResult<Member[]>
export type GetCountMemberRequest = FilterParams<MemberKeys>
export type GetCountMemberResponse = ResponseResult<number>
export type UpdateMemberRequest = DeepPartial<
  Omit<Member, 'registered_at'> & { portal_member_id: string; registered_at: number | undefined }
>
export type UpdateMemberResponse = ResponseResult<boolean>
export type DeleteMemberRequest = FilterParams<MemberKeys, [number]>
export type DeleteMemberResponse = ResponseResult<boolean>
export type DeleteProfileResponse = ResponseResult<boolean>
export type GeneratePasswordRequest = { id: number }
export type resendVerificationEmailRequest = { portal_id: number; token: string }
export type resendVerificationEmailResponse = ResponseResult<any>
export type GeneratePasswordResponse = ResponseResult<GeneratedPassword>

const memberService = {
  getMembersInfo: async (requestBody: GetAllMemberRequest): Promise<AxiosResponse<GetAllMemberResponse>> => {
    return service.post(requestUrls.getMembersInfo, requestBody)
  },
  getAll: async (requestBody: GetAllMemberRequest): Promise<AxiosResponse<GetAllMemberResponse>> => {
    return service.post(requestUrls.getMembers, requestBody)
  },
  getOne: async (requestBody: GetOneMemberRequest): Promise<AxiosResponse<GetOneMemberResponse>> => {
    return service.post(requestUrls.getMembers, requestBody)
  },
  getCount: async (requestBody: GetCountMemberRequest): Promise<AxiosResponse<GetCountMemberResponse>> => {
    return service.post(requestUrls.getMembersCount, requestBody)
  },
  update: async (requestBody: UpdateMemberRequest): Promise<AxiosResponse<UpdateMemberResponse>> => {
    return service.post(requestUrls.updateMember, requestBody)
  },
  delete: async (requestBody: DeleteMemberRequest): Promise<AxiosResponse<DeleteMemberResponse>> => {
    return service.post(requestUrls.deleteMember, requestBody)
  },
  deleteProfile: async (): Promise<AxiosResponse<DeleteProfileResponse>> => {
    return service.post(requestUrls.deleteProfile)
  },
  generatePassword: async (requestBody: GeneratePasswordRequest): Promise<AxiosResponse<GeneratePasswordResponse>> => {
    return service.post(requestUrls.generatePassword, requestBody)
  },
  resendVerificationEmail: async (
    requestBody: resendVerificationEmailRequest
  ): Promise<AxiosResponse<resendVerificationEmailResponse>> => {
    return service.post(requestUrls.resendVerificationEmail, requestBody)
  }
}

export default memberService
