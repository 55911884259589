import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Stack, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Icon from '../../../../@core/components/icon'
import { useAuth } from '../../../../hooks/useAuth'

const ModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  width: '100%',
  border: 0,
  outline: 0,
  borderRadius: theme.spacing(1.5),
  boxShadow: `0 5px 20px 0 ${theme.colorWithOpacity('#4B465C', 40)}`,
  backgroundColor: theme.palette.customColors.cardBg,
  [theme.breakpoints.down('md')]: {
    width: '93%'
  }
}))

const ModalBody = styled(Box)<BoxProps>({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 68px)'
})

const ModalFooter = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(4),
  padding: theme.spacing(1, 6, 5)
}))

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: `-${theme.spacing(2.5)}`,
  right: `-${theme.spacing(2.5)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.customColors.cardBg,
  boxShadow: `0 2px 4px 0 ${theme.colorWithOpacity(theme.palette.grey[500], 30)}`,
  cursor: 'pointer'
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const LogoutModal = ({ isOpen, onClose }: Props) => {
  const { logout } = useAuth()
  const theme = useTheme()

  const { t: tAction } = useTranslation('translation', { keyPrefix: 'ACTION' })
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.LOGOUT_MODAL' })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContent>
        <IconBox>
          <Icon icon='tabler:x' onClick={onClose} />
        </IconBox>
        <ModalBody>
          <Stack spacing={theme.spacing(1)}>
            <Typography variant='h5' paddingX={theme.spacing(6)} paddingY={theme.spacing(5)}>
              {t('title')}
            </Typography>
            <Typography variant='body1' paddingX={theme.spacing(6)} paddingY={theme.spacing(5)}>
              {t('are_you_sure')}
            </Typography>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant='text' color='secondary' onClick={onClose}>
            {tAction('cancel')}
          </Button>
          <Button variant='contained' color='primary' onClick={logout}>
            {tAction('logout')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
