import { VerticalNavItemsType } from 'src/@core/layouts/types'

import { NavigationMenuItem } from '../../types/navigation-items'
import { CustomNavNames } from '../types'

import mainMenu from './cc-main-menu'
import usersMenu from './cc-users-menu'
import accountMenu from './account-menu'
import rootMenu from './root-menu'
import customLinksMenu from './customer-custom-links'
import customerMenu from './customer-menu'
import GuestCustomLinksMenu from './guest-custom-links'

import { Community } from 'src/types/community'

interface Props {
  communities?: Community[]
  isLoadingCommunities: boolean
  showCommunities: boolean
  isCustomer: boolean
  showLeaderboard: boolean
  showCalendar: boolean
  customNavNames?: CustomNavNames
  customNavItems?: NavigationMenuItem[]
  isGuest: boolean
}

//render navigation
const navigation = ({
  communities,
  isLoadingCommunities,
  showCommunities,
  isCustomer,
  isGuest,
  showLeaderboard = true,
  showCalendar = true,
  customNavItems,
  customNavNames
}: Props): VerticalNavItemsType => {
  return [
    ...mainMenu,
    ...customerMenu({ showLeaderboard, showCalendar, customNavNames }),
    ...usersMenu({
      communities,
      isLoadingCommunities,
      showCommunities,
      communityCustomName: customNavNames?.community,
      isCustomer,
      isGuest
    }),
    ...accountMenu,
    ...rootMenu,
    ...(isCustomer && customNavItems ? customLinksMenu({ customLinks: customNavItems }) : []),
    ...(isGuest && customNavItems ? GuestCustomLinksMenu({ customLinks: customNavItems }) : [])
  ]
}

export default navigation
