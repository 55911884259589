import { useCallback, useMemo } from 'react'

import { useRouter } from 'next/router'

import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import { AvatarProps } from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { Stack, Tooltip, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CustomAvatar from 'src/@core/components/mui/avatar'
import { RenderProps } from 'src/@core/layouts/types'
import { CustomAvatarProps } from 'src/@core/components/mui/avatar/types'

import { getImageUrl } from 'src/utils/getImageUrl'

import authService from 'src/service/auth-service'

import { routePaths } from '../../paths'
import Icon from '../../../@core/components/icon'
import { LogoutModal } from '../../../components/settings/profile/components'
import useModal from '../../../hooks/useModal'
import { UserRolesType } from '../../../utils/getUserRole'
import Translations from '../../../layouts/components/Translations'
import { useSettings } from '../../../@core/hooks/useSettings'
import useMenu from '../../../hooks/useMenu'

const UserAvatar = styled(CustomAvatar, { shouldForwardProp: prop => prop !== 'size' })<AvatarProps & { size: string }>(
  ({ size }) => ({
    width: size,
    height: size
  })
)

const UserAvatarInitials = styled(CustomAvatar, { shouldForwardProp: prop => prop !== 'size' })<
  CustomAvatarProps & { size: string }
>(({ theme, size }) => ({
  width: size,
  height: size,
  '& .MuiTypography-root': {
    color: `${theme.palette.common.white} !important`
  }
}))

const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.customColors.navbarBg
  }
}))

const getDefaultUsername = (userRole?: string) => {
  if (userRole === UserRolesType.ADMIN) return 'NAVIGATION.rootMenu.noUsername'

  if (
    userRole === UserRolesType.DEMO_HIDDEN_CUSTOMER ||
    userRole === UserRolesType.HIDDEN_CUSTOMER ||
    userRole === UserRolesType.CUSTOMER
  )
    return 'NAVIGATION.clientMenu.noUsername'

  return ''
}

const getInitials = (str: string) => {
  if (!str) return ''

  return str
    .split(' ')
    .map(word => word[0]?.toUpperCase())
    .join('')
}

const UserNavLink = (props: RenderProps) => {
  const mentortools_user = authService.getUser()
  let imgSrc = ''
  let userName = ''
  let userNameInitials = ''

  if (mentortools_user) {
    if (mentortools_user.portal_member.avatar_id) imgSrc = getImageUrl(mentortools_user.portal_member.avatar_id)
    userName =
      mentortools_user.portal_member.first_name &&
      mentortools_user.portal_member.last_name &&
      `${mentortools_user.portal_member.first_name} ${mentortools_user.portal_member.last_name}`
    if (userName.length > 12)
      userName = `${mentortools_user.portal_member.first_name} ${mentortools_user.portal_member.last_name[0]}.`
    userNameInitials = getInitials(userName ? userName : props.title)
  }

  const { t } = useTranslation()
  const theme = useTheme()
  const { settings } = useSettings()
  const router = useRouter()

  const { open: openModal, isOpen, close } = useModal()

  const { anchor, open: onMenuOpen, isOpen: isMenuOpen, close: onMenuClose } = useMenu()

  const onLogoutClick = useCallback(() => {
    openModal()
    onMenuClose()
  }, [onMenuClose, openModal])

  const onProfileClick = useCallback(() => {
    const profilePath =
      mentortools_user?.portal_member.role_id.toString() === UserRolesType.CUSTOMER ||
      mentortools_user?.portal_member.role_id.toString() === UserRolesType.HIDDEN_CUSTOMER
        ? routePaths.customerProfile
        : routePaths.settingsProfile
    router.push(profilePath)
    onMenuClose()
  }, [mentortools_user?.portal_member.role_id, onMenuClose, router])

  const isCC = useMemo(
    () => mentortools_user?.portal_member.role_id.toString() === UserRolesType.CONTENT_CREATOR,
    [mentortools_user?.portal_member.role_id]
  )

  const isAdmin = useMemo(
    () => mentortools_user?.portal_member.role_id.toString() === UserRolesType.ADMIN,
    [mentortools_user?.portal_member.role_id]
  )

  const isPreviewMode = useMemo(
    () => mentortools_user?.portal_member.role_id.toString() === UserRolesType.HIDDEN_CUSTOMER,
    [mentortools_user?.portal_member.role_id]
  )

  return (
    <>
      <Box component={Button} onClick={onMenuOpen} {...props.menuItemWrapperBoxProps}>
        <ListItemIcon {...props.listItemIconProps}>
          {imgSrc ? (
            <UserAvatar src={imgSrc} size={settings.layout === 'horizontal' ? theme.spacing(8) : theme.spacing(5.5)} />
          ) : (
            <UserAvatarInitials size={settings.layout === 'horizontal' ? theme.spacing(8) : theme.spacing(5.5)}>
              <Typography variant='caption'>{userNameInitials}</Typography>
            </UserAvatarInitials>
          )}
        </ListItemIcon>

        {!isCC && settings.layout === 'vertical' ? (
          <Box {...props.menuItemTextMetaWrapperProps}>
            <Typography {...props.menuItemTextMetaTypographyProps}>
              <Translations
                text={userName ? userName : getDefaultUsername(String(mentortools_user?.portal_member.role_id))}
              />
            </Typography>
          </Box>
        ) : null}
      </Box>

      <MenuStyled
        anchorEl={anchor}
        open={isMenuOpen}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={
          settings.layout === 'horizontal'
            ? { vertical: 'top', horizontal: 'right' }
            : { vertical: 'bottom', horizontal: 'left' }
        }
      >
        {!isAdmin && (
          <MenuItem sx={{ width: theme.spacing(58) }} onClick={onProfileClick}>
            <Stack direction='row' spacing={theme.spacing(2)} alignItems='center'>
              <Icon color={theme.palette.customColors.navbarText} icon='gg:profile' fontSize={15} />
              <Typography color={theme.palette.customColors.navbarText}>
                {t('NAVIGATION.profileSubmenu.profile_settings')}
              </Typography>
            </Stack>
          </MenuItem>
        )}

        <Tooltip
          title={isPreviewMode ? t('PAGE.COMMON.preview_mode_tooltip') : ''}
          placement='top-start'
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          disableInteractive={false}
        >
          <MenuItem sx={{ width: theme.spacing(58) }} onClick={isPreviewMode ? undefined : onLogoutClick}>
            <Stack
              color={theme.palette.customColors.dark}
              direction='row'
              spacing={theme.spacing(2)}
              alignItems='center'
            >
              <Icon color={theme.palette.customColors.navbarText} icon='tabler:logout' fontSize={15} />
              <Typography color={theme.palette.customColors.navbarText}>
                {t('NAVIGATION.profileSubmenu.logout')}
              </Typography>
            </Stack>
          </MenuItem>
        </Tooltip>
      </MenuStyled>
      <LogoutModal onClose={close} isOpen={isOpen} />
    </>
  )
}

export default UserNavLink
