import { createQueryKeys } from '@lukemorales/query-key-factory'

const integrationsAutomations = createQueryKeys('integrationsAutomations', {
  externalSystemIntegrationList: {
    queryKey: null
  },
  systemIntegrationList: {
    queryKey: null
  },
  ruleEvents: { queryKey: null },
  ruleActions: { queryKey: null },
  ruleList: { queryKey: null },
  ruleCount: { queryKey: null },
  courseInfo: { queryKey: null },
  errorTypes: { queryKey: null },
  errorLogs: { queryKey: null },
  mailingList: { queryKey: null }
})

export default integrationsAutomations
