import { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import fileService from 'src/service/file-service'

import useToast from '../useToast'

export const useVideoUpload = (onSuccess?: (fileId: string) => void) => {
  const { notify } = useToast()

  const [isConverting, setIsConverting] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const onChangeProgress = useCallback((progress: number) => setProgress(progress), [setProgress])

  const pollVideoStatus = async (fileId: string): Promise<void> => {
    try {
      const {
        data: {
          result: { status }
        }
      } = await fileService.getVideoStatus({ file_handle: fileId })

      switch (status) {
        case 'in_progress':
          await new Promise(resolve => setTimeout(resolve, 200))

          return pollVideoStatus(fileId)
        case 'done':
          break
        default:
          break
      }
    } catch (error) {
      notify('VIDEO.convert', { type: 'error' })
    }
  }

  const { mutateAsync: uploadVideo, isPending } = useMutation({
    mutationFn: async (formData: FormData) => {
      setIsUploading(true)
      const {
        data: { result: source }
      } = await fileService.uploadVideo(formData, progress => setProgress(progress))
      setIsUploading(false)

      if (!source) {
        throw new Error('ALERT.ERROR.upload')
      }
      notify('VIDEO.upload', { type: 'success' })

      setIsConverting(true)
      await pollVideoStatus(source)
      setIsConverting(false)
      notify('VIDEO.convert', { type: 'success' })

      return source
    },
    onSuccess,
    onError: () => notify('VIDEO.upload', { type: 'error' })
  })

  return useMemo(
    () => ({
      onChangeProgress,
      uploadVideo,
      isPending,
      progress,
      isConverting,
      isUploading
    }),
    [isConverting, isPending, isUploading, onChangeProgress, progress, uploadVideo]
  )
}
