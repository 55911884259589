import { Button, Stack, styled, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'

const PreviewContainer = styled(Stack)({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFCA38'
})

const StyledText = styled(Typography)({
  color: '#000',
  alignSelf: 'center'
})

const StyledButton = styled(Button)({
  height: 28,
  color: '#000',
  backgroundColor: '#FFFF',
  '&:hover': {
    backgroundColor: '#FFFF'
  }
})

export const PreviewModeBar = () => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.COMMON' })
  const { t: tGlobal } = useTranslation('translation')

  const handleRefresh = () => window?.location.reload()

  return (
    <PreviewContainer>
      <Stack
        gap={theme.spacing(7)}
        direction='row'
        paddingY={theme.spacing(4)}
        flexWrap='wrap'
        justifyContent='center'
        paddingX={theme.spacing(2)}
      >
        <StyledText>{t('preview_mode_on')}</StyledText>

        <StyledButton variant='contained' startIcon={<Icon icon='tabler:refresh' />} onClick={handleRefresh}>
          {tGlobal('ACTION.refresh')}
        </StyledButton>
      </Stack>
    </PreviewContainer>
  )
}
