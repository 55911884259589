import { ReactNode, ReactElement, useEffect, useMemo } from 'react'

import { NextRouter, useRouter } from 'next/router'

import { AxiosResponse } from 'axios'

import { useAuth } from 'src/hooks/useAuth'

import { UserRolesType } from '../../../utils/getUserRole'
import { ONBOARDING_DONE_STEP } from '../../../utils/onboarding'
import { routePaths } from '../../../navigation/paths'
import getHomeRoute from '../../../layouts/components/acl/getHomeRoute'
import { UserDataType } from '../../../context/types'
import { DAY_IN_MS } from '../../../utils/timestamps'
import authService from '../../../service/auth-service'
import { useUpdateMember } from '../../../hooks/members/useUpdateMember'
import { UpdateMemberRequest, UpdateMemberResponse } from '../../../service/member-service'

interface OnboardingGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const routeOnboarding = async (
  onboardingStep: string,
  router: NextRouter,
  user: UserDataType,
  setUser: (value: UserDataType | null) => void,
  updateMember: (request: UpdateMemberRequest) => Promise<AxiosResponse<UpdateMemberResponse, any>>
) => {
  const onboardingDone = onboardingStep?.includes(ONBOARDING_DONE_STEP)
  const onOnboarding = router.route.includes(routePaths.userOnboarding)

  if (onboardingDone && onOnboarding) {
    router.push(getHomeRoute(user.role))

    return
  }
  if (onboardingDone || onOnboarding) return

  const portalMember = authService.getUser()?.portal_member
  if (!portalMember) return

  const { registered_at } = portalMember
  const now = Date.now()

  if (now - registered_at < DAY_IN_MS) {
    router.push(routePaths.userOnboarding)

    return
  }

  await updateMember({
    id: user.id,
    user_onboarding_step: ONBOARDING_DONE_STEP
  })
  setUser({ ...user, onboardingStep: ONBOARDING_DONE_STEP })

  router.push(getHomeRoute(user.role))
}

const OnboardingGuard = ({ children, fallback }: OnboardingGuardProps) => {
  const { user, loading, setUser } = useAuth()
  const router = useRouter()

  const { isCC, onboardingStep } = useMemo(
    () => ({
      isCC: user?.role === UserRolesType.CONTENT_CREATOR,
      onboardingStep: user?.onboardingStep
    }),
    [user]
  )

  const { updateMemberAsync } = useUpdateMember(false)

  useEffect(() => {
    if (typeof onboardingStep !== 'string' || !isCC) return

    routeOnboarding(onboardingStep, router, user!, setUser, updateMemberAsync)
  }, [user, router, router.query, onboardingStep, isCC, updateMemberAsync, setUser])

  if (loading) {
    return fallback
  }

  return <>{children}</>
}

export default OnboardingGuard
