import { z } from 'zod'
import * as zod from 'zod'

import { EmailVariable } from '../../../types/settings/email/email'
import { validateTextByEmailVariables } from '../../../utils/settings/emails'

export function createOrderEmailsSchema(emailVariables: EmailVariable[]) {
  const newUserVariables = emailVariables.find(v => v.name === 'new_user')!.allowed_variables
  const existingUserVariables = emailVariables.find(v => v.name === 'existing_buyer')!.allowed_variables

  return z.object({
    email_new_user_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, newUserVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_new_user_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, newUserVariables)
        if (invalids.length) {
          ctx.addIssue({ code: 'custom', message: invalids.join(', ') })
        }
      }),
    email_existing_buyer_subject: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, existingUserVariables)
        if (invalids.length) {
          ctx.addIssue({
            code: 'custom',
            message: invalids.join(', ')
          })
        }
      }),
    email_existing_buyer_body: z
      .string()
      .trim()
      .min(1)
      .superRefine((val, ctx) => {
        const invalids = validateTextByEmailVariables(val, existingUserVariables)
        if (invalids.length) {
          ctx.addIssue({
            code: 'custom',
            message: invalids.join(', ')
          })
        }
      }),
    mailing_settings_type: z.coerce.number()
  })
}

export type OrderEmailsFormData = zod.infer<ReturnType<typeof createOrderEmailsSchema>>
