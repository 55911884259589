export const subjects = {
  topMenu: 'topMenu',
  bottomMenu: 'bottomMenu',
  customLinks: 'customLinks',
  rootPage: 'rootPage',
  accountMenu: 'accountMenu',
  userMenu: 'userMenu',
  community: 'community',
  preview: 'preview',
  mentorCloud: 'mentorCloud',
  courses: 'courses',
  stundentPage: 'stundentPage',
  ccPage: 'ccPage',
  all: 'all',
  aclPage: 'aclPage',
  demo: 'demo',
  guest: 'guest',
  courseLanguageSettings: 'courseLanguageSettings'
} as const

export type Subjects = (typeof subjects)[keyof typeof subjects]
export type SubjectSubset = Pick<typeof subjects, 'topMenu' | 'bottomMenu' | 'customLinks'>[keyof Pick<
  typeof subjects,
  'topMenu' | 'bottomMenu' | 'customLinks'
>]
export type AclSubjectSubset = Omit<typeof subjects, 'topMenu' | 'bottomMenu' | 'customLinks'>[keyof Omit<
  typeof subjects,
  'topMenu' | 'bottomMenu' | 'customLinks'
>]
