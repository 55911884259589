import { AxiosError } from 'axios'

export const isStringRecord = (value: unknown): value is Record<string, string> => {
  if (value instanceof AxiosError) {
    return typeof value.response?.data.error === 'string' && typeof value.code === 'string'
  }

  return (
    typeof value === 'object' && value !== null && Object.entries(value).every(([_, val]) => typeof val === 'string')
  )
}
