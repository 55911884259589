import { createQueryKeys } from '@lukemorales/query-key-factory'

import { MediaVariant } from 'src/types/files/media'

const files = createQueryKeys('files', {
  all: {
    queryKey: null
  },
  folder: (id: number | null) => {
    return { queryKey: [id || -1] }
  },
  detail: (ids: string[]) => {
    return { queryKey: [ids] }
  },
  detailByFileId: (fileIds: number[]) => {
    return { queryKey: [fileIds] }
  },
  name: (fileIds: string[]) => {
    return { queryKey: ['name', fileIds] }
  },
  media: (folderId: number | null, mediaType: MediaVariant | undefined) => {
    return { queryKey: [folderId || -1, mediaType] }
  }
})

export default files
