import { createQueryKeys } from '@lukemorales/query-key-factory'

import { Timeframe } from 'src/types/stats'

const stats = createQueryKeys('stats', {
  list: (type: 'customer' | 'lead' | 'user_activity' | 'new_orders', timeframe: Timeframe, timestamp: number) => {
    return { queryKey: [type, timeframe, timestamp] }
  }
})

export default stats
