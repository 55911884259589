import React from 'react'

import { useTranslation } from 'react-i18next'

import Spinner from '../../../../@core/components/spinner'
import { useNotificationSettings } from '../../../../hooks/settings/notifications/useNotificationSettings'
import { NotificationsEditor } from '../components'

export const NotificationsForm = () => {
  const {
    data: notifications,
    isError: isNotificationSettingsError,
    isLoading: isNotificationSettingsLoading
  } = useNotificationSettings()

  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROFILE_SETTINGS' })

  if (isNotificationSettingsLoading) return <Spinner />

  if (isNotificationSettingsError || !notifications) return <>{t('error')}</>

  return (
    <NotificationsEditor
      communityNotificationSettings={notifications.communityNotificationSettings}
      notificationSettings={notifications.notificationSettings}
    />
  )
}
