import { createQueryKeys } from '@lukemorales/query-key-factory'

const members = createQueryKeys('members', {
  allMembers: {
    queryKey: null
  },
  allMembersCount: {
    queryKey: null
  },
  detail: (optinId: number) => {
    return { queryKey: [optinId] }
  }
})

export default members
