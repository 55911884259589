import { DragEvent, DragEventHandler, memo, useCallback, useMemo, useRef, useState } from 'react'

import { Button, Tooltip, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import { useMediaStorage } from 'src/hooks/useMediaStorage'
import useImageUploader from 'src/hooks/images/useImageUploader'
import useModal from 'src/hooks/useModal'

import Can from 'src/layouts/components/acl/Can'
import { subjects } from 'src/navigation/vertical/subjects'

import StockPhotoPickerModal from 'src/components/common/modals/StockPhotoChooser'
import ImageCropperModal from 'src/components/common/modals/image-editor'
import MentorcloudModal from 'src/components/common/modals/MentorcloudModal'

import { isFileType } from 'src/utils/files/isFileType'
import { getImageUrl } from 'src/utils/getImageUrl'
import { getUserRole, UserRolesType } from 'src/utils/getUserRole'

import { permissionActions } from 'src/configs/permissionActions'

import FileUploadProgress from '../../loaders/files/FileUploadProgress'

import {
  Footer,
  IntroductionWrapper,
  IntroductionIconWrapper,
  CroppedImage,
  DeleteButton,
  DropArea,
  ImageWrapper
} from './styled'

type ImageUploaderProps = {
  accept?: string
  existingImageId: string | null
  requirementsLabel?: string
  aspectRatio?: number
  isCircle?: boolean
  formSetterFn?: (id: string) => void
}

const ImageUploader = ({
  accept = 'image/*',
  existingImageId,
  requirementsLabel,
  aspectRatio,
  isCircle = false,
  formSetterFn
}: ImageUploaderProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const userRole = getUserRole()

  const {
    cropSource,
    source,
    progress,
    isImageUploading,
    isImageDeleting,
    onSelectImage,
    onDeleteUploadedImage,
    onClearCropSource,
    onChangeCropSource,
    onApplyCrop,
    onUpload
  } = useImageUploader({ imageId: existingImageId, formSetterFn })

  const [isDragOver, setIsDragOver] = useState(false)
  const fileInput = useRef<HTMLInputElement | null>(null)

  const { isOpen: isImageCropperOpen, close: closeImageCropper, open: openImageCropper } = useModal()
  const { isOpen: isStockPhotoGalleryOpen, close: closeStockPhotoGallery, open: openStockPhotoGallery } = useModal()
  const { isMentorCloudOpen, closeMentorCloud, openMentorCloud } = useMediaStorage({
    mediaType: 'image'
  })

  const isPreviewMode = useMemo(() => userRole === UserRolesType.HIDDEN_CUSTOMER, [userRole])

  const { isSelected: selectedMedia } = useSelectedMedia()

  const onDrag = useCallback(
    (isDragOver: boolean): DragEventHandler<HTMLDivElement> =>
      e => {
        e.preventDefault()
        setIsDragOver(isDragOver)
      },
    [setIsDragOver]
  )

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    onDrag(false)(e)

    const imageToUpload = e.dataTransfer.files[0]
    if (source || !imageToUpload?.type.startsWith('image')) return

    await onUpload(imageToUpload)
    openImageCropper()
  }

  const onStockImagePick = useCallback(
    (url: string) => {
      onChangeCropSource(url)
      openImageCropper()
      closeStockPhotoGallery()
    },
    [closeStockPhotoGallery, onChangeCropSource, openImageCropper]
  )

  const onMentorCloudImagePick = useCallback(() => {
    if (isFileType(selectedMedia) && selectedMedia?.file_id) {
      onChangeCropSource(getImageUrl(selectedMedia.file_id))
      openImageCropper()
      closeMentorCloud()
    }
  }, [closeMentorCloud, onChangeCropSource, openImageCropper, selectedMedia])

  const onSelectFile = () => fileInput.current?.click()

  return (
    <DropArea
      border={`1px dashed ${isDragOver && !source ? theme.palette.primary.main : theme.palette.divider}`}
      onDrop={onDrop}
      onDragLeave={onDrag(false)}
      onDragOver={onDrag(true)}
    >
      <StockPhotoPickerModal
        isOpen={isStockPhotoGalleryOpen}
        onClose={closeStockPhotoGallery}
        onPick={onStockImagePick}
      />
      <ImageCropperModal
        aspectRatio={aspectRatio}
        imgSrc={cropSource}
        isCircle={isCircle}
        isOpen={isImageCropperOpen}
        onClose={onClearCropSource(closeImageCropper)}
        onSubmit={onApplyCrop(closeImageCropper)}
        isSubmitting={isImageUploading}
      />
      <MentorcloudModal
        mediaType='image'
        key={5}
        isOpen={!!isMentorCloudOpen}
        onClose={closeMentorCloud}
        onCancel={closeMentorCloud}
        onChoose={onMentorCloudImagePick}
      />
      <input
        type='file'
        id='file'
        ref={fileInput}
        onChange={onSelectImage(openImageCropper)}
        style={{ display: 'none' }}
        accept={accept}
      />
      {isImageUploading ? (
        <FileUploadProgress fileName={'image'} value={progress} />
      ) : source ? (
        <ImageWrapper>
          <CroppedImage alt={t('extracted.cropped_image')} src={source} isCircle={isCircle} />
          <DeleteButton disabled={isImageDeleting} color='error' onClick={onDeleteUploadedImage}>
            <Icon fontSize={theme.typography.h5.fontSize} icon={'tabler:trash'} color='primary' />
          </DeleteButton>
        </ImageWrapper>
      ) : (
        <IntroductionWrapper>
          <IntroductionIconWrapper>
            <Icon strokeWidth={0.5} icon='tabler:photo' fontSize={theme.typography.h3.fontSize}></Icon>
          </IntroductionIconWrapper>
          <Typography fontWeight={400} variant='h5' align='center'>
            {t('ACTION.ENTITY.drag_drop_image')}
          </Typography>
          <Typography color={theme.palette.text.disabled} variant='body1'>
            {t('MISC.or')}
          </Typography>
          <Footer>
            <Tooltip
              title={isPreviewMode ? t('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <Button onClick={isPreviewMode ? undefined : onSelectFile} variant='contained'>
                {t('ACTION.ENTITY.browse_entities', {
                  entities: t('FILE_TYPE.images')
                })}
              </Button>
            </Tooltip>

            <Tooltip
              title={isPreviewMode ? t('PAGE.COMMON.preview_mode_tooltip') : ''}
              placement='top'
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              disableInteractive={false}
            >
              <Button variant='outlined' onClick={isPreviewMode ? undefined : openStockPhotoGallery}>
                {t('ACTION.ENTITY.browse_entities', {
                  entities: t('FILE_TYPE.stock_images')
                })}
              </Button>
            </Tooltip>

            <Can I={permissionActions.manage} a={subjects.mentorCloud}>
              <Button variant='outlined' startIcon={<Icon icon='tabler:cloud'></Icon>} onClick={openMentorCloud}>
                {t('ENTITY.mentor_cloud')}
              </Button>
            </Can>
          </Footer>
          <Typography textAlign={'center'} color={theme.palette.text.disabled} variant='caption'>
            {requirementsLabel ? requirementsLabel : t('PAGE.UPLOADER.image_req')}
          </Typography>
        </IntroductionWrapper>
      )}
    </DropArea>
  )
}

export default memo(ImageUploader)
