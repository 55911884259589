import { createQueryKeys } from '@lukemorales/query-key-factory'

const activityLog = createQueryKeys('activityLog', {
  getUserActionLog: (customerId: number) => {
    return { queryKey: [customerId] }
  },
  communityLog: (customerId: number) => {
    return { queryKey: [customerId] }
  },
  threadLog: (ids: number[]) => {
    return { queryKey: [ids] }
  },
  eventTypes: {
    queryKey: null
  }
})

export default activityLog
