import { createQueryKeys } from '@lukemorales/query-key-factory'

const appRequests = createQueryKeys('appRequests', {
  allAppRequests: {
    queryKey: null
  },
  allAppRequestsCount: {
    queryKey: null
  },
  appRequestsMembers: {
    queryKey: null
  },
  appRequestsCourses: {
    queryKey: null
  },
  appRequestsPersonal: {
    queryKey: null
  },
  coursesPersonal: {
    queryKey: null
  },
  settings: (appRequestId: number) => {
    return { queryKey: [appRequestId] }
  },
  userInfo: (portalId: number) => {
    return { queryKey: [portalId] }
  }
})

export default appRequests
