interface UrlMapping {
  readonly [key: string]: string
}

interface QueryParamConfig {
  readonly maxLength: number
  readonly possibleQueryParams: {
    readonly [key: string]: string
  }
}

interface UrlDecodeMapping {
  readonly [key: string]: QueryParamConfig
}

const urlEncodeGuide: UrlMapping = {
  // url elements
  customer: 'cu',
  'free-course': 'fc',
  double: 'do',
  dashboard: 'da',

  // query params
  course_id: 'CI',
  lesson_public: 'LP'
}

const urlDecodeGuide: UrlDecodeMapping = {
  cu: {
    maxLength: 3,
    possibleQueryParams: {
      CI: 'course_id',
      LP: 'lesson_public'
    }
  }
}

export const urlPossibleQueryParams = ['course_id', 'lesson_public']

export const createNewUrlWithQueryParamsForDecode = (
  url: string,
  urlDecodeGuide: UrlDecodeMapping,
  pathname: string,
  queryParamAfter: number
): string => {
  // get query param key from encoded url with urlDecodeGuide
  const queryKey = urlDecodeGuide.cu.possibleQueryParams[pathname.slice(2 * queryParamAfter, 2 * queryParamAfter + 2)]

  // get query param value from encoded url
  const queryValue = pathname.slice(2 * queryParamAfter + 2)

  return url + '?' + queryKey + '=' + queryValue
}

export const encodeUrl = (url: string) => {
  try {
    // BEGINNING OF THE REQUIRED VARIABLES
    // building url object from url string
    const tempUrl = new URL(url)

    // building url query params object
    const urlParams = new URLSearchParams(tempUrl.search)
    const paramPairs = []
    const newUrlArr = []
    let explodedUrl = tempUrl.pathname.replace('/app/', '/').split('/')

    // remove empty array elements
    explodedUrl = explodedUrl.filter(n => n)

    // END

    // building an array with shortened reference keys from guide
    for (let i = 0; i < explodedUrl.length; i++) {
      const encodedSegment = urlEncodeGuide[explodedUrl[i]]
      if (!encodedSegment) {
        throw new Error(`Unknown URL segment: ${explodedUrl[i]}`)
      }
      newUrlArr.push(encodedSegment)
    }

    // building an array with value of query param from guide
    for (let i = 0; i < urlPossibleQueryParams.length; i++) {
      if (urlParams.get(urlPossibleQueryParams[i])) {
        const encodedParam = urlEncodeGuide[urlPossibleQueryParams[i]]
        if (!encodedParam) {
          throw new Error(`Unknown query parameter: ${urlPossibleQueryParams[i]}`)
        }
        const paramValue = urlParams.get(urlPossibleQueryParams[i])
        paramPairs.push(encodedParam + paramValue)
      }
    }

    // creating a new shortened link
    const newUrl = tempUrl.origin + '/app/SU' + newUrlArr.join('') + paramPairs.join('')

    return newUrl
  } catch (error) {
    throw new Error(`Failed to encode URL: ${error}`)
  }
}

export const decodeUrl = (url: string): string => {
  try {
    // BEGINNING OF THE REQUIRED VARIABLES
    const tempUrl = new URL(url)
    const pathname = tempUrl.pathname.split('/')[1].substring(2)

    const invertedUrlEncodeGuide: Record<string, string> = {}
    Object.keys(urlEncodeGuide).forEach(key => {
      invertedUrlEncodeGuide[urlEncodeGuide[key]] = key
    })
    let decodeGuide
    const newUrlArr = []
    let maxLimit = 0
    let queryParamAfter = 0

    // END

    // get the decode guide and the maximum limit of encoded url elements
    if (urlDecodeGuide[pathname.slice(0, 2)]) {
      decodeGuide = urlDecodeGuide[pathname.slice(0, 2)]
      maxLimit = decodeGuide.maxLength
    }

    // decode encoded url elements
    for (let i = 0; i < maxLimit; i++) {
      // get the encoded url element of two letters from the string
      const urdEncodedKey = pathname.slice(i + i * 1, (i + 1) * 2)

      // check if the element is indeed the encoded url and not the query param
      if (!(urdEncodedKey !== urdEncodedKey.toLowerCase() && urdEncodedKey === urdEncodedKey.toUpperCase())) {
        newUrlArr.push(invertedUrlEncodeGuide[urdEncodedKey])
      } else {
        continue
      }

      // set query param start point
      queryParamAfter = i + 1
    }

    // creating a new shortened link
    let newUrl = newUrlArr.join('/')

    // checking if shortened url contain query param
    if (pathname.length > 2 * maxLimit) {
      // add query param to the url string using decode guide
      newUrl = createNewUrlWithQueryParamsForDecode(newUrl, urlDecodeGuide, pathname, queryParamAfter)
    }

    return newUrl
  } catch (error) {
    throw new Error(`Failed to decode URL: ${error}`)
  }
}
