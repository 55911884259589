import { NavLink } from 'src/@core/layouts/types'

import { routePaths } from 'src/navigation/paths'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

const rootMenu: NavLink[] = [
  {
    title: 'NAVIGATION.rootMenu.landingPage',
    subject: subjects.topMenu,
    aclSubject: subjects.rootPage,
    action: permissionActions.manage,
    icon: 'tabler:speakerphone',
    path: routePaths.landingPage
  },
  {
    title: 'NAVIGATION.rootMenu.contentCreators',
    subject: subjects.topMenu,
    aclSubject: subjects.rootPage,
    action: permissionActions.manage,
    icon: 'tabler:user',
    path: routePaths.contentCreators
  },
  {
    title: 'NAVIGATION.rootMenu.products',
    path: routePaths.products,
    subject: subjects.topMenu,
    aclSubject: subjects.rootPage,
    action: permissionActions.manage,
    icon: 'tabler:device-laptop'
  },
  {
    title: 'NAVIGATION.rootMenu.appRequests',
    subject: subjects.topMenu,
    aclSubject: subjects.rootPage,
    action: permissionActions.manage,
    icon: 'tabler:device-mobile',
    path: routePaths.appRequests
  },
  {
    title: 'NAVIGATION.rootMenu.aiVideo',
    subject: subjects.topMenu,
    aclSubject: subjects.rootPage,
    action: permissionActions.manage,
    icon: 'tabler:device-mobile',
    path: routePaths.aiVideos
  }
]

export default rootMenu
