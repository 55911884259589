import { createQueryKeys } from '@lukemorales/query-key-factory'

const moderators = createQueryKeys('moderators', {
  all: (communityId: number) => ({
    queryKey: [{ communityId }]
  }),
  check: (communityId: number) => ({
    queryKey: [{ communityId }]
  }),
  allMembers: (communityId: number) => ({
    queryKey: [{ communityId }]
  })
})

export default moderators
